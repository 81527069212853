/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer, Toolkit } from '@lottiefiles/toolkit-js';

interface ChangeDrawOrderWithChildrenArgs {
  drawOrder: number;
  layerId: string;
  toolkit: Toolkit;
}

export function changeDrawOrderWithChildren({ drawOrder, layerId, toolkit }: ChangeDrawOrderWithChildrenArgs): void {
  const node = toolkit.getNodeById(layerId) as Layer | null;

  if (node) node.setDrawOrder(drawOrder);
}
