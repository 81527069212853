/**
 * Copyright 2022 Design Barn Inc.
 */

import type { IconNameType } from '@lottiefiles/ds-core';
import {
  Button,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TooltipProvider,
  TooltipRoot as Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@lottiefiles/ds-core';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { styled } from '../../../config/stitches';
import { useKeypress } from '../../../hooks';
import {
  animationZoomStateAtom,
  backgroundColorValueAtom,
  boundingBoxAtom,
  canvasDimensionsAtom,
  transparentBackgroundAtom,
  wiredModeAtom,
} from '../../../state';
import { SCALE_STEP, getLottieWidth } from '../../../utils';
import { ColorPicker } from '../../color-picker';

const FloatingContainer = styled('div', {
  position: 'absolute',
  right: '$space7',
  bottom: '$space10',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  zIndex: 0,
  gap: '$space3',
});

const ShortcutKey = styled('span', {
  display: 'inline-block',
  fontSize: '$fontSizeXs',
  marginRight: '$space2',
  marginLeft: '$space2',
  borderRadius: '$roundedBase',
  background: '$gray500',
  boxShadow:
    '0px 0px 1px rgba(61, 72, 83, 0.36), 0px 2px 6px rgba(61, 72, 83, 0.06), 0px 8px 48px rgba(243, 246, 248, 0.15);',
  variants: {
    size: {
      sm: {
        paddingRight: '$space1',
        paddingLeft: '$space1',
      },
      md: {
        paddingRight: '$space2',
        paddingLeft: '$space2',
      },
      lg: {
        paddingRight: '6px',
        paddingLeft: '6px',
      },
    },
  },
});

const ShortcutText = styled('span', {
  display: 'inline-block',
  marginRight: '$space3',
});

export const FloatingMenu = (): JSX.Element => {
  const [animationBackgroundValue, setAnimationBackgroundValue] = useRecoilState(backgroundColorValueAtom);
  const [transparentBackground, setTransparentBackground] = useRecoilState(transparentBackgroundAtom);
  const [scale, setScale] = useRecoilState(animationZoomStateAtom);
  const [wired, setWired] = useRecoilState(wiredModeAtom);
  const [boundingBox, setBoundingBox] = useRecoilState(boundingBoxAtom);
  const [canvasDimensions] = useRecoilState(canvasDimensionsAtom);

  const zoomIn = useCallback(
    (event: any): void => {
      (event as Event).stopPropagation();

      const lottieWidth = getLottieWidth(window.innerWidth, scale);

      console.log({ lottieWidth, width: canvasDimensions.width });

      if (lottieWidth >= canvasDimensions.width) return;
      setScale(scale + SCALE_STEP);
    },
    [scale, setScale, canvasDimensions],
  );

  const zoomOut = useCallback(
    (event: any): void => {
      (event as Event).stopPropagation();

      if (scale > SCALE_STEP) setScale(scale - SCALE_STEP);
    },
    [scale, setScale],
  );

  const resetScale = useCallback((): void => {
    setScale(1);
  }, [setScale]);

  const toggleWired = useCallback(
    (event: any): void => {
      (event as Event).stopPropagation();

      setWired(!wired);
    },
    [wired, setWired],
  );

  const toggleBoundingBox = useCallback(
    (event: any): void => {
      (event as Event).stopPropagation();

      setBoundingBox(!boundingBox);
    },
    [boundingBox, setBoundingBox],
  );

  const toggleTransparentBackground = useCallback(
    (event: any): void => {
      (event as Event).stopPropagation();

      setTransparentBackground(!transparentBackground);
    },
    [transparentBackground, setTransparentBackground],
  );

  useKeypress('mod+=', zoomIn);
  useKeypress('mod+-', zoomOut);
  useKeypress('mod+0', resetScale);
  useKeypress('mod+y', toggleWired);

  return (
    <FloatingContainer>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger
            asChild
            style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <IconButton
              name="bounding-box"
              onClick={toggleBoundingBox}
              size="sm"
              buttonStyle={{
                background: '$white',
                color: boundingBox ? '$brandLottiefiles' : '$black',
                '&:hover': { background: '$gray100' },
                '&:focus': { background: '$white' },
              }}
            />
          </TooltipTrigger>
          <TooltipContent align="center" side="left" contentStyle={{ fontSize: '12px' }}>
            Bounding box
          </TooltipContent>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger
            asChild
            style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <IconButton
              name={`transparency-grid` as IconNameType}
              onClick={toggleTransparentBackground}
              buttonStyle={{
                background: '$white',
                color: transparentBackground ? '$brandLottiefiles' : '$black',
                '&:hover': { background: '$gray100' },
                '&:focus': { background: '$white' },
              }}
              size="sm"
            />
          </TooltipTrigger>
          <TooltipContent align="center" side="left" contentStyle={{ fontSize: '12px' }}>
            Transparency grid
          </TooltipContent>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger
            asChild
            style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <IconButton
              name="transparent"
              onClick={toggleWired}
              size="sm"
              buttonStyle={{
                background: '$white',
                color: wired ? '$brandLottiefiles' : '$black',
                '&:hover': { background: '$gray100' },
                '&:focus': { background: '$white' },
              }}
            />
          </TooltipTrigger>
          <TooltipContent align="center" side="left" contentStyle={{ fontSize: '12px' }}>
            X-ray view
          </TooltipContent>
        </Tooltip>

        <Popover>
          <Tooltip>
            <PopoverTrigger asChild>
              <TooltipTrigger
                asChild
                style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <IconButton
                  onClick={(event): void => event?.stopPropagation()}
                  name="background-color"
                  size="sm"
                  buttonStyle={{ background: '$white', '&:hover': { background: '$gray100' } }}
                ></IconButton>
              </TooltipTrigger>
            </PopoverTrigger>
            <TooltipContent align="center" side="left" contentStyle={{ fontSize: '12px' }}>
              Background color
            </TooltipContent>
          </Tooltip>
          <PopoverContent align="start" side="left" contentStyle={{ width: '100%' }} collisionPadding={24}>
            <ColorPicker
              colorValue={animationBackgroundValue}
              onChange={setAnimationBackgroundValue}
              showHexInput
              showPreview
              showAlphaSelector
            />
          </PopoverContent>
        </Popover>

        <div>
          <Tooltip>
            <TooltipTrigger
              asChild
              style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <IconButton
                name="plus-outline"
                onClick={zoomIn}
                size="sm"
                buttonStyle={{
                  background: '$white',
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderBottom: '0.5px solid #DAE1E7',
                  '&:hover': { background: '$gray100' },
                }}
              />
            </TooltipTrigger>
            <TooltipContent align="center" side="left" contentStyle={{ fontSize: '12px' }}>
              <ShortcutText>Zoom in</ShortcutText>
              <ShortcutKey size="md">⌘</ShortcutKey>
              <ShortcutKey size="lg">+</ShortcutKey>
            </TooltipContent>
          </Tooltip>

          <Button
            label={`${scale * 100}%`}
            buttonStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '$sizes$size4',
              color: '$textIconPrimary',
              background: '$white',
              borderRadius: 0,
              width: '$iconSizeLg',
              height: '$sizes$size17',
              cursor: 'default',
              '&:hover': { background: '$gray100 !important' },
            }}
            onClick={resetScale}
          ></Button>

          <Tooltip>
            <TooltipTrigger
              asChild
              style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <IconButton
                name="minus"
                onClick={zoomOut}
                size="sm"
                buttonStyle={{
                  background: '$white',
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0,
                  borderTop: '0.5px solid #DAE1E7',
                  '&:hover': { background: '$gray100' },
                }}
              />
            </TooltipTrigger>
            <TooltipContent align="center" side="left" contentStyle={{ fontSize: '12px' }}>
              <ShortcutText>Zoom out</ShortcutText>
              <ShortcutKey size="md">⌘</ShortcutKey>
              <ShortcutKey size="lg">-</ShortcutKey>
            </TooltipContent>
          </Tooltip>
        </div>
      </TooltipProvider>
    </FloatingContainer>
  );
};
