/**
 * Copyright 2022 Design Barn Inc.
 */

export const envConfig = {
  authServerUrl: import.meta.env.VITE_AUTH_SERVER_URL,
  graphqlGatewayUrl: import.meta.env.VITE_GRAPHQL_GATEWAY_URL,
  usersnapApiKey: import.meta.env.VITE_USERSNAP_API_KEY,
  uploadRequestStagingUrl: import.meta.env.VITE_UPLOAD_REQUEST_STAGING_URL,
  s3Url: import.meta.env.VITE_S3_URL,
  bugsnagApiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  isDev: window.location.hostname === 'editor.lottiefiles.dev',
  isProd: window.location.hostname === 'editor.lottiefiles.com',
  isStaging: window.location.hostname === 'editor.lottiefiles.io',
  isLocal: window.location.hostname === 'editor.local.lottiefiles.dev',
  appVersion: '0.0.1-beta.1',
};
