/**
 * Copyright 2022 Design Barn Inc.
 */

import { Modal, ModalTitle, ModalHeader, ModalContent, ModalBody, Keyboard } from '@lottiefiles/ds-core';
import type { CSS } from '@stitches/react/types/css-util';
import { useRecoilState } from 'recoil';

import { styled } from '../../../../config/stitches';
import { keyboardModalOpenAtom } from '../../../../state';

const KeyboardContainer = styled('span', {
  display: 'flex',
  gap: '$space2',
});

const keyShadow: CSS = {
  boxShadow:
    '0px 0px 1px rgba(61, 72, 83, 0.36), 0px 2px 6px rgba(61, 72, 83, 0.06), 0px 8px 48px rgba(243, 246, 248, 0.15);',
};

const UnorderedList = styled('ul', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '52px',
  gridRowGap: '12px',
  listStyle: 'none',
  padding: 0,
});

const keyboardShortcuts = [
  {
    text: 'Undo',
    shortcut: [{ key: '⌘' }, { key: 'Z' }],
    id: 'hotkey_undo',
  },
  {
    text: 'Play / pause animation',
    shortcut: [{ key: 'space' }],
    id: 'hotkey_play',
  },
  {
    text: 'Redo',
    shortcut: [{ key: '⌘' }, { key: '⇧' }, { key: 'Z' }],
    id: 'hotkey_redo',
  },
  {
    text: 'Zoom in',
    shortcut: [{ key: '⌘' }, { key: '+' }],
    id: 'hotkey_zoom_in',
  },
  {
    text: 'Delete selection',
    shortcut: [{ key: 'Delete' }],
    id: 'hotkey_delete',
  },
  {
    text: 'Zoom out',
    shortcut: [{ key: '⌘' }, { key: '-' }],
    id: 'hotkey_zoom_out',
  },
  {
    text: 'Show / hide selection',
    shortcut: [{ key: '⌘' }, { key: '⇧' }, { key: 'H' }],
    id: 'hotkey_show',
  },
  {
    text: 'Reset zoom',
    shortcut: [{ key: '⌘' }, { key: '0' }],
    id: 'hotkey_zoom_reset',
  },
  {
    text: 'Move layer to front',
    shortcut: [{ key: ']' }],
    id: 'hotkey_move_front',
  },
  {
    text: 'X-ray view',
    shortcut: [{ key: '⌘' }, { key: 'Y' }],
    id: 'hotkey_xray',
  },
  {
    text: 'Move layer to back',
    shortcut: [{ key: '[' }],
    id: 'hotkey_move_back',
  },
  {
    text: 'Save animation',
    shortcut: [{ key: '⌘' }, { key: 'S' }],
    id: 'hotkey_save',
  },
  {
    text: '',
    shortcut: [],
    id: 'hotkey_empty',
  },
  {
    text: 'Download',
    shortcut: [{ key: '⌘' }, { key: 'E' }],
    id: 'hotkey_download',
  },
  {
    text: '',
    shortcut: [],
    id: 'hotkey_empty_2',
  },
  {
    text: 'Keyboard shortcuts',
    shortcut: [{ key: '⇧' }, { key: '?' }],
    id: 'hotkey_keyboard',
  },
];

export const KeyboardShortcutsModal = (): JSX.Element => {
  const [keyboardModalOpen, setKeyboardModalOpen] = useRecoilState(keyboardModalOpenAtom);

  return (
    <Modal open={keyboardModalOpen} onOpenChange={setKeyboardModalOpen}>
      <ModalContent size="lg">
        <ModalHeader
          containerStyle={{
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '$textIconPrimary',
          }}
          showClose
        >
          <ModalTitle title="Keyboard Shortcuts" />
        </ModalHeader>
        <ModalBody
          containerStyle={{ fontFamily: '$fontFamilyProduct', color: '$textIconPrimary', fontSize: '$fontSizeSm' }}
        >
          <div className="keyboard-modal-body">
            <UnorderedList>
              {keyboardShortcuts.map((keyboardShortcut) => {
                return (
                  <li key={keyboardShortcut.id}>
                    <span>{keyboardShortcut.text}</span>
                    <KeyboardContainer>
                      {keyboardShortcut.shortcut.map((shortcut) => {
                        return (
                          <Keyboard key={shortcut.key} style={keyShadow}>
                            {shortcut.key}
                          </Keyboard>
                        );
                      })}
                    </KeyboardContainer>
                  </li>
                );
              })}
            </UnorderedList>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
