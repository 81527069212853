/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Accept } from 'react-dropzone';

export const TOP_BAR_HEIGHT = 64;

/**
 * Width set for the panel component
 */
export const PANEL_WIDTH = 300;

/**
 * Color Item Height
 */
export const COLOR_ITEM_HEIGHT = 20;

/**
 * Color Item Width
 */
export const COLOR_ITEM_WIDTH = 20;

/**
 * Floatin Menu Width
 */
export const FLOATING_MENU_WIDTH = 40;

/**
 * Scale Step for Zoom In/Out
 */
export const SCALE_STEP = 0.25;

/**
 * Base Player Width
 */
export const BASE_PLAYER_WIDTH = 33;

/**
 * Default Animation
 */
// eslint-disable-next-line no-secrets/no-secrets
export const DEFAULT_ANIMATION_URL = 'https://lottie.host/3ba4b372-1a5b-48b3-9fe0-73d5f807a3f0/l907uAvkm9.json';

/**
 * Accepted Image Formats for Image Asset Replacement
 */
export const ACCEPTED_IMAGE_FORMATS: Accept = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/gif': ['.gif'],
};

/**
 * Accepted Origins for Lottie Files Editor
 */
export const ACCEPTED_ORIGINS = ['web', 'iconscout', 'workflow'];

/**
 * Maxiumum File Size f or Image Asset Replacement
 */
export const MAX_SIZE = 1000000;

/**
 * Maxium files accepted for Image Asset Replacement
 */
export const MAX_FILES = 1;

/**
 * How many nodes to display under parents
 */
export const LAYER_DEPTH = 2;

/**
 * Query Search Param for  File URL
 */
export const QUERY_PARAMETER_FILE_URL = 'fileUrl';

/**
 * Query parameter for filename
 */
export const QUERY_PARAMETER_FILE_NAME = 'fileName';

/**
 * Query parameter for the background color
 */
export const QUERY_PARAMETER_BACKGROUND_COLOR = 'bg_color';

/**
 * Query Parmeter for Return Back URL after editing
 */
export const QUERY_PARAMETER_RETURN_URL = 'src';

/**
 * Query Parmeter for Return Back Hash after editing
 */
export const QUERY_PARAMETER_RETURN_HASH = 'hash';
/**
 *
 * Query Parmeter for Return Back Hash after editing
 */
export const QUERY_PARAMETER_ORIGIN = 'origin';

/**
 * Lolo searching with m agnifying glass animation
 * Used in:
 * empty-frontpage.tsx
 */
// eslint-disable-next-line no-secrets/no-secrets
export const LOLO_MAGNIFYING_GLASS = 'https://lottie.host/9d467775-6209-4e3f-8879-07131601e21c/rQfOw5AVvY.json';

/**
 * Left panel Layer Name Text Width
 */
export const TEXT_WIDTH_DEPTH_ZERO = 166;
