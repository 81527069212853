/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';
import ReactLagRadar from 'react-lag-radar';

import { styled } from '../../config/stitches';

const StyledContainer = styled('div', {
  position: 'fixed',
  top: 0,
  right: 0,
});

export const LagRadar: React.FC = () => {
  return (
    <StyledContainer>
      <ReactLagRadar size={200} />
    </StyledContainer>
  );
};
