/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Color, Layer } from '@lottiefiles/toolkit-js';
import {
  Scene,
  AVLayer,
  TextLayer,
  AnimatedGradientProperty,
  StaticColorProperty,
  AnimatedColorProperty,
  PrecompositionLayer,
} from '@lottiefiles/toolkit-js';

import { visitAllLayers } from './visit-all-layers';

interface ColorEntry {
  color: Color;
  keyframe: number;
  nodeId: string;
}
interface ColorRegistry {
  [key: string]: ColorEntry[];
}
type ColorList = ColorEntry[];

export const getColorKey = (color: Color): string => {
  return `${color.red}-${color.green}-${color.blue}-${color.alpha}`;
};

type AppendColor = (colorStorage: ColorRegistry | ColorList, colorEntry: ColorEntry) => void;
const appendColor: AppendColor = (colorStorage, colorEntry): void => {
  if (Array.isArray(colorStorage)) {
    colorStorage.push(colorEntry);
  } else {
    const colorKey = getColorKey(colorEntry.color);

    if (!(colorKey in colorStorage)) {
      colorStorage[colorKey] = [];
    }

    colorStorage[colorKey]?.push(colorEntry);
  }
};

type ExtractLayerColors = (args: { colorList: ColorList; colorRegistry: ColorRegistry; layer: Layer }) => void;

const extractLayerColors: ExtractLayerColors = ({ colorList, colorRegistry, layer }): void => {
  if (layer instanceof PrecompositionLayer) return;

  if (layer instanceof TextLayer) {
    layer.textData.track.keyFrames.forEach((keyframe) => {
      [keyframe.value.fontColor, keyframe.value.strokeColor].forEach((color) => {
        if (!color) return;

        const colorEntry = {
          color,
          keyframe: keyframe.frame,
          nodeId: layer.nodeId,
        };

        appendColor(colorRegistry, colorEntry);
        appendColor(colorList, colorEntry);
      });
    });
  } else if (layer instanceof AVLayer) {
    [
      layer.colors.fillProps,
      layer.colors.strokeProps,
      layer.colors.effectValueProps,
      layer.colors.gradientStrokeProps,
      layer.colors.gradientFillProps,
      layer.colors.solidColorProps,
    ].forEach((colorProps) => {
      colorProps.forEach((colorProp) => {
        if (colorProp instanceof AnimatedGradientProperty) {
          colorProp.track.keyFrames.forEach((keyframe) => {
            keyframe.value.colorStops.forEach((colorStop) => {
              if (!colorProp.parent) return;

              const colorEntry = {
                color: colorStop.color,
                keyframe: keyframe.frame,
                nodeId: colorProp.parent.nodeId,
              };

              appendColor(colorRegistry, colorEntry);
              appendColor(colorList, colorEntry);
            });
          });
        } else if (colorProp instanceof AnimatedColorProperty) {
          colorProp.track.keyFrames.forEach((keyframe) => {
            if (!colorProp.parent) return;

            const colorEntry = {
              color: keyframe.value,
              keyframe: keyframe.frame,
              nodeId: colorProp.parent.nodeId,
            };

            appendColor(colorRegistry, colorEntry);
            appendColor(colorList, colorEntry);
          });
        } else if (colorProp instanceof StaticColorProperty) {
          const colorEntry = {
            color: colorProp.value,
            keyframe: 0,
            nodeId: layer.nodeId,
          };

          appendColor(colorRegistry, colorEntry);
          appendColor(colorList, colorEntry);
        }
      });
    });
  }
};

export const getColors = (node: Scene | Layer): { allColors: ColorEntry[]; uniqueColors: ColorRegistry } => {
  const colorRegistry: ColorRegistry = {};
  const colorList: ColorList = [];

  const set = new Set();

  if (node instanceof Scene) {
    node.allLayers.forEach((layer) => {
      visitAllLayers(layer, ({ layer: nestedLayer }) => {
        if (set.has(nestedLayer.nodeId)) return;

        extractLayerColors({ colorList, colorRegistry, layer: nestedLayer });
        set.add(nestedLayer.nodeId);
      });
    });
  } else {
    visitAllLayers(node, ({ layer: nestedLayer }) => {
      if (set.has(nestedLayer.nodeId)) return;

      extractLayerColors({ colorList, colorRegistry, layer: nestedLayer });
      set.add(nestedLayer.nodeId);
    });
  }

  return { uniqueColors: colorRegistry, allColors: colorList };
};
