/**
 * Copyright 2022 Design Barn Inc.
 */

import { IconButton } from '@lottiefiles/ds-core';
import { styled } from '@stitches/react';
import type { CSSProperties } from 'react';
import React, { forwardRef } from 'react';

export interface ActionProps extends React.HTMLAttributes<HTMLButtonElement> {
  active?: {
    background: string;
    fill: string;
  };
  collapsed?: boolean;
  cursor?: CSSProperties['cursor'];
  depth: number;
}

const StyledArrow = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Action = forwardRef<HTMLButtonElement, ActionProps>(
  ({ active, collapsed, cursor, depth, ...props }, ref) => {
    return (
      <StyledArrow style={{ width: depth > 0 ? '32px' : '24px', height: depth > 0 ? '32px' : '24px' }}>
        <IconButton
          name="chevron-filled-down"
          ref={ref}
          {...props}
          tabIndex={0}
          size="xs"
          style={{ backgroundColor: 'transparent' }}
          iconStyle={{
            color: '$textIconTertiary',
            transition: 'all 150ms ease-in',
            transform: collapsed ? 'rotate(-90deg)' : '',
          }}
        ></IconButton>
      </StyledArrow>
    );
  },
);
