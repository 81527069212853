/**
 * Copyright 2021 Design Barn Inc.
 */

// eslint-disable-next-line import/no-namespace
import * as SliderPrimitive from '@radix-ui/react-slider';
import React from 'react';

import { styled } from '../../config/stitches';

interface SeekerProps {
  max: number;
  onChange: (e: number) => void;
  value: number;
}

const StyledSlider = styled(SliderPrimitive.Root, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  touchAction: 'none',
  width: '100%',

  '&[data-orientation="horizontal"]': {
    height: 20,
  },

  '&[data-orientation="vertical"]': {
    flexDirection: 'column',
    width: 20,
    height: 100,
  },
});

const StyledTrack = styled(SliderPrimitive.Track, {
  backgroundColor: '$gray100',
  position: 'relative',
  flexGrow: 1,
  borderRadius: '9999px',

  '&[data-orientation="horizontal"]': { height: 4 },
  '&[data-orientation="vertical"]': { width: 3 },
});

const StyledRange = styled(SliderPrimitive.Range, {
  position: 'absolute',
  backgroundColor: '$teal200',
  borderRadius: '9999px',
  height: '100%',
});

const StyledThumb = styled(SliderPrimitive.Thumb, {
  all: 'unset',
  display: 'block',
  width: 16,
  height: 16,
  backgroundColor: '$teal300',
  borderRadius: 10,
  '&:hover': { backgroundColor: '$teal300' },
  '&:focus': { boxShadow: `0 0 0 5px '$teal300'` },
});

const StyledForm = styled('form', {
  width: '100%',
});

export const PlayerSeeker: React.FC<SeekerProps> = ({ max, onChange, value }: SeekerProps) => {
  const handleChange = (frames: number[]): void => {
    if (frames.length && frames[0]) onChange(frames[0]);
  };

  return (
    <StyledForm>
      <StyledSlider
        defaultValue={[0]}
        max={max}
        step={1}
        aria-label="Lottie animation frame slider"
        value={[value]}
        disabled={false}
        onValueChange={handleChange}
      >
        <StyledTrack>
          <StyledRange />
        </StyledTrack>
        <StyledThumb />
      </StyledSlider>
    </StyledForm>
  );
};
