/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { useAnimationURL } from '../hooks';
import { getExtension } from '../utils';

export type AnimationType = 'lottie' | 'json' | null;

const AnimationTypeContext = React.createContext<AnimationType>(null);

interface Props {
  children: React.ReactNode;
}

export const AnimationTypeProvider: React.FC<Props> = ({ children }) => {
  const url = useAnimationURL();

  const fileExtension = getExtension(url);

  let type: AnimationType = null;

  if (fileExtension === 'json') {
    type = 'json';
  } else if (fileExtension === 'lottie') {
    type = 'lottie';
  }

  return <AnimationTypeContext.Provider value={type}>{children}</AnimationTypeContext.Provider>;
};

export const useAnimationType = (): AnimationType => {
  return React.useContext(AnimationTypeContext);
};
