/**
 * Copyright 2022 Design Barn Inc.
 */

import { useEffect, useState } from 'react';

interface WindowSize {
  height: number;
  width: number;
}

export function useWindowResize(): WindowSize {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const onResize = (): void => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', onResize);

    return (): void => window.removeEventListener('resize', onResize);
  }, []);

  return {
    width,
    height,
  };
}
