/**
 * Copyright 2022 Design Barn Inc.
 */

import { FlexItem } from '@lottiefiles/ds-core';
import type { Player as ReactPlayer } from '@lottiefiles/react-lottie-player';
import type { Layer, PrecompositionAsset, Scene } from '@lottiefiles/toolkit-js';
import { PrecompositionLayer } from '@lottiefiles/toolkit-js';
import type { FC } from 'react';
import React from 'react';
import { useUpdateEffect } from 'react-use';
import { useRecoilValue } from 'recoil';

import { styled } from '../../../config/stitches';
import { useKeypress } from '../../../hooks';
import { selectedLayerIdAtom } from '../../../state';
import { useScene, useToolkit } from '../../../toolkit';
import { TOP_BAR_HEIGHT, PANEL_WIDTH } from '../../../utils';

import { SortableTree } from './tree/sortable-tree';
import type { TreeItem, TreeItems } from './tree/types';

const Panel = styled(FlexItem, {
  width: PANEL_WIDTH,
  height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
  backgroundColor: '$white',
});

const StyledLeftPanel = styled(Panel, {
  borderRight: '1px solid $white',
  overflowY: 'auto',
});

const LAYER_MAX_DEPTH = 3;

const traverseTree = (tree: TreeItem[], callback: (treeItem: TreeItem) => void): void => {
  const stack: TreeItem[] = [...tree];

  while (stack.length > 0) {
    const item = stack.pop();

    if (!item) continue;

    // eslint-disable-next-line node/callback-return
    callback(item);

    stack.push(...item.children);
  }
};

const buildTree = (scene: Scene | PrecompositionAsset, prevTree: TreeItems): TreeItems => {
  const prevTreeRegistry = new Map<TreeItem['id'], TreeItem>();

  traverseTree(prevTree, (treeItem): void => {
    prevTreeRegistry.set(treeItem.id, treeItem);
  });

  const mapToTree = (node: Scene | PrecompositionAsset, depth = 1): TreeItem[] =>
    node.allLayers
      .filter((layer) => layer.isEnabled)
      .map((layer) => {
        const prevTreeItem = prevTreeRegistry.get(layer.nodeId);

        return {
          id: layer.nodeId,
          collapsed: prevTreeItem?.collapsed ?? true,
          children:
            layer instanceof PrecompositionLayer && layer.asset && depth < LAYER_MAX_DEPTH
              ? mapToTree(layer.asset, depth + 1)
              : [],
          drawOrder: layer.drawOrder,
        };
      });

  const newTree = mapToTree(scene);

  return newTree;
};

export interface LeftPanelProps {
  playerRef?: React.RefObject<ReactPlayer>;
}

export const LeftPanel: FC = React.memo(({ playerRef }: LeftPanelProps) => {
  const scene = useScene();
  const [tree, setTree] = React.useState<TreeItems>(() => {
    return scene ? buildTree(scene, []) : [];
  });
  const toolkit = useToolkit();
  const selectedLayerId = useRecoilValue(selectedLayerIdAtom);
  const selectedLayer = toolkit.getNodeById(selectedLayerId) as Layer | null;

  const swapLeft = (index: number): void => {
    if (index <= 0) return;

    const target = tree[index - 1];

    if (target) {
      selectedLayer?.setDrawOrder(target.drawOrder);
    }
  };

  const swapRight = (index: number): void => {
    if (index >= tree.length - 1) return;

    const target = tree[index + 1];

    if (target) {
      selectedLayer?.setDrawOrder(target.drawOrder);
    }
  };

  const handleMoveLayerUp = (): void => {
    if (!selectedLayerId) return;

    const idx = tree.findIndex((element) => element.id === selectedLayerId);

    swapLeft(idx);
  };

  const handleMoveLayerDown = (): void => {
    if (!selectedLayerId) return;

    const idx = tree.findIndex((element) => element.id === selectedLayerId);

    swapRight(idx);
  };

  useKeypress('[', handleMoveLayerDown);
  useKeypress(']', handleMoveLayerUp);

  useUpdateEffect(() => {
    if (scene?.state) {
      setTree((prevTree) => {
        const newTree = buildTree(scene, prevTree);

        return newTree;
      });
    }
  }, [scene?.state]);

  return (
    <StyledLeftPanel>
      <SortableTree collapsible indicator removable data={tree} playerRef={playerRef} />
    </StyledLeftPanel>
  );
});
