/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Scene } from '@lottiefiles/toolkit-js';
import React from 'react';
import { useUpdate } from 'react-use';

import { useToolkit } from '../provider';

import { useToolkitNode } from './use-toolkit-node';

export function useScene(): Scene | null {
  const toolkit = useToolkit();
  const rerender = useUpdate();

  const scene = useToolkitNode(toolkit.scenes[0]?.nodeId || '') as Scene | null;

  React.useEffect(() => {
    toolkit.events.addEventListener('addScene', rerender);
    toolkit.events.addEventListener('removeScene', rerender);

    return (): void => {
      toolkit.events.removeEventListener('addScene', rerender);
      toolkit.events.removeEventListener('removeScene', rerender);
    };
  }, [toolkit, rerender]);

  return scene;
}
