/**
 * Copyright 2022 Design Barn Inc.
 */

import type { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import React from 'react';

import { Bugsnag } from '../../lib';

export const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) as BugsnagErrorBoundary;
