/**
 * Copyright 2022 Design Barn Inc.
 */

import type { CSS as StitchesCSS } from '@stitches/react';
import { createStitches } from '@stitches/react';

export const {
  config: stitches,
  createTheme,
  css,
  getCssText,
  globalCss,
  keyframes,
  styled,
} = createStitches({
  theme: {
    colors: {
      brandLottiefiles: '$teal200',
      brandIconscout: '$blue200',
      backgroundDefault: '$white',
      backgroundSubtle: '$gray50',
      backgroundStrong: '$gray100',
      textIconPrimary: '$gray900',
      textIconSecondary: '$gray600',
      textIconTertiary: '$gray500',
      textIconPlaceholder: '$gray400',
      textIconDisabled: '$gray300',
      textIconInverse: '$white',
      textIconError: '$red500',
      borderDefault: '$gray200',
      borderStrong: '$gray900',
      borderSubtle: '$gray100',
      actionPrimary: '$teal300',
      actionPrimaryHover: '$teal400',
      actionSecondary: '$gray50',
      actionSecondaryHover: '$gray100',
      actionTransparent: '#ffffff00',
      actionGhostHover: '$gray50',
      actionCritical: '$red500',
      actionCriticalHover: '$red600',
      actionActionDisabled: '$gray100',
      actionActionFocus: '$teal200',
      actionLink: '$teal300',
      actionLinkHover: '$teal400',
      white: '#ffffff',
      black: '#000000',
      gray50: '#f3f6f8',
      gray100: '#d9e0e6',
      gray200: '#bfc8d1',
      gray300: '#a1adb7',
      gray400: '#808e9a',
      gray500: '#63727e',
      gray600: '#4c5863',
      gray700: '#3d4853',
      gray800: '#333c45',
      gray900: '#20272c',
      green50: '#eef8ef',
      green100: '#c4e9c4',
      green200: '#94d793',
      green300: '#50c154',
      green400: '#00951d',
      green500: '#00820f',
      green600: '#006600',
      green700: '#13530d',
      green800: '#174410',
      green900: '#142b0f',
      teal50: '#d6fef1',
      teal100: '#5cf8cf',
      teal200: '#00ddb3',
      teal300: '#00c1a2',
      teal400: '#019d91',
      teal500: '#007d7c',
      teal600: '#006262',
      teal700: '#004f4f',
      teal800: '#014242',
      teal900: '#002b2b',
      blue50: '#e9f8fe',
      blue100: '#b0e8fd',
      blue200: '#00c6ff',
      blue300: '#00b6fe',
      blue400: '#0092e4',
      blue500: '#0073c2',
      blue600: '#0857a0',
      blue700: '#1a487b',
      blue800: '#1c3c62',
      blue900: '#17273a',
      indigo50: '#f0f6fe',
      indigo100: '#d0e0fe',
      indigo200: '#b2c6fe',
      indigo300: '#92a7fe',
      indigo400: '#7180fd',
      indigo500: '#5359fd',
      indigo600: '#3c26fe',
      indigo700: '#2e03e5',
      indigo800: '#2500c4',
      indigo900: '#200082',
      purple50: '#f6f4fa',
      purple100: '#e3ddf5',
      purple200: '#cfbef6',
      purple300: '#ba9bfc',
      purple400: '#9c73f8',
      purple500: '#8746ff',
      purple600: '#6243ab',
      purple700: '#4e3a82',
      purple800: '#403266',
      purple900: '#28223d',
      pink50: '#fdf2fd',
      pink100: '#fcd2fc',
      pink200: '#fdabfa',
      pink300: '#ff8eec',
      pink400: '#ed509e',
      pink500: '#d32370',
      pink600: '#a81752',
      pink700: '#871844',
      pink800: '#6d1b3a',
      pink900: '#431725',
      red50: '#fef3f3',
      red100: '#fdd6d5',
      red200: '#ffb4b1',
      red300: '#ff8a7d',
      red400: '#ff3c00',
      red500: '#d92600',
      red600: '#af1400',
      red700: '#910a00',
      red800: '#7c0400',
      red900: '#550000',
      orange50: '#fef2ea',
      orange100: '#fed8be',
      orange200: '#ffb887',
      orange300: '#ff8e3a',
      orange400: '#ff7100',
      orange500: '#bb4f00',
      orange600: '#933d00',
      orange700: '#7a3100',
      orange800: '#672800',
      orange900: '#431a00',
      amber50: '#fef5bd',
      amber100: '#f8e152',
      amber200: '#e3cb00',
      amber300: '#c0ac00',
      amber400: '#958f00',
      amber500: '#747400',
      amber600: '#5a5a00',
      amber700: '#494900',
      amber800: '#3d3e00',
      amber900: '#272700',
      yellow50: '#fef3dd',
      yellow100: '#fede7e',
      yellow200: '#ffbf00',
      yellow300: '#d8a300',
      yellow400: '#b18500',
      yellow500: '#8f6a00',
      yellow600: '#715200',
      yellow700: '#5d4200',
      yellow800: '#4e3700',
      yellow900: '#342300',
    },
    sizes: {
      size1: '1px',
      size2: '2px',
      size3: '4px',
      size4: '8px',
      size5: '10px',
      size6: '12px',
      size7: '14px',
      size8: '16px',
      size9: '18px',
      size10: '20px',
      size11: '22px',
      size12: '24px',
      size13: '28px',
      size14: '32px',
      size15: '36px',
      size16: '40px',
      size17: '48px',
      size18: '64px',
      size19: '96px',
      iconSizeSm: '$size8',
      iconSizeMd: '$size12',
      iconSizeLg: '$size14',
    },
    fonts: {
      arboria: 'Arboria, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif',
      arboriaBlack:
        'Arboria Black, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif',
      karla: 'Karla, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif',
      karlaLight: 'Karla Light, serif',
      karlaExtraLight: 'Karla ExtraLight, sans-serif',
      karlaMedium: 'Karla Medium, sans-serif',
      karlaSemiBold: 'Karla SemiBold, sans-serif',
      karlaBold: 'Karla Bold, sans-serif',
      karlaExtraBold: 'Karla ExtraBold, sans-serif',
      fontFamilyBrand: '$arboria',
      fontFamilyProduct: '$karla',
    },
    fontSizes: {
      fontSize2xs: '$sizes$size5',
      fontSizeXs: '$sizes$size6',
      fontSizeSm: '$sizes$size7',
      fontSizeBase: '$sizes$size8',
      fontSizeMd: '$sizes$size9',
      fontSizeLg: '$sizes$size10',
      fontSizeXl: '$sizes$size12',
      fontSize2xl: '$sizes$size13',
      fontSize3xl: '$sizes$size15',
      fontSize4xl: '$sizes$size17',
      fontSize5xl: '$sizes$size18',
      fontSize6xl: '$sizes$size19',
    },
    space: {
      space1: '$sizes$size2',
      space2: '$sizes$size3',
      space3: '$sizes$size4',
      space4: '$sizes$size6',
      space5: '$sizes$size8',
      space6: '$sizes$size10',
      space7: '$sizes$size12',
      space8: '$sizes$size14',
      space9: '$sizes$size16',
      space10: '$sizes$size17',
      space11: '$sizes$size18',
      space12: '$sizes$size19',
    },
    radii: {
      roundedBase: '$sizes$size3',
      roundedSm: '$sizes$size4',
      roundedMd: '$sizes$size6',
      roundedLg: '$sizes$size8',
      roundedXl: '$sizes$size10',
      rounded2xl: '$sizes$size16',
      roundedFull: '9999px',
    },
    fontWeights: {
      fontRegular: 'normal',
      fontBold: 'bold',
    },
    lineHeights: {
      lineHeight1: '120%',
      lineHeight2: '130%',
      lineHeight3: '150%',
    },
    letterSpacings: { letterSpacingTight: '-2%' },
    borderWidths: {},
    borderStyles: {},
    shadows: {
      shadowCard: '0 2px 6px 0 #0000000f',
      shadowDropdown: '0 2px 8px 0 #0000001a',
      shadowHeader: '0 4px 12px 0 #0000001f',
      shadowPopover: '0 8px 16px 0 #00000029',
      shadowModal: '0 12px 24px 0 #00000033',
      focusOnLight: '0 0 0 1px #ffffff, 0 0 0 3px #00ddb3',
      focusOnDark: '0 0 0 1px #22292f, 0 0 0 3px #00ddb3',
    },
    zIndices: {
      zIndex1: 1,
      zIndexCard: 10,
      zIndexHeader: 20,
      zIndexPopover: 30,
      zIndexModalOverlay: 40,
      zIndexModal: 50,
      zIndexHighest: 9999,
    },
    transitions: {},
  },
  media: {
    // bp1: '(min-width: 640px)',
    // bp2: '(min-width: 768px)',
    // bp3: '(min-width: 1024px)',
    xs: '(min-width: 576px)',
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
    xl: '(min-width: 1280px)',
  },
  utils: {
    gridColums: (value: number) => ({
      margin: value,
      gridTemplateColumns: `repeat(${value}, '1fr')`,
    }),
    fontBold: (value: string) => ({
      fontFamily: value === 'product' ? '$karlaBold' : '$arboriaBlack',
    }),
  },
});

export type CSS<T = typeof stitches> = StitchesCSS<T>;

const ASSET_DIR = '/assets/';

export const globalStyles = globalCss({
  '@font-face': [
    {
      fontFamily: 'Arboria',
      src: `local("Arboria-Bold"), url("${ASSET_DIR}Arboria-Bold.woff") format("woff")`,
      fontWeight: '$bold',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Arboria Black',
      src: `local("Arboria-Black"), url("${ASSET_DIR}Arboria-Black.woff") format("woff")`,
      fontWeight: '$bolder',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Karla ExtraLight',
      src: `local("Karla-ExtraLight"), url("${ASSET_DIR}Karla-ExtraLight.woff") format("woff")`,
      fontWeight: '$lighter',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Karla ExtraLight',
      src: `local("Karla-ExtraLightItalic"), url("${ASSET_DIR}Karla-ExtraLightItalic.woff") format("woff")`,
      fontWeight: '$lighter',
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Karla Light',
      src: `local("Karla-Light"), url("${ASSET_DIR}Karla-Light.woff") format("woff")`,
      fontWeight: '$light',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Karla Light',
      src: `local("Karla-LightItalic"), url("${ASSET_DIR}Karla-LightItalic.woff") format("woff")`,
      fontWeight: '$light',
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Karla',
      src: `local("Karla-Regular"), url("${ASSET_DIR}Karla-Regular.woff") format("woff")`,
      fontWeight: '$regular',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Karla',
      src: `local("Karla-Italic"), url("${ASSET_DIR}Karla-Italic.woff") format("woff")`,
      fontWeight: '$regular',
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Karla Medium',
      src: `local("Karla-Medium"), url("${ASSET_DIR}Karla-Medium.woff") format("woff")`,
      fontWeight: '$medium',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Karla Medium',
      src: `local("Karla-MediumItalic"), url("${ASSET_DIR}Karla-MediumItalic.woff") format("woff")`,
      fontWeight: '$medium',
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Karla SemiBold',
      src: `local("Karla-SemiBold"), url("${ASSET_DIR}Karla-SemiBold.woff") format("woff")`,
      fontWeight: '$semiBold',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Karla SemiBold',
      src: `local("Karla-SemiBoldItalic"), url("${ASSET_DIR}Karla-SemiBoldItalic.woff") format("woff")`,
      fontWeight: '$semiBold',
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Karla Bold',
      src: `local("Karla-Bold"), url("${ASSET_DIR}Karla-Bold.woff") format("woff")`,
      fontWeight: '$bold',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Karla Bold',
      src: `local("Karla-BoldItalic"), url("${ASSET_DIR}Karla-BoldItalic.woff") format("woff")`,
      fontWeight: '$bold',
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Karla ExtraBold',
      src: `local("Karla-ExtraBold"), url("${ASSET_DIR}Karla-ExtraBold.woff") format("woff")`,
      fontWeight: '$boldest',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Karla ExtraBold',
      src: `local("Karla-ExtraBoldItalic"), url("${ASSET_DIR}Karla-ExtraBoldItalic.woff") format("woff")`,
      fontWeight: '$boldest',
      fontStyle: 'italic',
    },
  ],
});
