/**
 * Copyright 2022 Design Barn Inc.
 */

import { Text, TextInput, Icon } from '@lottiefiles/ds-core';
import type { Layer } from '@lottiefiles/toolkit-js';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { styled } from '../../config/stitches';
import { selectedLayerIdAtom } from '../../state/ui/atom';
import { useToolkitNode, useToolkit } from '../../toolkit';

interface LayerAttributesProps {}

const StyledLayerAttributesContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$space4',
});

const StyledErrorContainer = styled('div', {
  display: 'inline-flex',
  color: '$textIconError',
});

const StyledBottomBanner = styled('div', {
  padding: '$space3 $space4',
  fontFamily: '$karla',
  fontSize: '$fontSizeXs',
  lineHeight: '$lineHeight3',
  backgroundColor: '$blue50',
  borderRadius: '$roundedSm',
});

export const LayerAttributes: FC<LayerAttributesProps> = () => {
  const toolkit = useToolkit();
  const selectedLayerId = useRecoilValue(selectedLayerIdAtom);
  const selectedLayer = useToolkitNode(selectedLayerId) as Layer | null;
  const [layerName, setLayerName] = useState(selectedLayer?.name || '');
  const [layerId, setLayerId] = useState(selectedLayer?.nodeId || '');
  const [layerClasses, setLayerClasses] = useState<string>(selectedLayer?.classNames.join(' ') || '');
  const isInvalidLayerId = /\s/u.test(layerId);

  // When layer is highlighted, set its properties in panel
  useEffect(() => {
    if (selectedLayer) {
      setLayerName(selectedLayer.name);
      setLayerId(selectedLayer.id);
      setLayerClasses(selectedLayer.classNames.join(' '));
    }
  }, [selectedLayer?.state]);

  const commitChangeToToolkit = (): void => {
    if (!selectedLayer) return;

    toolkit.batch(() => {
      if (layerName && selectedLayer.name !== layerName) {
        selectedLayer.setName(layerName);
      }

      if (!isInvalidLayerId && selectedLayer.id !== layerId) {
        selectedLayer.setId(layerId);
      }

      if (selectedLayer.classNames.join(' ') !== layerClasses) {
        selectedLayer.setClassNames(layerClasses.split(' '));
      }
    });
  };

  return (
    <form
      autoComplete="off"
      onSubmit={(event): void => {
        event.preventDefault();
        commitChangeToToolkit();
      }}
    >
      <StyledLayerAttributesContainer>
        <div>
          <TextInput
            id="layerName"
            label="Layer Name"
            placeholder="Layer name"
            inputSize={'sm'}
            containerStyle={{ height: '100%', justifyContent: 'center' }}
            inputWrapperStyle={{ padding: '3px 12px' }}
            value={layerName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              setLayerName(event.target.value);
            }}
            type="text"
            onBlur={(): void => {
              commitChangeToToolkit();
            }}
          />
        </div>

        <div>
          <TextInput
            id="layerId"
            label="Layer ID"
            placeholder="layerID"
            inputSize={'sm'}
            containerStyle={{ height: '100%', justifyContent: 'center' }}
            inputWrapperStyle={{ padding: '3px 12px' }}
            value={layerId}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              setLayerId(event.target.value);
            }}
            onBlur={(): void => {
              commitChangeToToolkit();
            }}
          />
        </div>

        {isInvalidLayerId && (
          <StyledErrorContainer>
            <Icon name="warning" size="sm" style={{ marginRight: '4px' }}></Icon>
            <Text
              plugins={false}
              variant="caption1"
              customTag="div"
              textStyle={{ fontWeight: 700, padding: 0, margin: 0 }}
            >
              Must not contain any spaces.
            </Text>
          </StyledErrorContainer>
        )}

        <div>
          <TextInput
            id="classes"
            label="Layer class(es)"
            placeholder="class1 class2"
            inputSize={'sm'}
            containerStyle={{ height: '100%', justifyContent: 'center' }}
            inputWrapperStyle={{ padding: '3px 12px' }}
            value={layerClasses}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              setLayerClasses(event.target.value);
            }}
            onBlur={(): void => {
              commitChangeToToolkit();
            }}
          />
          <Text
            plugins={false}
            variant="caption1"
            customTag="div"
            textStyle={{ color: '$textIconPlaceholder', fontSize: '$fontSize2xs', marginTop: '$space3' }}
          >
            Seperate multiple classes with spaces.
          </Text>
        </div>

        <StyledBottomBanner>
          <span>The layer ID and classes let you target the layers of animations when embedded on webpages.</span>
        </StyledBottomBanner>
        <input type="submit" hidden />
      </StyledLayerAttributesContainer>
    </form>
  );
};
