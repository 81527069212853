/**
 * Copyright 2022 Design Barn Inc.
 */

import type { RgbaColor } from 'react-colorful';
import { atom } from 'recoil';

export const backgroundColorValueAtom = atom<RgbaColor>({
  key: 'animationBackgroundValue',
  default: { r: 243, g: 246, b: 248, a: 1 },
});

export const animationZoomStateAtom = atom({
  key: 'animationZoom',
  default: 1,
});

export const selectedLayerIdAtom = atom({
  key: 'selectedLayerId',
  default: '',
});

export const highlightedLayerIdAtom = atom({
  key: 'highlightedLayerId',
  default: '',
});

export const wiredModeAtom = atom({
  key: 'wiredMode',
  default: false,
});

export const boundingBoxAtom = atom({
  key: 'boundingBox',
  default: false,
});

export const keyboardModalOpenAtom = atom({
  key: 'keyboardModalOpen',
  default: false,
});

export const transparentBackgroundAtom = atom({
  key: 'transparentBackground',
  default: false,
});

export const changeLayerDrawOrderAtom = atom({
  key: 'changeLayerDrawOrder',
  default: { layerId: '-1000', newDrawOrder: 0 },
});

export const errorToastOpenAtom = atom({
  key: 'errorToastOpen',
  default: false,
});

export const canvasDimensionsAtom = atom({
  key: 'canvasDimensions',
  default: { width: 0, height: 0 },
});
