/**
 * Copyright 2022 Design Barn Inc.
 */

import { FlexItem } from '@lottiefiles/ds-core';

import { styled } from '../../../config/stitches';

import { DownloadButton } from './download-button';
import { SaveButton } from './save-button';
import { SupportButton } from './support-button';

const StyledTopbarActionsContainer = styled(FlexItem, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '196px',
  gap: '$space5',
});

export const ActionControls = (): JSX.Element => {
  return (
    <StyledTopbarActionsContainer customStyles={{}}>
      <SupportButton />

      <DownloadButton />

      <SaveButton />
    </StyledTopbarActionsContainer>
  );
};
