/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { useViewerQuery } from '../generated/generated-types';
import { Bugsnag } from '../lib';

const AuthContext = React.createContext<boolean>(false);

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [result] = useViewerQuery();

  React.useEffect(() => {
    if (result.data?.viewer) {
      Bugsnag.setUser(result.data.viewer.id);
    }
  }, [result.data?.viewer]);

  const isLoggedIn = Boolean(result.data?.viewer);

  return <AuthContext.Provider value={isLoggedIn}>{children}</AuthContext.Provider>;
};

export const useAuth = (): boolean => {
  return React.useContext(AuthContext);
};
