/**
 * Copyright 2022 Design Barn Inc.
 */

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { envConfig } from '../config/env';

let releaseStage = '';

if (envConfig.isLocal) {
  releaseStage = 'local';
} else if (envConfig.isDev) {
  releaseStage = 'development';
} else if (envConfig.isStaging) {
  releaseStage = 'staging';
} else if (envConfig.isProd) {
  releaseStage = 'production';
}

if (!Bugsnag.isStarted()) {
  Bugsnag.start({
    apiKey: envConfig.bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
    releaseStage,
    enabledReleaseStages: ['development', 'staging', 'production'],
  });
}

export { Bugsnag };
