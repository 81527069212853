/**
 * Copyright 2022 Design Barn Inc.
 */

import { Icon, IconButton, Text } from '@lottiefiles/ds-core';
import { Player } from '@lottiefiles/react-lottie-player';
import type { Layer } from '@lottiefiles/toolkit-js';
import { styled } from '@stitches/react';
import classNames from 'classnames';
import React, { forwardRef, useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { highlightedLayerIdAtom, selectedLayerIdAtom } from '../../../../../../state';
import {
  useExportLayerToLottie,
  useToolkitNode,
  removeLayer as removeLayerFromScene,
  setIsHidden,
  useToolkit,
} from '../../../../../../toolkit';
import { TEXT_WIDTH_DEPTH_ZERO } from '../../../../../../utils';
import { Action } from '../Item/components/action';

import classes from './tree-item.module.css';

export interface TreeItemProps {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: string;
  handleProps?: any;
  highlighted?: boolean;
  indentationWidth: number;
  indicator?: boolean;
  onCollapse?(): void;
  onRemove?(): void;
  selected?: boolean;
  value: string;
  wrapperRef?(node: HTMLLIElement): void;
}

const StyledAnimationThumb = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$roundedBase',
  backgroundColor: '$gray50',
  width: '32px',
  height: '32px',
});

const StyledTextContainer = styled('div', {
  marginLeft: '8px',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
});

const StyledTextImageIconContainer = styled('div', {
  width: '32px',
  height: '32px',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  display: 'flex',
});

export const TreeItem = React.memo(
  forwardRef<HTMLDivElement, TreeItemProps>(
    (
      {
        childCount,
        clone,
        collapsed,
        depth,
        disableInteraction,
        disableSelection,
        ghost,
        handleProps,
        highlighted,
        indentationWidth,
        indicator,
        onCollapse,
        onRemove,
        selected,
        style,
        value,
        wrapperRef,
        ...props
      },
      ref,
    ) => {
      const layer = useToolkitNode(value) as Layer | null;
      const lottie = useExportLayerToLottie(layer);
      const setHighlightedLayerId = useSetRecoilState(highlightedLayerIdAtom);
      const setSelectedLayerId = useSetRecoilState(selectedLayerIdAtom);
      const toolkit = useToolkit();
      const textWidthAtZeroDepth = TEXT_WIDTH_DEPTH_ZERO;
      const [childMouseDown, setChildMouseDown] = useState(false);

      const hidden = layer?.isHidden ?? false;
      const name = layer?.name ?? '';
      const type = layer?.type ?? '';

      const highlightLayer = React.useCallback(() => {
        if (hidden) return;
        setHighlightedLayerId(value);
      }, [value, setHighlightedLayerId, hidden]);

      const unhighlightLayer = React.useCallback(() => {
        setHighlightedLayerId('');
        // setIsHighLighted(false);

        if (depth > 0) setChildMouseDown(false);
      }, [setHighlightedLayerId, setChildMouseDown, depth]);

      const selectLayer = React.useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
          if (hidden) return;

          event.stopPropagation();
          setSelectedLayerId(value);

          if (depth > 0) setChildMouseDown(true);
        },
        [value, setSelectedLayerId, hidden, depth, setChildMouseDown],
      );

      const removeLayer = useCallback((): void => {
        if (layer) removeLayerFromScene(layer);
      }, [layer]);

      const changeVisibility = useCallback((): void => {
        if (layer) {
          if (!hidden) setSelectedLayerId('');
          setIsHidden({ layer, isHidden: !hidden, toolkit });
          if (layer.isHidden) {
            setHighlightedLayerId('');
            setSelectedLayerId('');
          } else {
            setHighlightedLayerId(layer.nodeId);
          }
        }
      }, [layer, hidden]);

      function renderThumbnail(): JSX.Element {
        if (type === 'TEXT') {
          return (
            <StyledTextImageIconContainer>
              <Icon name={'text'} size={'sm'}></Icon>
            </StyledTextImageIconContainer>
          );
        } else if (type === 'IMAGE') {
          return (
            <StyledTextImageIconContainer>
              <Icon name={'image'} size={'sm'}></Icon>
            </StyledTextImageIconContainer>
          );
        } else {
          return <Player src={lottie} style={{ width: '32px', height: '32px' }} />;
        }
      }

      if (!layer) return null;

      return (
        <li
          className={classNames(
            classes['wrapper'],
            clone && classes['clone'],
            ghost === value && classes['ghost'],
            indicator && ghost && classes['indicator'],
            disableSelection && classes['disableSelection'],
            disableInteraction && classes['disableInteraction'],
          )}
          onMouseDown={selectLayer}
          onMouseEnter={highlightLayer}
          onMouseUp={(): void => setChildMouseDown(false)}
          onMouseLeave={unhighlightLayer}
          ref={wrapperRef}
          style={
            {
              '--spacing': `${indentationWidth * (depth - 1) + 24}px`,
              boxShadow: `${highlighted ? 'inset 0px 0px 0px 1px #BFC8D1' : 'none'}`,
              backgroundColor: selected && !ghost ? '#F4F6F8' : 'transparent',
              opacity: hidden ? '40%' : '100%',
              cursor: childMouseDown ? 'not-allowed' : 'default',
            } as React.CSSProperties
          }
          {...props}
        >
          <div className={classes['tree-item']} ref={ref} style={style}>
            <div style={{ display: 'flex' }}>
              {onCollapse && <Action onClick={onCollapse} collapsed={collapsed} depth={depth}></Action>}
              {!onCollapse && (
                <span style={{ width: depth > 0 ? '32px' : '24px', height: depth > 0 ? '32px' : '24px' }}></span>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {/* 
              Handle props controls if a layer is draggable.
              As we only want the top layer to be draggable, anything with a depth > 0 should not have handleprops.  
            */}
              <StyledAnimationThumb
                style={{
                  opacity: hidden ? '50%' : '100%',
                  boxShadow: selected ? 'inset 0 0 0 1px #BFC8D1' : 'none',
                }}
              >
                {renderThumbnail()}
              </StyledAnimationThumb>
              {depth > 0 && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <StyledTextContainer>
                    <Text
                      plugins={false}
                      variant={'caption1'}
                      textStyle={{
                        color: '$textIconPrimary',
                        width: `${textWidthAtZeroDepth - indentationWidth * depth}px`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {name}
                    </Text>
                  </StyledTextContainer>
                </div>
              )}
              {depth === 0 && (
                <div
                  {...handleProps}
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <StyledTextContainer>
                    <Text
                      plugins={false}
                      variant={'caption1'}
                      textStyle={{
                        color: '$textIconPrimary',
                        cursor: 'default',
                        width: `${textWidthAtZeroDepth}px`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {name}
                    </Text>
                  </StyledTextContainer>
                </div>
              )}

              <div style={{ display: 'flex', alignItems: 'center' }}>
                {!clone && (
                  <div
                    style={{
                      display: highlighted || selected || hidden ? 'flex' : 'none',
                    }}
                  >
                    <IconButton
                      name={hidden ? 'eye-closed' : 'eye-open-outline'}
                      size="sm"
                      onMouseUp={changeVisibility}
                      onMouseDown={(event: React.MouseEvent<HTMLElement>): void => {
                        // Stop parent <li> from catching mouseDown when clicking on eye icon
                        event.stopPropagation();
                      }}
                      iconStyle={{
                        color: '$textIconTertiary',
                        width: '16px',
                        height: '16px',
                        '&:hover': {
                          color: '$textIconPrimary',
                        },
                      }}
                      buttonStyle={{
                        backgroundColor: 'transparent',
                        float: 'right',
                        marginLeft: 'auto',
                        width: '16px',
                        height: '16px',
                        marginRight: '8px',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        '&:focus': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    />

                    <IconButton
                      name={'delete'}
                      size="sm"
                      onClick={removeLayer}
                      style={{ color: '$actionCritical' }}
                      iconStyle={{
                        color: '$textIconTertiary',
                        width: '16px',
                        height: '16px',
                        '&:hover': {
                          color: '$actionCritical',
                        },
                      }}
                      buttonStyle={{
                        backgroundColor: 'transparent',
                        float: 'right',
                        marginLeft: 'auto',
                        width: '16px',
                        height: '16px',
                        marginRight: '8px',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        opacity: hidden ? '0' : '1',
                        pointerEvents: hidden ? 'none' : 'auto',
                      }}
                    />
                  </div>
                )}
              </div>

              {/* 
              Uncomment to re-active the size indicator
              */}
              {/* {clone && childCount && childCount > 1 ? <span className={classes['count']}>{childCount}</span> : null} */}
            </div>
          </div>
        </li>
      );
    },
  ),
);
