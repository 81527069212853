/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Scene } from '@lottiefiles/toolkit-js';
import { useAsync } from 'react-use';
import type { AsyncState } from 'react-use/lib/useAsync';

import { useAnimationType } from '../../providers/animation-type-provider';
import { lottiePlugin, dotLottiePlugin } from '../plugins';
import { useToolkit } from '../provider';

export function useLottieImporter(url: string): AsyncState<Scene | null> {
  const toolkit = useToolkit();
  const extension = useAnimationType();

  const state = useAsync(async (): Promise<Scene | null> => {
    if (extension === 'json') {
      return toolkit.import(lottiePlugin.id, { animation: url, enableNodeIds: true });
    } else if (extension === 'lottie') {
      return toolkit.import(dotLottiePlugin.id, { dotlottie: url, enableNodeIds: true });
    }

    return Promise.reject(new Error(`Unsupported file extension: ${extension}`));
  }, [url]);

  return state;
}
