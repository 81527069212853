/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Toolkit } from '@lottiefiles/toolkit-js';
import { StateHistory } from '@lottiefiles/toolkit-js';
import React from 'react';
import { useUpdate } from 'react-use';

import { useToolkit } from '../provider';

const ToolkitHistoryContext = React.createContext<StateHistory | null>(null);

export function ToolkitHistoryProvider({
  children,
  toolkit,
}: {
  children: React.ReactNode;
  toolkit: Toolkit;
}): React.ReactElement {
  const [toolkitHistory] = React.useState(() => {
    const history = new StateHistory(toolkit.events, 100);

    history.removeTrackedEvent('addScene', 'removeScene');

    return history;
  });

  return <ToolkitHistoryContext.Provider value={toolkitHistory}>{children}</ToolkitHistoryContext.Provider>;
}

export function useToolkitHistory(): StateHistory {
  const toolkitHistory = React.useContext(ToolkitHistoryContext);

  if (!toolkitHistory) throw new Error('useToolkitHistory shall be used within ToolkitProvider');

  const toolkit = useToolkit();
  const rerender = useUpdate();

  React.useEffect(() => {
    toolkit.events.addGlobalListener(rerender);

    return (): void => {
      toolkit.events.removeGlobalListener(rerender);
    };
  }, [toolkit, rerender]);

  return toolkitHistory;
}
