/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';

import { getLayerDOMElement } from './get-all-layers-dom-elements';

export function addClassName(layer: Layer, className: string): void {
  const el = getLayerDOMElement(layer);

  if (el && !el.classList.contains(className)) {
    el.classList.add(className);
  }
}
