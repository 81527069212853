/**
 * Copyright 2022 Design Barn Inc.
 */

import { IconButton, TooltipRoot as Tooltip, TooltipTrigger, TooltipContent } from '@lottiefiles/ds-core';
import { useCallback } from 'react';

import { useKeypress } from '../../../hooks';
import { useAnimationType } from '../../../providers/animation-type-provider';
import { useScene, useToolkit } from '../../../toolkit';
import { lottiePlugin, dotLottiePlugin } from '../../../toolkit/plugins';

export const DownloadButton = (): JSX.Element => {
  const toolkit = useToolkit();
  const scene = useScene();
  const extension = useAnimationType();

  /**
   * Handle Download button click
   */
  const handleDownload = useCallback((): void => {
    async function exportLottie(): Promise<void> {
      let sceneName;
      let result;
      const aLink = document.createElement('a');

      if (!scene) return;
      if (extension === 'lottie') {
        sceneName = scene.name ? `${scene.name}.lottie` : 'lottie-animation.lottie';

        result = await toolkit.export(dotLottiePlugin.id, {
          scene,
          exportNodeIds: false,
          filePath: sceneName,
        });

        // eslint-disable-next-line node/no-unsupported-features/node-builtins
        aLink.href = URL.createObjectURL(
          new Blob([result], {
            type: 'text/JSON',
          }),
        );
      } else {
        sceneName = scene.name ? `${scene.name}.json` : 'lottie-animation.json';
        result = await toolkit.export(lottiePlugin.id, {
          scene,
          exportNodeIds: false,
          filePath: sceneName,
        });

        // eslint-disable-next-line node/no-unsupported-features/node-builtins
        aLink.href = URL.createObjectURL(
          new Blob([JSON.stringify(result)], {
            type: 'text/JSON',
          }),
        );
      }
      aLink.setAttribute('download', sceneName);
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);
    }

    exportLottie();
  }, [scene]);

  useKeypress('mod+e', handleDownload);

  return (
    <Tooltip>
      <TooltipTrigger
        asChild
        style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <IconButton name="download" onClick={handleDownload} size="sm" iconStyle={{ color: '$textIconPrimary' }} />
      </TooltipTrigger>
      <TooltipContent align="center" side="bottom" contentStyle={{ fontSize: '12px', zIndex: 2 }}>
        Download
      </TooltipContent>
    </Tooltip>
  );
};
