/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  Announcements,
  DragStartEvent,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  DropAnimation,
  Modifier,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  MeasuringStrategy,
  defaultDropAnimation,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { Player as ReactPlayer } from '@lottiefiles/react-lottie-player';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useUpdateEffect } from 'react-use';
import { useRecoilValue } from 'recoil';

import { highlightedLayerIdAtom, selectedLayerIdAtom } from '../../../../state';
import { useToolkit } from '../../../../toolkit';
import { changeDrawOrderWithChildren } from '../../../../toolkit/utils/change-draw-order-with-children';

import { SortableTreeItem } from './components';
import { sortableTreeKeyboardCoordinates } from './keyboard-coordinates';
import type { FlattenedItem, SensorContext, TreeItems } from './types';
import { flattenTree, getProjection, getChildCount, removeChildrenOf, setProperty } from './utilities';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

interface Props {
  collapsible?: boolean;
  data: TreeItems;
  indentationWidth?: number;
  indicator?: boolean;
  playerRef?: React.RefObject<ReactPlayer>;
  removable?: boolean;
}

export function SortableTree({
  collapsible,
  data,
  indentationWidth = 32,
  indicator = false,
  playerRef,
}: Props): JSX.Element {
  const [items, setItems] = useState<TreeItems>(() => data);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    overId: UniqueIdentifier;
    parentId: UniqueIdentifier | null;
  } | null>(null);

  const selectedLayerId = useRecoilValue(selectedLayerIdAtom);
  const highlightedLayerId = useRecoilValue(highlightedLayerIdAtom);

  const [dragMoveStarted, setDragMoveStarted] = useState('');
  const toolkit = useToolkit();

  useUpdateEffect(() => {
    // On data change, re-set the items
    // to do: This is perhaps causing items to not stick to their order after dropping, but is necessary for undo to work
    setItems(data);
  }, [data]);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, { children, collapsed, id }) => (collapsed && children.length ? [...acc, id] : acc),
      [],
    );

    return removeChildrenOf(flattenedTree, activeId ? [activeId, ...collapsedItems] : collapsedItems);
  }, [activeId, items]);

  const projected =
    activeId && overId ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth) : null;

  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth),
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  function resetState(): void {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setDragMoveStarted('');
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  }

  function getMovementAnnouncement(eventName: string, aId: UniqueIdentifier, oId?: UniqueIdentifier): string | void {
    if (oId && projected) {
      if (eventName !== 'onDragEnd') {
        if (currentPosition && projected.parentId === currentPosition.parentId && oId === currentPosition.overId) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            oId,
          });
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));
      const overIndex = clonedItems.findIndex(({ id }) => id === oId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === aId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement = '';
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];

        if (nextItem) announcement = `${aId} was ${movedVerb} before ${nextItem.id}.`;
      } else if (projected.depth > previousItem.depth) {
        announcement = `${aId} was ${nestedVerb} under ${previousItem.id}.`;
      } else {
        let previousSibling: FlattenedItem | undefined = previousItem;

        while (previousSibling && projected.depth < previousSibling.depth) {
          const parentId: UniqueIdentifier | null = previousSibling.parentId;

          previousSibling = sortedItems.find(({ id }) => id === parentId);
        }

        if (previousSibling) {
          announcement = `${aId} was ${movedVerb} after ${previousSibling.id}.`;
        }
      }

      // eslint-disable-next-line consistent-return
      return announcement;
    }

    // eslint-disable-next-line consistent-return
    return '';
  }

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id);
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id);
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  function handleCollapse(id: UniqueIdentifier): void {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    return setItems((items) =>
      setProperty(items, id, 'collapsed', (value) => {
        return !value;
      }),
    );
  }

  function handleDragEnd({ active, over }: DragEndEvent): void {
    resetState();
    setDragMoveStarted('');

    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));

      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const overDrawOrder = clonedItems.at(overIndex)?.drawOrder;

      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      const oldParentId = clonedItems.find(({ id }) => id === active.id)?.parentId;

      if (playerRef && playerRef.current) {
        playerRef.current.pause();
      }

      /**
       * The parent has changed, therefor we stop the drop proccess
       */
      if (parentId !== oldParentId) return;

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const idx = sortedItems.findIndex((element) => element.id === active.id);

      changeDrawOrderWithChildren({
        toolkit,
        layerId: active.id.toString(),
        drawOrder: overDrawOrder ? overDrawOrder : idx,
      });
    }
  }

  function handleDragCancel(): void {
    resetState();
  }

  function handleDragStart({ active: { id: aId } }: DragStartEvent): void {
    setActiveId(aId);
    setOverId(aId);

    const newActiveItem = flattenedItems.find(({ id }) => id === aId);

    if (newActiveItem) {
      setCurrentPosition({
        parentId: newActiveItem.parentId,
        overId: aId,
      });
    }

    document.body.style.setProperty('cursor', 'grabbing');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleDragMove({ active, delta }: DragMoveEvent): void {
    setDragMoveStarted(active.id.toString());
    const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));

    const oldParentId = clonedItems.find(({ id }) => id === active.id)?.parentId;
    const { parentId } = projected;

    if (oldParentId === parentId) {
      document.body.style.cursor = 'grabbing';
    } else {
      document.body.style.cursor = 'not-allowed';
    }

    /**
     * Deactivated to stop nesting and nesting UI
     */
    // setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent): void {
    setOverId(over?.id ?? null);
  }

  const adjustTranslate: Modifier = ({ transform }) => {
    return {
      ...transform,
    };
  };

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        <ul style={{ margin: 0, padding: 0, listStyle: 'none', paddingTop: '24px', userSelect: 'none' }}>
          {flattenedItems.map(({ children, collapsed, depth, id, parentId }: FlattenedItem) => (
            <SortableTreeItem
              key={`${id}_${parentId}`}
              id={id}
              value={id}
              selected={id === selectedLayerId || parentId === selectedLayerId}
              highlighted={id === highlightedLayerId}
              depth={id === activeId && projected ? projected['depth'] : depth}
              indentationWidth={indentationWidth}
              indicator={indicator}
              collapsed={Boolean(collapsed && children.length)}
              // eslint-disable-next-line no-undefined
              onCollapse={collapsible && children.length ? (): boolean => handleCollapse(id) : undefined}
              ghost={dragMoveStarted}
            />
          ))}
        </ul>
        {createPortal(
          <DragOverlay dropAnimation={dropAnimationConfig} modifiers={indicator ? [adjustTranslate] : []}>
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                depth={activeItem.depth}
                clone
                childCount={getChildCount(items, activeId) + 1}
                value={activeId.toString()}
                indentationWidth={indentationWidth}
              />
            ) : null}
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  );
}
