/**
 * Copyright 2022 Design Barn Inc.
 */

import { IconButton } from '@lottiefiles/ds-core';
import React from 'react';

import { styled } from '../../config/stitches';

import { PlayerSeeker } from './lottie-player-seeker';
import { PlayerSeekerStatus } from './lottie-player-seeker-status';
import { MemoizedLottiePlayerSpeedButton } from './lottie-player-speed-button';

interface LottiePlayerControlsProps {
  currSpeed: number;
  currentFrame: number;
  isLooping?: boolean;
  isPlaying?: boolean;
  loopBtnOff?: boolean;
  onSeekChange: (e: number) => void;
  speedBtnOff?: boolean;
  style?: {
    [key: string]: string | number;
  };
  toggleLooping: () => void;
  togglePlayback: () => void;
  toggleSpeed: () => void;
  totalFrames: number;
}

const StyledButtonContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  margin: '0 auto',
});

const StyledSeekerAndFramesContainer = styled('div', {
  display: 'block',
  width: '100%',
  marginLeft: '8px',
});

const StyledSeekerContainer = styled('div', {
  marginTop: '33px',
});

const StyledFramesContainer = styled('div', {
  marginTop: '10px',
});

export const LottiePlayerControls: React.FunctionComponent<LottiePlayerControlsProps> = ({
  currSpeed,
  currentFrame,
  isLooping = false,
  isPlaying = false,
  loopBtnOff = false,
  onSeekChange,
  speedBtnOff = false,
  style,
  toggleLooping,
  togglePlayback,
  toggleSpeed,
  totalFrames,
}: LottiePlayerControlsProps) => (
  <>
    <StyledButtonContainer style={style}>
      <IconButton name={isPlaying ? 'pause' : 'play'} onClick={togglePlayback} />
      <StyledSeekerAndFramesContainer>
        <StyledSeekerContainer>
          <PlayerSeeker max={totalFrames} value={currentFrame} onChange={onSeekChange} />
        </StyledSeekerContainer>

        <StyledFramesContainer>
          <PlayerSeekerStatus currentFrame={currentFrame} totalFrames={totalFrames} />
        </StyledFramesContainer>
      </StyledSeekerAndFramesContainer>

      {!loopBtnOff && (
        <IconButton
          name={isLooping ? 'loop-on' : 'loop-off'}
          onClick={toggleLooping}
          buttonStyle={{ marginLeft: '8px' }}
        />
      )}

      {!speedBtnOff && <MemoizedLottiePlayerSpeedButton toggleSpeed={toggleSpeed} currSpeed={currSpeed} />}
    </StyledButtonContainer>{' '}
  </>
);
