/**
 * Copyright 2022 Design Barn Inc.
 */

import { useSearchParams } from 'react-router-dom';

import { DEFAULT_ANIMATION_URL, QUERY_PARAMETER_FILE_URL } from '../utils/constants';

export const useAnimationURL = (): string => {
  const [searchParams] = useSearchParams();

  return searchParams.get(QUERY_PARAMETER_FILE_URL) || DEFAULT_ANIMATION_URL;
};
