/**
 * Copyright 2022 Design Barn Inc.
 */

import { Button } from '@lottiefiles/ds-core';
import axios from 'axios';
import { colord } from 'colord';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { envConfig } from '../../../config/env';
import { useKeypress } from '../../../hooks';
import { backgroundColorValueAtom } from '../../../state';
import { useScene, useToolkit } from '../../../toolkit';
import { lottiePlugin } from '../../../toolkit/plugins';
import {
  ACCEPTED_ORIGINS,
  QUERY_PARAMETER_FILE_URL,
  QUERY_PARAMETER_ORIGIN,
  QUERY_PARAMETER_RETURN_HASH,
  QUERY_PARAMETER_RETURN_URL,
} from '../../../utils';

export const SaveButton = (): JSX.Element => {
  const toolkit = useToolkit();
  const scene = useScene();
  const [searchParams] = useSearchParams();
  const [animationBackgroundValue] = useRecoilState(backgroundColorValueAtom);
  const form = useRef<any>(null);

  const animationBgColor = colord(animationBackgroundValue);

  // HANDBACK PROPS
  const bgColor =
    animationBgColor.isValid() && animationBgColor.toHex().substring(1) !== '2B2E30'
      ? animationBgColor.toHex().substring(1)
      : '';
  const src = searchParams.get(QUERY_PARAMETER_RETURN_URL) as string;
  const hash = searchParams.get(QUERY_PARAMETER_RETURN_HASH) as string;
  const origin = searchParams.get(QUERY_PARAMETER_ORIGIN) as string;
  const fileUrl = searchParams.get(QUERY_PARAMETER_FILE_URL) as string;

  /**
   *  Export Lottie from Toolkit
   */
  const exportLottie = async (): Promise<any> => {
    const sceneName = scene?.name ? `${scene.name}.json` : `lottie-animation.json`;

    const result = await toolkit.export(lottiePlugin.id, {
      scene,
      exportNodeIds: false,
      filePath: sceneName,
    });

    return result;
  };

  /**
   * Generates presigned post data for uploading to S3
   */
  const createUploadRqeuest = async (): Promise<any> => {
    if (!ACCEPTED_ORIGINS.includes(origin)) {
      throw new Error('Invalid origin');
    }

    const result = await axios.get(`${envConfig.uploadRequestStagingUrl}`);

    return result.data;
  };

  /**
   * Upload File to S3
   */
  const upload = async (fields: any, lottie: any, url: string): Promise<any> => {
    const formData = new FormData();

    formData.append('Content-Type', 'application/json');

    Object.entries(fields).forEach(([fieldKey, fieldValue]: [any, any]) => {
      formData.append(fieldKey, fieldValue);
    });

    const json = JSON.stringify(lottie);

    const file = new Blob([json], {
      type: 'application/json',
    });

    formData.append('file', file);

    const result = await axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(result.data, 'text/xml') as any;

    return xmlDoc.getElementsByTagName('Location')[0].childNodes[0].nodeValue;
  };

  /**
   * Handle Save button click
   */
  const handleSave = async (): Promise<void> => {
    const lottieJson = await exportLottie();

    try {
      // Create upload request
      const { fields, url } = await createUploadRqeuest();

      //  Upload File
      const response = await upload(fields, lottieJson, url);

      //  Update asset_url input field
      const assetUrlInputField = form.current?.elements.asset_url;

      if (assetUrlInputField) {
        assetUrlInputField.value = response;
      }

      // Submit form
      form.current?.submit();
    } catch (error) {
      throw new Error(`Error saving file: ${String(error)}`);
    }
  };

  useKeypress('mod+s', handleSave);

  /**
   * Check if origin exists and its included in the whitelisted origins
   */
  const isValidOrigin = (): boolean => {
    if (origin && ACCEPTED_ORIGINS.includes(origin)) {
      return true;
    }

    return false;
  };

  /**
   * Check if valid params are passed
   */
  const isValidParams = (): boolean => {
    switch (origin) {
      case 'web':
        if (src) {
          return true;
        }
        break;

      case 'iconscout':
        if (src && hash) {
          return true;
        }
        break;

      case 'workflow':
        if (src && hash) {
          return true;
        }
        break;

      default:
    }

    return false;
  };

  /**
   * Check if file can be saved
   */
  const isHandback = (): boolean => {
    if (origin === 'iconscout' || !fileUrl) {
      return false;
    }

    return true;
  };

  return (
    <>
      {isHandback() && (
        <>
          <Button
            disabled={!isValidOrigin() || !isValidParams()}
            size="md"
            buttonStyle={{ flexGrow: 1, justifyContent: 'center' }}
            label="Save"
            onClick={(): any => handleSave()}
          />
          {isValidOrigin() && isValidParams() && (
            <form hidden method="POST" action={src} ref={form} encType="multipart/form-data">
              <input type="hidden" name="origin" value={origin} />
              <input type="hidden" name="hash" value={hash} />
              <input type="hidden" name="asset_url" />
              <input type="hidden" name="bg_color" value={bgColor} />
            </form>
          )}
        </>
      )}
    </>
  );
};
