/**
 * Copyright 2022 Design Barn Inc.
 */

import { authExchange } from '@urql/exchange-auth';
import React from 'react';
import type { CombinedError } from 'urql';
import { createClient, errorExchange, dedupExchange, cacheExchange, fetchExchange, Provider } from 'urql';

import { envConfig } from '../config/env';

interface Props {
  children: React.ReactNode;
}

if (!envConfig.graphqlGatewayUrl) {
  throw new Error('GRAPHQL_GRATEWAY_URL is missing');
}

interface Identity {
  id: string;
  traits: {
    email: string;
    name: {
      first: string;
      last?: string;
    };
  };
}

interface UserSession {
  active?: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  authenticated_at?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  expires_at?: string;
  id: string;
  identity: Identity;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  issued_at?: string;

  token: string;
}

async function fetchUserToken(): Promise<string | null> {
  try {
    const res = await fetch(`${envConfig.authServerUrl}/deimos/v1/session`, {
      method: 'POST',
      credentials: 'include',
    });

    const { token } = (await res.json()) as UserSession;

    return token;
  } catch (err) {
    return null;
  }
}

const client = createClient({
  url: envConfig.graphqlGatewayUrl,
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange<{ token: string | null }>({
      addAuthToOperation: ({ authState, operation }) => {
        // the token isn't in the auth state, return the operation without changes
        if (!authState || !authState.token) {
          return operation;
        }

        // fetchOptions can be a function (See Client API) but you can simplify this based on usage
        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

        return {
          ...operation,
          context: {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                'client-name': '@lottiefiles/editor-web',
                'client-version': envConfig.appVersion as string,
                authorization: `Bearer ${authState.token}`,
              },
            },
          },
        };
      },
      willAuthError: () => {
        // This will always be false because we are calling some endpoints that don't require auth
        return false;
      },
      didAuthError: ({ error }) => {
        return error.graphQLErrors.some((err) => err.extensions['code'] === 'unauthorized');
      },
      getAuth: async ({ authState }) => {
        // This will fetch token at the first time or refreshes the token
        const token = await fetchUserToken();

        if (!authState || token) {
          return {
            token,
          };
        }

        return null;
      },
    }),
    errorExchange({
      onError: (error: CombinedError) => {
        const isUnauthorizedError = error.graphQLErrors.some((err) =>
          err.message.toLowerCase().includes('unauthorized'),
        );

        if (isUnauthorizedError) {
          window.location.href = `${envConfig.authServerUrl}/login?return_to=${encodeURIComponent(
            window.location.href,
          )}`;
        }
      },
    }),
    fetchExchange,
  ],
});

export const UrqlProvider: React.FC<Props> = ({ children }) => {
  return <Provider value={client}>{children}</Provider>;
};
