/**
 * Copyright 2022 Design Barn Inc.
 */

export const getExtension = (url: string): string => {
  // Remove everything to the last slash in URL
  let extension = url.substring(1 + url.lastIndexOf('/'));

  // Break URL at ? and take first part (file name, extension)
  extension = extension.split('?')[0] as string;

  // Sometimes URL doesn't have ? but #, so we should aslo do the same for #
  extension = extension.split('#')[0] as string;

  // Break file name at . and take last part (extension)
  extension = extension.split('.').pop() as string;

  return extension;
};
