/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';
import { PrecompositionLayer } from '@lottiefiles/toolkit-js';

type Depth = number;

const DEFAULT_MAX_DEPTH = 10;

interface Node {
  depth: Depth;
  layer: Layer;
  parent: Layer | null;
}

export const visitAllLayers = (
  rootLayer: Layer,
  callback: (node: Node) => void,
  maxDepth: Depth = DEFAULT_MAX_DEPTH,
): void => {
  if (maxDepth <= 0 || !rootLayer.isEnabled) {
    return;
  }

  const queue: Node[] = [
    {
      layer: rootLayer,
      parent: null,
      depth: 0,
    },
  ];

  while (queue.length > 0) {
    const { depth, layer, parent } = queue.shift() as Node;

    if (depth >= maxDepth) {
      continue;
    }

    // eslint-disable-next-line node/callback-return, node/no-callback-literal
    callback({ layer, parent, depth });

    if (layer instanceof PrecompositionLayer) {
      layer.asset?.allLayers.forEach((nestedLayer) => {
        if (!nestedLayer.isEnabled) return;

        queue.push({
          layer: nestedLayer,
          parent: layer,
          depth: depth + 1,
        });
      });
    }
  }
};
