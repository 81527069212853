/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';
import { AVLayer, Shape } from '@lottiefiles/toolkit-js';
import React from 'react';
import { useAsyncFn } from 'react-use';

import { lottiePlugin } from '../plugins';
import { useToolkit } from '../provider';

export const useExportLayerToLottie = (layer: Layer | null): object => {
  const toolkit = useToolkit();
  const [state, runAsync] = useAsyncFn(
    async ({ scene }) => {
      if (!scene) return Promise.resolve();

      return toolkit.export(lottiePlugin.id, {
        scene,
      });
    },
    [toolkit],
  );

  React.useEffect(() => {
    if (!layer || !toolkit.nodeRegistry.has(layer.nodeId)) return;

    if (layer instanceof AVLayer || layer instanceof Shape) {
      const scene = toolkit.extract(layer);

      runAsync({ scene });
    }
  }, [layer?.state, runAsync, toolkit]);

  return state.value as object;
};
