/**
 * Copyright 2022 Design Barn Inc.
 */

import { FlexItem, Tabs } from '@lottiefiles/ds-core';
import React from 'react';

import { styled } from '../../../config/stitches';
import { useScene } from '../../../toolkit';
import { PANEL_WIDTH, TOP_BAR_HEIGHT } from '../../../utils';
import { AnimationSettings } from '../../animation-settings';
import { LayerSettings } from '../../layer-settings';

const Panel = styled(FlexItem, {
  width: PANEL_WIDTH,
  height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
  backgroundColor: '$white',
  position: 'relative',
  overflow: 'scroll',
});

const StyledRightPanel = styled(Panel, {
  borderLeft: '1px solid $gray50',
});

export const RightPanel: React.FC = React.memo((): React.ReactElement => {
  const scene = useScene();

  const [tab, setTab] = React.useState<string>('edit');

  return (
    <StyledRightPanel customStyles={{}}>
      <Tabs
        ariaLabel="RightPanel Tabs"
        value={tab}
        onValueChange={(value): void => setTab(value)}
        className="rightpanel-tabs"
        tabs={[
          {
            disabled: false,
            content: <LayerSettings />,
            label: 'Edit',
            tabId: 'edit',
          },
          { disabled: false, content: scene && <AnimationSettings />, label: 'Settings', tabId: 'settings' },
        ]}
      />
    </StyledRightPanel>
  );
});
