/**
 * Copyright 2022 Design Barn Inc.
 */

import { colord } from 'colord';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { backgroundColorValueAtom } from '../state';
import { getURLParam, QUERY_PARAMETER_BACKGROUND_COLOR } from '../utils';

export const useBackgroundColor = (): void => {
  const [, setAnimationBackgroundValue] = useRecoilState(backgroundColorValueAtom);

  const backgroundColor = getURLParam(QUERY_PARAMETER_BACKGROUND_COLOR);

  useEffect(() => {
    if (backgroundColor) {
      const color = colord(`#${backgroundColor}`);

      if (color.isValid()) {
        setAnimationBackgroundValue(color.rgba);
      }
    }
  }, []);
};
