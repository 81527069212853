/**
 * Copyright 2022 Design Barn Inc.
 */

import { Toolkit } from '@lottiefiles/toolkit-js';
import React from 'react';

import { ToolkitHistoryProvider } from './history';
import { dotLottiePlugin, lottiePlugin } from './plugins';
import { ToolkitStoreProvider } from './store';

const ToolkitContext = React.createContext<Toolkit | null>(null);

ToolkitContext.displayName = 'ToolkitContext';

export function useToolkit(): Toolkit {
  const toolkit = React.useContext(ToolkitContext);

  if (!toolkit) throw new Error('useToolkit must be used within ToolkitProvider');

  return toolkit;
}

export function ToolkitProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const [toolkit] = React.useState(() => {
    const toolkitInstance = new Toolkit({
      enableNodeIds: true,
      immutableState: true,
      hydratePrecompositions: false,
      useFlatHierarchy: true,
    });

    toolkitInstance.initializeState();

    toolkitInstance.addPlugin(lottiePlugin);
    toolkitInstance.addPlugin(dotLottiePlugin);

    return toolkitInstance;
  });

  return (
    <ToolkitContext.Provider value={toolkit}>
      <ToolkitStoreProvider toolkit={toolkit}>
        <ToolkitHistoryProvider toolkit={toolkit}>{children}</ToolkitHistoryProvider>
      </ToolkitStoreProvider>
    </ToolkitContext.Provider>
  );
}
