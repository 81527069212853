/**
 * Copyright 2022 Design Barn Inc.
 */

import { Button, Text, Icon } from '@lottiefiles/ds-core';
import type { Color } from '@lottiefiles/toolkit-js';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@radix-ui/react-accordion';
import React from 'react';

import { styled } from '../../config/stitches';
import type { getColors } from '../../toolkit';
import { useToolkit, updateColor, updateSVGColor } from '../../toolkit';
import { UniqueColorGrid, AllColorGrid } from '../ui';

const StyledShowAllContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: '$space4',
});

const StyledColorGridContainer = styled('div', {
  overflow: 'hidden',
  variants: {
    showAll: {
      true: {
        maxHeight: '100%',
      },
      false: {
        maxHeight: 90,
      },
    },
  },
});

const StyledAccordionTrigger = styled(AccordionTrigger, {
  backgroundColor: 'transparent',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: 'none',
  width: '100%',
  paddingTop: '$space5',
  paddingBottom: '$space4',
  paddingLeft: '0',
  paddingRight: '0',
  cursor: 'pointer',
  '> svg': {
    transition: 'all 300ms',
  },
  '&[data-state=open] > svg': {
    transform: 'rotate(180deg)',
  },
});

interface UniqueColorsProps {
  colors: ReturnType<typeof getColors>['uniqueColors'];
}

interface ColorGridContainerProps {
  children: React.ReactNode;
  colorsCount: number;
}
const ColorGridContainer: React.FC<ColorGridContainerProps> = ({ children, colorsCount }) => {
  const [showAllColors, setShowAllColors] = React.useState(false);

  const showAll = (): void => {
    setShowAllColors(true);
  };

  return (
    <>
      <StyledColorGridContainer showAll={showAllColors}>{children}</StyledColorGridContainer>
      {colorsCount > 24 && !showAllColors && (
        <StyledShowAllContainer>
          <Button
            icon={{
              name: 'ellipsis-vertical',
              position: 'left',
            }}
            disabled={colorsCount === 24}
            variant="ghost"
            label={` See all ${colorsCount} colors`}
            onClick={showAll}
            buttonStyle={{
              fontWeight: '$fontBold',
              color: '$textIconPrimary',
              paddingRight: 8,
              paddingLeft: 2,
              paddingTop: 2,
              paddingBottom: 2,
            }}
            size="xs"
          />
        </StyledShowAllContainer>
      )}
    </>
  );
};

const UniqueColors: React.FC<UniqueColorsProps> = ({ colors }) => {
  const toolkit = useToolkit();

  const onUniqueColorChange = (colorHex: string, newColor: Color): void => {
    const colorEntries = colors[colorHex];

    if (colorEntries) {
      colorEntries.forEach(({ color, keyframe, nodeId }) => {
        updateSVGColor({
          target: toolkit.getNodeById(nodeId),
          keyframe,
          targetColor: color,
          newColor,
        });
      });
    }
  };

  const onUniqueColorChangeEnd = (colorKey: string, newColor: Color): void => {
    const colorEntries = colors[colorKey];

    if (colorEntries) {
      toolkit.batch(() => {
        colorEntries.forEach(({ color, keyframe, nodeId }) => {
          updateColor({
            target: toolkit.getNodeById(nodeId),
            keyframe,
            targetColor: color,
            newColor,
          });
        });
      });
    }
  };

  return Object.keys(colors).length > 0 ? (
    <ColorGridContainer colorsCount={Object.keys(colors).length}>
      <UniqueColorGrid colors={colors} onChangeEnd={onUniqueColorChangeEnd} onChange={onUniqueColorChange} />
    </ColorGridContainer>
  ) : null;
};

interface AllColorsProps {
  colors: ReturnType<typeof getColors>['allColors'];
}

const AllColors: React.FC<AllColorsProps> = ({ colors }) => {
  const toolkit = useToolkit();

  const onColorChange = (colorIndex: number, newColor: Color): void => {
    const colorEntry = colors[colorIndex];

    if (colorEntry) {
      const { color, keyframe, nodeId } = colorEntry;

      updateSVGColor({
        target: toolkit.getNodeById(nodeId),
        keyframe,
        targetColor: color,
        newColor,
      });
    }
  };

  const onColorChangeEnd = (colorIndex: number, newColor: Color): void => {
    const colorEntry = colors[colorIndex];

    if (colorEntry) {
      const { color, keyframe, nodeId } = colorEntry;

      updateColor({
        target: toolkit.getNodeById(nodeId),
        keyframe,
        targetColor: color,
        newColor,
      });
    }
  };

  return colors.length > 0 ? (
    <ColorGridContainer colorsCount={colors.length}>
      <AllColorGrid colors={colors} onChangeEnd={onColorChangeEnd} onChange={onColorChange} />
    </ColorGridContainer>
  ) : null;
};

interface ColorSettingsProps {
  colors: ReturnType<typeof getColors>;
}

export const ColorSettings: React.FC<ColorSettingsProps> = (props) => {
  const { colors } = props;

  return (
    <>
      <Accordion type="multiple" defaultValue={['item-1', 'item-2']}>
        <AccordionItem value="item-1" style={{ borderBottom: '1px solid #F3F6F8' }}>
          <StyledAccordionTrigger>
            <Text plugins={false} variant="body4" customTag="div" textStyle={{ fontWeight: 700, color: '$gray800' }}>
              Unique colors
            </Text>
            <Icon name="chevron-outline-down" size="sm" color="$gray300" />
          </StyledAccordionTrigger>

          <AccordionContent style={{ paddingBottom: '16px' }}>
            <UniqueColors colors={colors.uniqueColors} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2" style={{ borderBottom: '1px solid #F3F6F8' }}>
          <StyledAccordionTrigger>
            <Text plugins={false} variant="body4" customTag="div" textStyle={{ fontWeight: 700, color: '$gray800' }}>
              All colors
            </Text>
            <Icon name="chevron-outline-down" size="sm" color="$gray300" />
          </StyledAccordionTrigger>

          <AccordionContent style={{ paddingBottom: '16px' }}>
            <AllColors colors={colors.allColors} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
};
