/**
 * Copyright 2022 Design Barn Inc.
 */

import { Text } from '@lottiefiles/ds-core';
import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';

import loaderLottie from '../../../assets/lottie/loader.lottie.json';
import { styled } from '../../../config/stitches';

const StyledLoaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
});

interface Props {
  size?: 'sm' | 'lg';
}

export const PageLoader: React.FC<Props> = ({ size = 'sm' }) => {
  const style = { width: 0 };

  switch (size) {
    case 'lg':
      style.width = 126;
      break;

    case 'sm':
      style.width = 72;
      break;

    default:
      throw new Error('Invalid size provided');
  }

  return (
    <StyledLoaderContainer>
      <Player src={loaderLottie} autoplay loop style={style} />
      <Text plugins={false} variant="body4" textStyle={{ fontFamily: `$karla`, color: `$textIconSecondary` }}>
        Loading Editor...
      </Text>
    </StyledLoaderContainer>
  );
};
