/**
 * Copyright 2022 Design Barn Inc.
 */

import type { UniqueIdentifier } from '@dnd-kit/core';
import type { AnimateLayoutChanges } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { CSSProperties } from 'react';
import React from 'react';

import { iOS } from '../../utilities';

import type { TreeItemProps } from './tree-item';
import { TreeItem } from './tree-item';

interface Props extends TreeItemProps {
  ghost: string;
  id: UniqueIdentifier;
  selected?: boolean;
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) => !(isSorting || wasDragging);

export function SortableTreeItem({ depth, id, ...props }: Props): JSX.Element {
  const { attributes, isSorting, listeners, setDraggableNodeRef, setDroppableNodeRef, transform, transition } =
    useSortable({
      id,
      animateLayoutChanges,
    });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      depth={depth}
      indicator={false}
      disableSelection={iOS}
      disableInteraction={isSorting}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      {...props}
    />
  );
}
