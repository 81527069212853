/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { useRecoilState } from 'recoil';

import { selectedLayerIdAtom } from '../../state';
import { getLayerDOMElement, setIsHidden, useToolkit, useToolkitNode, removeLayer } from '../../toolkit';
import { SelectionMenu } from '../ui';

export const SelectedLayerBoundingBox = ({ style = {} }: { style?: React.CSSProperties }): React.ReactElement => {
  const [selectionMenuRoot] = React.useState(() => document.getElementById('layer-selection-menu-root'));
  const [selectedLayerId, setSelectedLayerId] = useRecoilState(selectedLayerIdAtom);
  const toolkit = useToolkit();
  const selectedLayer = useToolkitNode(selectedLayerId) as Layer | null;

  const isHidden = Boolean(selectedLayer?.state.properties.hd);

  const selectedLayerElement = selectedLayer ? getLayerDOMElement(selectedLayer) : null;
  const selectedLayerElementClientRect = selectedLayerElement?.getBoundingClientRect();

  const deleteLayer = React.useCallback(() => {
    if (selectedLayer) {
      removeLayer(selectedLayer);
    }
    setSelectedLayerId('');
  }, [selectedLayer, setSelectedLayerId]);

  const changeVisibility = React.useCallback(() => {
    if (selectedLayer) {
      setIsHidden({ layer: selectedLayer, isHidden: !isHidden, toolkit });
    }
  }, [selectedLayer, isHidden, toolkit, setSelectedLayerId, toolkit]);

  return selectionMenuRoot && selectedLayerElement && !isHidden ? (
    ReactDOM.createPortal(
      <div
        style={{
          position: 'absolute',
          top: `${Number(selectedLayerElementClientRect?.top) - 4}px`,
          left: `${
            Number(selectedLayerElementClientRect?.left) + Number(selectedLayerElementClientRect?.width) + 16
          }px`,
          ...style,
        }}
      >
        <SelectionMenu onDelete={deleteLayer} onVisibilityChange={changeVisibility} visible={!isHidden} />
      </div>,
      selectionMenuRoot,
    )
  ) : (
    <></>
  );
};
