/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Scene } from '@lottiefiles/toolkit-js';

export interface IAnimationSettings {
  duration: number;
  frameRate: number;
  height: number;
  width: number;
}

/**
 * Get animation settings from toolkit scene
 * @param scene - Scene
 * @returns IAnimationSettings
 */
export const animationSettings = (scene: Scene): IAnimationSettings => {
  const { height, width } = scene.size;

  return {
    width,
    height,
    frameRate: Number(scene.timeline.frameRate),
    duration: Number(scene.timeline.duration),
  };
};
