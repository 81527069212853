/**
 * Copyright 2022 Design Barn Inc.
 */

import type { DagNode } from '@lottiefiles/toolkit-js';
import React from 'react';
import { useUpdate } from 'react-use';

import { useToolkit } from '../provider';

export function useToolkitNode(id: string, events: string[] = ['*']): DagNode | null {
  const toolkit = useToolkit();
  const rerender = useUpdate();

  const node = toolkit.getNodeById(id);

  const eventsRef = React.useRef(events);

  eventsRef.current = events;

  React.useEffect(() => {
    eventsRef.current.forEach((event) => {
      node?.addEventListener(event, rerender);
    });

    return (): void => {
      eventsRef.current.forEach((event) => {
        node?.removeEventListener(event, rerender);
      });
    };
  }, [node, rerender]);

  return node;
}
