/**
 * Copyright 2022 Design Barn Inc.
 */

import { IconButton } from '@lottiefiles/ds-core';
import React from 'react';

import { styled } from '../../../config/stitches';

interface SelectionMenuProps {
  onDelete: (evt?: React.MouseEvent<HTMLButtonElement>) => void;
  onVisibilityChange: (evt?: React.MouseEvent<HTMLButtonElement>) => void;
  visible: boolean;
}

const StyledSelectionMenuContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '$shadowPopover',
  borderRadius: '$roundedSm',
  background: '$backgroundDefault',
  border: '1px solid $gray50',
});

const StyledSeparator = styled('span', {
  width: '$iconSizeLg',
  height: '$size1',
  background: '$gray50',
});

export function SelectionMenu({ onDelete, onVisibilityChange }: SelectionMenuProps): React.ReactElement {
  return (
    <StyledSelectionMenuContainer>
      <IconButton
        name="eye-open-outline"
        size="md"
        iconStyle={{ color: '$gray500' }}
        buttonStyle={{
          border: '0px',
          background: '$backgroundDefault',
          borderRadius: '$roundedSm $roundedSm 0 0',
          '&:hover': {
            '> svg': {
              color: '$textIconPrimary',
            },
          },
        }}
        onClick={onVisibilityChange}
      />
      <StyledSeparator></StyledSeparator>
      <IconButton
        className="deleteIconButton"
        name="delete"
        onClick={onDelete}
        iconStyle={{
          color: '$gray500',
        }}
        size="md"
        buttonStyle={{
          border: '0px',
          background: '$backgroundDefault',
          borderRadius: '0 0 $roundedSm $roundedSm',
          '&:hover': {
            '> svg': {
              color: '$actionCriticalHover',
            },
          },
        }}
      />
    </StyledSelectionMenuContainer>
  );
}
