/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Player as ReactPlayer, PlayerEvent } from '@lottiefiles/react-lottie-player';
import { Player } from '@lottiefiles/react-lottie-player';
import type { AnimationItem } from 'lottie-web';
import { useCallback, useEffect, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { usePrevious } from 'react-use';
import { useRecoilState } from 'recoil';

import { useWindowResize, useKeypress } from '../../hooks';
import {
  animationZoomStateAtom,
  boundingBoxAtom,
  highlightedLayerIdAtom,
  selectedLayerIdAtom,
  wiredModeAtom,
} from '../../state';
import { getLayersDOMElements, useScene } from '../../toolkit';
import { BASE_PLAYER_WIDTH, getLottieWidth } from '../../utils';

import { LottiePlayerControls } from './lottie-player-controls';
import { SelectedLayerBoundingBox } from './selected-layer-bounding-box';

export declare type PlayerDirection = -1 | 1;

interface LottiePlayerProps {
  animationData: any;
  playerRef: React.RefObject<ReactPlayer>;
}

export const LottiePlayer: React.FC<LottiePlayerProps> = ({ animationData, playerRef }) => {
  const { width } = useWindowResize();

  /**
   * Lottie Animation States
   */
  const [scale] = useRecoilState(animationZoomStateAtom);
  const [wired] = useRecoilState(wiredModeAtom);
  const [boundingBox] = useRecoilState(boundingBoxAtom);
  const [selectedLayerId, setSelectedLayerId] = useRecoilState(selectedLayerIdAtom);
  const [, setHighlightedLayerId] = useRecoilState(highlightedLayerIdAtom);

  /**
   * Lottie Instance and Player Ref
   */
  const [lottieInstance, setLottieInstance] = useState<AnimationItem | null>(null);

  /**
   * Player Controls States
   */
  const [currentFrame, setCurrentFrame] = useState(0);
  const [totalFrames, setTotalFrames] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const isPrevPlaying = usePrevious(isPlaying);
  const [isLooping, setIsLooping] = useState(true);
  const [currSpeed, setCurrSpeed] = useState(1);

  const scene = useScene();

  /**
   * PlayerEvent
   */
  const onEvent = useCallback(
    (playerEvent: PlayerEvent): void => {
      if (playerEvent === 'load' && scene) {
        if (lottieInstance) setTotalFrames(lottieInstance.totalFrames);
        getLayersDOMElements(scene.allLayers).forEach((el) => {
          el.addEventListener('click', (event) => {
            event.stopPropagation();

            setSelectedLayerId(el.id);
          });
          el.addEventListener('mouseenter', (event) => {
            event.stopPropagation();

            setHighlightedLayerId(el.id);
          });
          el.addEventListener('mouseleave', (event) => {
            event.stopPropagation();

            setHighlightedLayerId('');
          });
        });

        if (isPrevPlaying && playerRef.current) {
          playerRef.current.play();
          setIsPlaying(true);
        }
      }
      if (playerEvent === 'complete' && scene) {
        if (!isLooping) setIsPlaying(false);
      }
      if (playerEvent === 'frame' && scene) {
        if (lottieInstance) setCurrentFrame(Math.round(lottieInstance.currentFrame));
      }
    },
    [scene?.state, setSelectedLayerId, setHighlightedLayerId, isLooping, lottieInstance],
  );

  const setLottiePlayerInstance = useCallback((instance: AnimationItem): void => {
    setLottieInstance(instance);
  }, []);

  /**
   * Player Methods
   */
  const play = useCallback(() => {
    if (!playerRef.current) return;

    playerRef.current.play();
    setIsPlaying(true);
  }, [setIsPlaying]);

  const pause = useCallback(() => {
    if (!playerRef.current) return;

    playerRef.current.pause();
    setIsPlaying(false);
  }, [setIsPlaying]);

  const togglePlayback = useCallback(() => {
    if (isPlaying) pause();
    else play();
  }, [isPlaying, pause, play]);

  const toggleLooping = useCallback(() => {
    if (!lottieInstance) return;

    lottieInstance.loop = !isLooping;
    setIsLooping(!isLooping);
  }, [isLooping, lottieInstance]);

  const toggleSpeed = useCallback(() => {
    if (!playerRef.current) return;

    let newSpeed = currSpeed + 0.5;

    if (newSpeed > 2.5) newSpeed = 0.5;
    setCurrSpeed(newSpeed);
    playerRef.current.setPlayerSpeed(newSpeed);
  }, [currSpeed]);

  const onSeekChange = useCallback(
    (newFrame: number) => {
      if (lottieInstance) {
        if (isPlaying) {
          lottieInstance.goToAndPlay(newFrame, true);
        } else {
          lottieInstance.goToAndStop(newFrame, true);
        }
        setCurrentFrame(newFrame);
      }
    },
    [isPlaying, lottieInstance],
  );

  useEffect(() => {
    if (selectedLayerId.length) {
      pause();
    }
  }, [selectedLayerId]);

  /**
   *  Toggle Styles
   */
  const wiredClass = wired ? 'wired' : '';
  const boundingBoxClass = boundingBox ? 'bounding-box' : '';

  /**
   * Keyboard Shortcuts
   */
  useKeypress(' ', togglePlayback);

  return (
    <>
      <SelectedLayerBoundingBox />
      <ScrollContainer
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            width: `${getLottieWidth(width, scale)}px`,
            height: `auto`,
            maxWidth: '100%',
            minWidth: `${BASE_PLAYER_WIDTH / 2}%`,
            maxHeight: '100%',
          }}
        >
          <Player
            ref={playerRef}
            style={{
              width: '100%',
              height: '100%',
            }}
            lottieRef={setLottiePlayerInstance}
            className={`${wiredClass} ${boundingBoxClass}`}
            src={animationData as object}
            onEvent={onEvent}
            loop={isLooping}
            autoplay={false}
          />
        </div>
      </ScrollContainer>
      {
        <div
          style={{
            position: 'absolute',
            bottom: '24px',
            minWidth: '350px',
            maxWidth: '40rem',
            width: '100%',
            left: '50%',
            transform: 'translate3d(-50%, 0, 0)',
          }}
        >
          <LottiePlayerControls
            isPlaying={isPlaying}
            togglePlayback={togglePlayback}
            toggleLooping={toggleLooping}
            toggleSpeed={toggleSpeed}
            totalFrames={totalFrames}
            currentFrame={currentFrame}
            onSeekChange={onSeekChange}
            isLooping={isLooping}
            currSpeed={currSpeed}
            loopBtnOff={false}
            speedBtnOff={false}
          />
        </div>
      }
    </>
  );
};
