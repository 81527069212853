/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Toolkit } from '@lottiefiles/toolkit-js';
import React from 'react';

import { createToolkitStore } from './create-store';
import type { Store } from './create-store';

const ToolkitStoreContext = React.createContext<Store | null>(null);

export function ToolkitStoreProvider({
  children,
  toolkit,
}: {
  children: React.ReactNode;
  toolkit: Toolkit;
}): React.ReactElement {
  const [store] = React.useState(() => createToolkitStore(toolkit));

  return <ToolkitStoreContext.Provider value={store}>{children}</ToolkitStoreContext.Provider>;
}

export function useToolkitStore(): Store {
  const store = React.useContext(ToolkitStoreContext);

  if (!store) throw new Error('useStore must be used within StoreProvider');

  return store;
}
