/**
 * Copyright 2022 Design Barn Inc.
 */

import './styles.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import type { RouteObject } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AuthManager, LottieImporter, ToolkitSandbox, ErrorBoundary, AppFallback } from './components';
import { envConfig } from './config/env';
import App from './pages/app';
import { AuthProvider, UrqlProvider } from './providers';
import { AnimationTypeProvider } from './providers/animation-type-provider';
import { ToolkitProvider } from './toolkit';

const Main: React.FC = () => (
  <ErrorBoundary FallbackComponent={AppFallback}>
    <RecoilRoot>
      <ToolkitProvider>
        <UrqlProvider>
          <AuthProvider>
            <AuthManager>
              <AnimationTypeProvider>
                <LottieImporter>
                  <App />
                </LottieImporter>
              </AnimationTypeProvider>
            </AuthManager>
          </AuthProvider>
        </UrqlProvider>
      </ToolkitProvider>
    </RecoilRoot>
  </ErrorBoundary>
);

const routes = [
  {
    path: '*',
    element: <Main />,
    hasErrorBoundary: true,
  },
  envConfig.isDev || envConfig.isLocal
    ? {
        path: '/sandbox',
        element: <ToolkitSandbox />,
      }
    : null,
].filter(Boolean);

const router = createBrowserRouter(routes as RouteObject[]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<RouterProvider router={router} />);
