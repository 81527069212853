/**
 * Copyright 2022 Design Barn Inc.
 */

/**
 * Returns query params and returns the value
 * @param key - Key to be used for query search params
 * @returns query search param value
 */
export const getURLParam = (key: string): string | null => {
  const queryString = window.location.search;
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(key);
};
