/**
 * Copyright 2022 Design Barn Inc.
 */

import { Text } from '@lottiefiles/ds-core';

import { styled } from '../../config/stitches';

interface LayerContainerProps {
  children?: React.ReactNode;
  subTitle?: string;
  title: string;
}
const StyledLayerSettingsContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$space4',
  padding: '$space5 0',
});

export const LayerSettingsContainer = (props: LayerContainerProps): JSX.Element => {
  const { children, subTitle, title } = props;

  return (
    <StyledLayerSettingsContainer>
      <>
        {title && (
          <Text plugins={false} variant="body4" customTag="div" textStyle={{ fontWeight: 700, color: '$gray800' }}>
            {title}
          </Text>
        )}

        {subTitle && (
          <Text plugins={false} variant="caption1" customTag="span" textStyle={{ color: '$textIconPrimary' }}>
            {subTitle}
          </Text>
        )}
      </>
      {children}
    </StyledLayerSettingsContainer>
  );
};
