/**
 * Copyright 2021 Design Barn Inc.
 */

import { Text } from '@lottiefiles/ds-core';
import React from 'react';

import { styled } from '../../config/stitches';

interface PlayerSeekerStatusProps {
  currentFrame?: number;
  totalFrames?: number;
}

const InnerContainerStyle = styled('span', {
  padding: '4px',
  borderColor: '$borderDefault',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: '4px',
  margin: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'inline-flex',
  height: '10px',
  width: '50px',
});

const FrameTextLeft = styled(Text, {
  marginLeft: '4px',
  marginTop: '2px',
  marginBottom: '2px',
});

const FrameSeperator = styled('span', {
  marginLeft: '2px',
  marginRight: '2px',
  marginTop: '2px',
  marginBottom: '2px',
});

const FrameTextRight = styled(Text, {
  marginRight: '4px',
  marginTop: '2px',
  marginBottom: '2px',
});

export const PlayerSeekerStatus: React.FC<PlayerSeekerStatusProps> = ({
  currentFrame,
  totalFrames,
}: PlayerSeekerStatusProps) => {
  return (
    <div>
      <InnerContainerStyle>
        <span>
          <FrameTextLeft variant="caption1" plugins textStyle={{ color: '$textIconPrimary' }}>
            {currentFrame}
          </FrameTextLeft>
        </span>{' '}
        <FrameSeperator>
          <Text variant="caption1" plugins>
            /
          </Text>
        </FrameSeperator>{' '}
        <span>
          <FrameTextRight variant="caption1" plugins textStyle={{ color: '$textIconPlaceholder' }}>
            {totalFrames}
          </FrameTextRight>
        </span>{' '}
      </InnerContainerStyle>
    </div>
  );
};
