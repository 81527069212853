/**
 * Copyright 2022 Design Barn Inc.
 */

import { FlexItem, IconButton, TooltipRoot as Tooltip, TooltipTrigger, TooltipContent } from '@lottiefiles/ds-core';

import { styled } from '../../../config/stitches';
import { useKeypress } from '../../../hooks';
import { useToolkitHistory } from '../../../toolkit';

const StyledHistoryControls = styled(FlexItem, {
  display: 'inline-flex',
  gap: '$space5',
});

const StyledIconButton = styled(IconButton, {
  // marginRight: '$space5',
  variants: {
    disabled: {
      true: {
        background: 'transparent',
        color: '$textIconDisabled',
        '&:active': {
          background: 'transparent',
        },
        '&:hover': {
          background: 'transparent',
        },
        '&:focus': {
          background: 'transparent',
        },
      },
    },
  },
});

const ShortcutText = styled('span', {
  display: 'inline-block',
  marginRight: '$space3',
});

const ShortcutKey = styled('span', {
  display: 'inline-block',
  fontSize: '$fontSizeXs',
  marginRight: '$space2',
  marginLeft: '$space2',
  borderRadius: '$roundedBase',
  background: '$gray500',
  boxShadow:
    '0px 0px 1px rgba(61, 72, 83, 0.36), 0px 2px 6px rgba(61, 72, 83, 0.06), 0px 8px 48px rgba(243, 246, 248, 0.15);',
  variants: {
    size: {
      sm: {
        paddingRight: '$space1',
        paddingLeft: '$space1',
      },
      md: {
        paddingRight: '$space2',
        paddingLeft: '$space2',
      },
      lg: {
        paddingRight: '6px',
        paddingLeft: '6px',
      },
    },
  },
});

export const HistoryControls = (): JSX.Element => {
  const toolkitHistory = useToolkitHistory();

  /**
   * Handle Undo operations
   */
  const handleUndo = (): any => {
    toolkitHistory.undo();
  };

  /**
   * Handle Redo operations
   */
  const handleRedo = (): any => {
    toolkitHistory.redo();
  };

  useKeypress('mod+z', handleUndo);
  useKeypress('mod+shift+z', handleRedo);

  return (
    <FlexItem customStyles={{}}>
      <StyledHistoryControls customStyles={{}}>
        <Tooltip>
          <TooltipTrigger
            asChild
            style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <StyledIconButton name="undo" onClick={handleUndo} size="xs" disabled={!toolkitHistory.canUndo} />
          </TooltipTrigger>
          <TooltipContent align="center" side="bottom" contentStyle={{ fontSize: '12px' }}>
            <ShortcutText>Undo</ShortcutText>
            <ShortcutKey size="md">⌘</ShortcutKey>
            <ShortcutKey size="lg">Z</ShortcutKey>
          </TooltipContent>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger
            asChild
            style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <StyledIconButton name="redo" onClick={handleRedo} size="xs" disabled={!toolkitHistory.canRedo} />
          </TooltipTrigger>
          <TooltipContent align="center" side="bottom" contentStyle={{ fontSize: '12px' }}>
            <ShortcutText>Redo</ShortcutText>
            <ShortcutKey size="md">⌘</ShortcutKey>
            <ShortcutKey size="md">⇧</ShortcutKey>
            <ShortcutKey size="lg">Z</ShortcutKey>
          </TooltipContent>
        </Tooltip>
      </StyledHistoryControls>
    </FlexItem>
  );
};
