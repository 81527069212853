/**
 * Copyright 2022 Design Barn Inc.
 */

import { FlexItem } from '@lottiefiles/ds-core';

import { ReactComponent as BrandLogo } from '../../../assets/logo.svg';
import { styled } from '../../../config/stitches';
import { PANEL_WIDTH } from '../../../utils';

const StyleBrand = styled(FlexItem, {
  borderRight: '1px solid',
  borderColor: '$gray50',
  alignItems: 'center',
  display: 'inline-flex',
  height: '100%',
  paddingLeft: '$space5',
  paddingRight: '$space5',
  flexBasis: `calc(${PANEL_WIDTH}px - 32px)`,
  flexGrow: 0,
  flexShrink: 0,
});

const AppName = styled('div', {
  fontFamily: '$arboria',
  fontWeight: '$fontBold',
  fontSize: '$fontSizeBase',
  margin: 0,
  padding: 0,
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: '$space3',
  color: '$gray900',
  lineHeight: '150%',
  fontStyle: 'normal',
  '-webkit-font-smoothing': 'antialiased',
});

export const Brand = (): JSX.Element => {
  return (
    <StyleBrand customStyles={{}}>
      <BrandLogo />
      <AppName>Lottie Editor</AppName>
    </StyleBrand>
  );
};
