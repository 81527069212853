/**
 * Copyright 2022 Design Barn Inc.
 */

import { Button, Text } from '@lottiefiles/ds-core';
import type { ImageLayer } from '@lottiefiles/toolkit-js';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useRecoilValue } from 'recoil';

import { ReactComponent as ValidationIcon } from '../../assets/icons/validation-error.svg';
import { styled } from '../../config/stitches';
import { selectedLayerIdAtom } from '../../state';
import { useToolkitNode } from '../../toolkit';
import { ACCEPTED_IMAGE_FORMATS, MAX_FILES, MAX_SIZE } from '../../utils';

import { LayerSettingsContainer } from './layer-settings-container';

interface ImageLayerSettingsProps {
  title: string;
}

const StyledImage = styled('img', {
  position: 'relative',
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  opacity: 1,
  '&:hover': {
    opacity: 0.4,
  },
});

const UpdateButton = styled(Button, {
  justifyContent: 'center',
  flexGrow: 1,
});

const StyledImageDropzone = styled('div', {
  position: 'relative',
  width: '100%',
  height: 218,
  left: 0,
  top: 0,
  background: '#F3F6F8',
  opacity: 0.9,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  textAlign: 'center',
  overflow: 'hidden',
  boxSizing: 'border-box',
  padding: 10,

  variants: {
    dragStyle: {
      default: {
        border: '1px solid #D9E0E6',
      },
      isFocused: {
        border: '2px dashed #2196f3',
      },
      isDragActive: {
        border: '2px dashed #00e676',
      },
      isDragReject: {
        border: '2px dashed #ff1744',
      },
    },
  },
});

export const ImageLayerSettings = (props: ImageLayerSettingsProps): JSX.Element => {
  const { title } = props;

  const selectedLayerId = useRecoilValue(selectedLayerIdAtom);
  const selectedLayer = useToolkitNode(selectedLayerId) as ImageLayer;
  const [showUpdateButton, setShowUpdateButton] = useState(false);

  /**
   * Update Image Layer
   */
  const updateImageUri = (dataURL: string): void => {
    selectedLayer.image?.setUri(dataURL);
  };

  /**
   * Handle Image Drop
   */
  const onDrop = useCallback((acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (): void => {
      const dataURL = reader.result;

      updateImageUri(dataURL as string);
    };

    reader.readAsDataURL(file);
  }, []);

  const { fileRejections, getInputProps, getRootProps, isDragActive, open } = useDropzone({
    accept: ACCEPTED_IMAGE_FORMATS,
    maxFiles: MAX_FILES,
    maxSize: MAX_SIZE,
    onDrop,
    multiple: false,
    noClick: true,
  });

  const fileRejectionItems = fileRejections.map(({ errors }): any => (
    <div>
      {errors.length > 0 && (
        <Text
          plugins={false}
          variant="caption2"
          customTag="div"
          color="$gray500"
          textStyle={{
            fontWeight: 700,
            color: '$red500',
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            gap: 6,
          }}
        >
          <ValidationIcon /> Error! Unable to upload image
        </Text>
      )}
    </div>
  ));

  return (
    <LayerSettingsContainer
      title={title}
      subTitle={`${selectedLayer.image?.size.width} x ${selectedLayer.image?.size.height} px`}
    >
      <StyledImageDropzone {...getRootProps()} dragStyle={isDragActive ? 'isDragActive' : 'default'}>
        <input {...getInputProps()} />

        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
          onMouseOver={(): void => setShowUpdateButton(true)}
          onMouseOut={(): void => setShowUpdateButton(false)}
        >
          {selectedLayer.image?.uri && (
            <StyledImage
              src={selectedLayer.image.uri}
              style={{
                opacity: isDragActive || showUpdateButton ? 0.4 : 1,
              }}
            />
          )}

          {showUpdateButton && !isDragActive && (
            <UpdateButton
              label="Replace Image"
              css={{
                position: 'absolute',
                left: 0,
                right: 0,
                margin: 'auto',
                top: 0,
                bottom: 0,
                width: 118,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={open}
            />
          )}
        </div>
      </StyledImageDropzone>

      <Text
        plugins={false}
        variant="caption2"
        customTag="div"
        color="$gray500"
        textStyle={{
          fontWeight: 700,
          color: '$gray500',
          width: '100%',
          textAlign: 'center',
        }}
      >
        Accepted Format: JPG, PNG, SVG, WEBP
      </Text>

      <>{fileRejectionItems}</>
    </LayerSettingsContainer>
  );
};
