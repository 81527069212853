/**
 * Copyright 2022 Design Barn Inc.
 */

import {
  Button,
  Text,
  Icon,
  TooltipProvider,
  TooltipRoot as Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@lottiefiles/ds-core';
import type { FontAsset, TextLayer } from '@lottiefiles/toolkit-js';
import type { SyntheticEvent } from 'react';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { styled } from '../../config/stitches';
import { selectedLayerIdAtom } from '../../state';
import { useScene, useToolkitNode } from '../../toolkit';

const UpdateButton = styled(Button, {
  justifyContent: 'center !important',
  '&:disabled': {
    color: '$textIconDisabled',
  },
});

const StyledTextArea = styled('textarea', {
  backgroundColor: '$gray50',
  borderColor: '$gray100',
  paddingLeft: `$space4`,
  paddingRight: `$space4`,
  paddingTop: `$space2`,
  paddingBottom: `$space2`,
  borderRadius: `$roundedBase`,
  color: `$textIconPrimary`,
  resize: 'none',
  outline: 'none',
  height: `$space16`,
  fontSize: '$fontSizeXs',
  '&:focus': {
    outline: '2px solid $actionPrimary',
  },
  alignItems: 'center',
});

function normalizeLayerText(text: string): string {
  return text.replaceAll('\r', '\n');
}

export const TextLayerSettings = (): JSX.Element => {
  const scene = useScene();
  const selectedLayerId = useRecoilValue(selectedLayerIdAtom);
  const selectedLayer = useToolkitNode(selectedLayerId) as TextLayer | null;

  if (!selectedLayer) return <></>;

  const textData = selectedLayer.textData.value;

  const layerText = normalizeLayerText(textData.text);

  const [text, setText] = useState<string>(layerText);

  const fontAsset = scene?.fonts.find((font: FontAsset) => font.name === textData.fontName);

  // this is a hack to check if the font can be editable
  const isEditable = fontAsset?.chars.length === 0;

  /**
   * Update the local state of the text
   * @param event - Input event
   */
  const updateText = useCallback(
    (event: SyntheticEvent): void => {
      const target = event.target as HTMLInputElement;

      setText(target.value);
    },
    [setText],
  );

  // update the text state when the layer text changes externally by undo/redo
  const textRef = useRef('');

  textRef.current = text;

  useEffect(() => {
    if (textRef.current !== layerText) {
      setText(layerText);
    }
  }, [layerText]);

  /**
   * Update the text layer with the new text
   */
  const handleSetText = useCallback((): void => {
    if (text === layerText) return;

    const newTextData = textData.clone();

    const isMultilinesText = text.includes('\n');

    if (isMultilinesText) {
      // replace all the new lines with the carriage return
      newTextData.setText(text.replaceAll('\n', '\r'));

      // to prevent multi line text from overlapping
      if (newTextData.fontSize >= Number(newTextData.lineHeight)) {
        newTextData.setLineHeight(newTextData.fontSize);
      }
    } else {
      newTextData.setText(text);
    }

    selectedLayer.textData.setValueAtKeyFrame(newTextData, 0);
  }, [selectedLayer, text, layerText, textData]);

  return (
    <>
      <div>
        <Text
          plugins={false}
          variant="caption1"
          customTag="span"
          textStyle={{ color: '$textIconPrimary', display: 'flex', alignItems: 'center' }}
        >
          <span>Font: {selectedLayer.textData.value.fontName}</span>
          {!isEditable && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Icon
                    name="warning"
                    style={{ marginLeft: '$space2', color: '$orange300', width: '$size6', height: '$size6' }}
                  />
                </TooltipTrigger>
                <TooltipContent align="center" side="right">
                  Limited character support
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </Text>
        {!isEditable && (
          <Text plugins={false} variant="caption2" textStyle={{ color: '$gray500', marginTop: '$space2' }}>
            Editing is disabled due to how the font is stored
          </Text>
        )}
      </div>

      <StyledTextArea
        disabled={!isEditable}
        value={text}
        onChange={updateText}
        style={{
          fontFamily: `${textData.fontName}, karla, sans-serif`,
          background: isEditable ? '' : '#DAE1E7',
          color: isEditable ? '' : '#606F7B',
        }}
      />
      <UpdateButton disabled={!text || !isEditable} label="Update Text" onClick={handleSetText} />
    </>
  );
};
