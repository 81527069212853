/**
 * Copyright 2022 Design Barn Inc.
 */

import { FlexLayout, TooltipProvider } from '@lottiefiles/ds-core';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { styled } from '../../../config/stitches';
import { useKeypress } from '../../../hooks';
import { keyboardModalOpenAtom } from '../../../state';
import { TOP_BAR_HEIGHT } from '../../../utils';
import { Brand } from '../brand';
import { KeyboardShortcutsModal } from '../modals/keyboard-shortcuts-modal';

import { ActionControls } from './action-controls';
import { HistoryControls } from './history-controls';

const StyledTopBar = styled(FlexLayout, {
  height: TOP_BAR_HEIGHT,
  borderBottom: '1px solid $gray50',
  backgroundColor: '$white',
});

export const TopBar = (): JSX.Element => {
  const [keyboardModalOpen, setKeyboardModalOpen] = useRecoilState(keyboardModalOpenAtom);

  /**
   * Toggle Keyboard Shortcut Modal
   */
  const toggleKeyboardModal = useCallback((): void => {
    setKeyboardModalOpen(!keyboardModalOpen);
  }, [keyboardModalOpen, setKeyboardModalOpen]);

  /**
   * Keyboard Shortcuts
   */
  useKeypress('shift+/', toggleKeyboardModal);

  return (
    <StyledTopBar customStyles={{}} direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
      <TooltipProvider>
        <Brand />

        <FlexLayout
          customStyles={{ width: '100%', padding: '0 $space5' }}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <HistoryControls />

          <ActionControls />
        </FlexLayout>
        <KeyboardShortcutsModal />
      </TooltipProvider>
    </StyledTopBar>
  );
};
