/**
 * Copyright 2022 Design Barn Inc.
 */

import { Button, Text } from '@lottiefiles/ds-core';
import { Player } from '@lottiefiles/react-lottie-player';
import { styled } from '@stitches/react';
import type { FC } from 'react';
import { useCallback } from 'react';

import { LOLO_MAGNIFYING_GLASS } from '../../../utils';

export interface EmptyFrontpageProps {}

const AnimationContainer = styled('div', {
  width: '20%',
  alignContent: 'center',
  justifyContent: 'center',
  margin: 'auto',
  display: 'block',
});

const Container = styled('div', {
  width: '100%',
  alignContent: 'center',
  justifyContent: 'center',
  margin: 'auto',
  display: 'flex',
});

const KeyContainer = styled(Container, {
  marginTop: '12px',
  fontFamily: '$fontFamilyProduct',
  color: '$gray900',
  fontSize: '$fontSizeSm',
});

const ShortcutKey = styled('span', {
  display: 'flex',
  margin: '0 4px 0 0',
  alignContent: 'center',
  justifyContent: 'center',
  marginRight: '4px',
  width: '16px',
  height: '18px',
  padding: '5px',
  borderRadius: '4px',
  background: '#F3F6F8',
  boxShadow:
    '0px 0px 1px rgba(61, 72, 83, 0.36), 0px 2px 6px rgba(61, 72, 83, 0.06), 0px 8px 48px rgba(243, 246, 248, 0.15);',
  variants: {
    size: {
      sm: {
        paddingRight: '3px',
        paddingLeft: '3px',
      },
      md: {
        paddingRight: '4px',
        paddingLeft: '4px',
      },
      lg: {
        paddingRight: '6px',
        paddingLeft: '6px',
      },
    },
  },
});

export const EmptyFrontpage: FC<EmptyFrontpageProps> = () => {
  const resetScene = useCallback((): void => {
    window.location.reload();
  }, []);

  return (
    <div
      style={{ margin: 'auto', display: 'block', justifyContent: 'center', alignItems: 'center', paddingTop: '10%' }}
    >
      <AnimationContainer>
        <Player src={LOLO_MAGNIFYING_GLASS} autoplay={true} loop={true} />
      </AnimationContainer>
      <Container>
        <Text variant="h4" plugins={false} textStyle={{ margin: '0px' }}>
          Did you just delete everything?
        </Text>
      </Container>
      <Container>
        <Text variant="body1" plugins={false} textStyle={{ margin: '0px' }}>
          Undo can bring them back!
        </Text>
      </Container>
      <KeyContainer>
        <ShortcutKey size="lg">
          {/* <Text variant="body1" plugins={false} sty> */}⌘{/* </Text> */}
        </ShortcutKey>
        <ShortcutKey size="lg">Z</ShortcutKey>
      </KeyContainer>
      <Container style={{ marginTop: '14px' }}>
        <Button onClick={resetScene} label="Reset animation"></Button>
      </Container>
    </div>
  );
};
