/**
 * Copyright 2022 Design Barn Inc.
 */

import {
  Text,
  TextInput,
  Icon,
  TooltipProvider,
  TooltipRoot as Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@lottiefiles/ds-core';
import {
  MAX_FRAME_RATE,
  MIN_FRAME_RATE,
  MAX_WIDTH,
  MIN_WIDTH,
  MAX_HEIGHT,
  MIN_HEIGHT,
  Size,
} from '@lottiefiles/toolkit-js';
import React from 'react';
import { useForm } from 'react-hook-form';

import { styled } from '../../config/stitches';
import type { CSS } from '../../config/stitches';
import { useScene, useToolkit } from '../../toolkit';
import { animationSettings } from '../../utils';
import { LayerSettingsContainer } from '../layer-settings';

const MAX_DURATION = 20;
const MIN_DURATION = 0;

const StyledTextInput = styled(TextInput, {
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '&[type=number]': {
    '-moz-appearance': 'textfield',
  },
});

const StyledTooltipContent = styled(TooltipContent, {
  fontSize: '$fontSizeXs',
});

const AnimationSettingsContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '108px 1fr 1fr',
  alignItems: 'center',
  columnGap: '$space3',
  rowGap: '$space4',
});

const UpdateButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: `$size14`,
  border: `1px solid $borderDefault`,
  borderRadius: `$roundedSm`,
  padding: `$space2 $space4`,
  color: `$textIconPrimary`,
  fontWeight: 'bold',
  fontSize: `$fontSizeSm`,
  backgroundColor: `$actionTransparent`,
  textAlign: 'center',
  cursor: 'pointer',
  fontFamily: `$fontFamilyProduct`,
  '&:hover': {
    borderColor: `$borderStrong`,
    borderStyle: 'solid',
  },
  '&:disabled': {
    backgroundColor: `$actionSecondaryHover`,
    borderColor: `$borderSubtle`,
    color: `$textIconDisabled`,
    cursor: 'default',
  },
});

interface AnimationSettingsValues {
  duration: number;
  frameRate: number;
  height: number;
  width: number;
}

export const AnimationSettings: React.FC = () => {
  const scene = useScene();

  if (!scene) return <></>;

  const toolkit = useToolkit();

  const settings = React.useMemo(() => animationSettings(scene), [scene.state]);

  const { formState, handleSubmit, register, reset, resetField, watch } = useForm<AnimationSettingsValues>({
    defaultValues: {
      width: settings.width,
      height: settings.height,
      frameRate: settings.frameRate,
      duration: settings.duration,
    },
    reValidateMode: 'onChange',
  });

  const width = watch('width');
  const height = watch('height');
  const frameRate = watch('frameRate');
  const duration = watch('duration');

  const isInvalidWidth = React.useMemo(() => Number.isNaN(width) || width < MIN_WIDTH || width > MAX_WIDTH, [width]);
  const isInvalidHeight = React.useMemo(
    () => Number.isNaN(height) || height < MIN_HEIGHT || height > MAX_HEIGHT,
    [height],
  );
  const isInvalidFrameRate = React.useMemo(
    () => Number.isNaN(frameRate) || frameRate < MIN_FRAME_RATE || frameRate > MAX_FRAME_RATE,
    [frameRate],
  );
  const isInvalidDuration = React.useMemo(
    () => Number.isNaN(duration) || duration <= MIN_DURATION || duration > MAX_DURATION,
    [duration],
  );

  React.useEffect(() => {
    reset({
      width: settings.width,
      height: settings.height,
      frameRate: settings.frameRate,
      duration: settings.duration,
    });
  }, [settings, reset]);

  /**
   * Input Styles
   */
  const textInputWrapperStyle: CSS = { padding: '3px $space4' };
  const textInputStyle: CSS = { paddingLeft: '$space2' };
  const inputLabelStyle: CSS = { color: '$gray600', margin: 0, display: 'flex', alignItems: 'center' };

  const onSubmit = (): void => {
    toolkit.batch(() => {
      // Update Frame Rate
      if (frameRate !== settings.frameRate) {
        if (isInvalidFrameRate) {
          resetField('frameRate');
        } else {
          scene.timeline.setFrameRate(frameRate);
        }
      }

      // Update Width
      if (width !== settings.width) {
        if (isInvalidWidth) {
          resetField('width');
        } else {
          scene.crop(new Size(width, height));
        }
      }

      // Update Height
      if (height !== settings.height) {
        if (isInvalidHeight) {
          resetField('height');
        } else {
          scene.crop(new Size(width, height));
        }
      }

      // Update Duration
      if (duration !== settings.duration) {
        if (isInvalidDuration) {
          resetField('duration');
        } else {
          scene.timeline.setDuration(duration);
        }
      }
    });
  };

  const isDisabled = !formState.isDirty;

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <TooltipProvider>
        <LayerSettingsContainer title="Animation settings">
          <AnimationSettingsContainer>
            <Tooltip delayDuration={0}>
              <Text plugins={false} variant="caption1" textStyle={inputLabelStyle}>
                <span>Dimension</span>
                <TooltipTrigger asChild>
                  <Icon name="info" color="$borderDefault" style={{ marginLeft: '6px' }}></Icon>
                </TooltipTrigger>
                <StyledTooltipContent align="start" side="bottom">
                  Width & height must be {MIN_WIDTH} - {MAX_WIDTH} pixels
                </StyledTooltipContent>
              </Text>
            </Tooltip>
            <StyledTextInput
              prefix="W"
              inputSize={'xs'}
              type="number"
              placeholder="512"
              containerStyle={{ height: '100%', justifyContent: 'center' }}
              inputStyle={textInputStyle}
              inputWrapperStyle={{
                ...textInputWrapperStyle,
                '&:focus-within': {
                  boxShadow: isInvalidWidth ? '0 0 0 2px var(--colors-actionCritical)' : '',
                },
              }}
              {...register('width', {
                required: true,
                valueAsNumber: true,
              })}
            />
            <StyledTextInput
              prefix="H"
              inputSize={'xs'}
              type="number"
              placeholder="512"
              containerStyle={{ height: '100%', justifyContent: 'center' }}
              inputStyle={textInputStyle}
              inputWrapperStyle={{
                ...textInputWrapperStyle,
                '&:focus-within': {
                  boxShadow: isInvalidHeight ? '0 0 0 2px var(--colors-actionCritical)' : '',
                },
              }}
              {...register('height', {
                required: true,
                valueAsNumber: true,
              })}
            />
            <Tooltip delayDuration={0}>
              <Text plugins={false} variant="caption1" textStyle={inputLabelStyle}>
                <span>Duration</span>
                <TooltipTrigger asChild>
                  <Icon name="info" color="$borderDefault" style={{ marginLeft: '6px' }}></Icon>
                </TooltipTrigger>
                <StyledTooltipContent align="start" side="bottom">
                  Duration must be 0.1 - 20 seconds
                </StyledTooltipContent>
              </Text>
            </Tooltip>
            <StyledTextInput
              inputSize={'xs'}
              type="number"
              placeholder="1"
              step="0.01"
              inputWrapperStyle={{
                ...textInputWrapperStyle,
                '&:focus-within': {
                  boxShadow: isInvalidDuration ? '0 0 0 2px var(--colors-actionCritical)' : '',
                },
              }}
              containerStyle={{ height: '100%', justifyContent: 'center' }}
              {...register('duration', {
                required: true,
                valueAsNumber: true,
              })}
            />
            {/* Remove this later, adding this to keep the spacing as in figma design for now. Possible to have "Seconds" added here */}
            <div id="settings_container" style={{ width: '100%', position: 'relative' }}></div>
            <Tooltip delayDuration={0}>
              <Text plugins={false} variant="caption1" textStyle={inputLabelStyle}>
                <span>Frame rate</span>
                <TooltipTrigger asChild>
                  <Icon name="info" color="$borderDefault" style={{ marginLeft: '6px' }}></Icon>
                </TooltipTrigger>
                <StyledTooltipContent align="start" side="bottom">
                  Frame rate must be between {MIN_FRAME_RATE} - {MAX_FRAME_RATE} frames per second (fps)
                </StyledTooltipContent>
              </Text>
            </Tooltip>
            <StyledTextInput
              inputSize={'xs'}
              type="number"
              placeholder="24"
              inputWrapperStyle={{
                ...textInputWrapperStyle,
                '&:focus-within': {
                  boxShadow: isInvalidFrameRate ? '0 0 0 2px var(--colors-actionCritical)' : '',
                },
              }}
              containerStyle={{ height: '100%', justifyContent: 'center' }}
              {...register('frameRate', {
                required: true,
                valueAsNumber: true,
              })}
            />
          </AnimationSettingsContainer>

          <UpdateButton disabled={isDisabled} type="submit">
            Update
          </UpdateButton>
        </LayerSettingsContainer>
      </TooltipProvider>
    </form>
  );
};
