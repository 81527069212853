/**
 * Copyright 2022 Design Barn Inc.
 */

import { IconButton, TooltipRoot as Tooltip, TooltipTrigger, TooltipContent } from '@lottiefiles/ds-core';
import { useState } from 'react';

import { DropdownMenu } from '../dropdown-menu';

export const SupportButton = (): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  /**
   * Handle Support button click
   */
  const handleSupport = (): any => {
    console.log('support');
  };

  return (
    <Tooltip>
      <DropdownMenu onOpenChange={setDropdownOpen}>
        <TooltipTrigger
          asChild
          style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <IconButton
            name="question-mark"
            onClick={handleSupport}
            size="sm"
            buttonStyle={{
              backgroundColor: dropdownOpen ? '$backgroundSubtle' : '$backgroundDefault',
            }}
            iconStyle={{ color: '$textIconPrimary' }}
          />
        </TooltipTrigger>
      </DropdownMenu>
      <TooltipContent align="center" side="bottom" contentStyle={{ fontSize: '12px', zIndex: 2 }}>
        Help
      </TooltipContent>
    </Tooltip>
  );
};
