/**
 * Copyright 2022 Design Barn Inc.
 */

import { FlexItem } from '@lottiefiles/ds-core';
import React, { useEffect, useRef } from 'react';
import type { PropsWithChildren } from 'react';
import { useRecoilState } from 'recoil';

import { styled } from '../../../config/stitches';
import {
  backgroundColorValueAtom,
  canvasDimensionsAtom,
  selectedLayerIdAtom,
  transparentBackgroundAtom,
} from '../../../state';
import { TOP_BAR_HEIGHT } from '../../../utils';

const StyledCanvasArea = styled(FlexItem, {
  flex: 1,
  height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&::before': {
    content: '',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    backgroundImage:
      'linear-gradient(to right, rgba(250, 250, 250, 0.9), rgba(250, 250, 250, 0.9)), /* checkered effect */ linear-gradient(to right, black 50%, white 50%), linear-gradient(to bottom, black 50%, white 50%) !important',
    backgroundBlendMode: 'normal, difference, normal !important',
    backgroundSize: '2em 2em !important',
  },
});

export const CanvasArea: React.FC<PropsWithChildren> = ({ children }): React.ReactElement => {
  const [animationBackgroundValue] = useRecoilState(backgroundColorValueAtom);
  const [, setSelectedLayerId] = useRecoilState(selectedLayerIdAtom);
  const [transparentBackground] = useRecoilState(transparentBackgroundAtom);
  const [, setCanvasDimensions] = useRecoilState(canvasDimensionsAtom);
  const canvasRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      setCanvasDimensions({ width: canvasRef.current.clientWidth, height: canvasRef.current.clientHeight });
    }
  }, []);

  const clearLayerSelection = React.useCallback(() => {
    setSelectedLayerId('');
  }, [setSelectedLayerId]);

  return (
    <StyledCanvasArea
      ref={canvasRef}
      customStyles={{}}
      css={{
        background: `rgba(
          ${animationBackgroundValue.r}, 
          ${animationBackgroundValue.g}, 
          ${animationBackgroundValue.b}, 
          ${transparentBackground ? 0 : animationBackgroundValue.a}
          )`,
      }}
      onClick={clearLayerSelection}
    >
      {children}
    </StyledCanvasArea>
  );
};
