/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { useAuth } from '../../providers';
import { PageLoader } from '../ui';

interface Props {
  children: React.ReactNode;
}

export const AuthManager: React.FC<Props> = ({ children }) => {
  const isAuthorized = useAuth();

  if (!isAuthorized) return <PageLoader />;

  return <>{children}</>;
};
