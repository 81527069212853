/**
 * Copyright 2022 Design Barn Inc.
 */

import { TextInput } from '@lottiefiles/ds-core';
import { colord } from 'colord';
import { useEffect, useState } from 'react';
import type { RgbaColor } from 'react-colorful';
import { HexColorInput, RgbaColorPicker } from 'react-colorful';

import { styled } from '../../config/stitches';
import './color-picker.css';

const StyledColorPicker = styled('div', {
  background: '$white',
  width: 275,
  padding: 16,
  boxShadow: '$shadowPopover',
  borderRadius: 8,
});

const ColorPreview = styled('div', {
  width: 24,
  height: 24,
  display: 'block',
  borderRadius: 4,
  '&::before': {
    content: '',
    width: 24,
    height: 24,
    backgroundImage: "url('check.png')",
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

const StyledHexColorInput = styled(HexColorInput, {
  width: 55,
  padding: '3px 12px',
  height: 18,
  backgroundColor: '$backgroundSubtle',
  borderRadius: '$roundedBase',
  border: '1px solid $gray100',
  fontSize: '$fontSizeXs',
  color: '$textIconPrimary',
  fontFamily: '$fontFamilyProduct',
  fontWeight: '$fontRegular',
  textTransform: 'uppercase',
});

interface ColorPickerProps {
  colorValue: RgbaColor;
  onChange: (color: RgbaColor) => void;
  showAlphaInput?: boolean;
  showAlphaSelector?: boolean;
  showHexInput?: boolean;
  showPreview?: boolean;
}
export const ColorPicker = ({
  colorValue,
  onChange,
  showAlphaInput,
  showAlphaSelector,
  showHexInput,
  showPreview,
}: ColorPickerProps): JSX.Element => {
  const [color, setColor] = useState(colorValue);

  /**
   * Convert hex color to rgb and update color state
   * @param hexColor - Hex color value
   */
  const handleHexColorInput = (hexColor: string): void => {
    const newColor = colord(hexColor);

    if (newColor.isValid() && hexColor.length === 7) {
      setColor(newColor.rgba);
    }
  };

  /**
   * Convert alpha value to decimal and update color state
   * @param event - Input Event
   */
  const handleAlphaInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const alpha = Number(event.target.value) / 100;
    const newColor = colord({ ...color, a: alpha });

    if (newColor.isValid()) {
      setColor(newColor.rgba);
    }
  };

  useEffect(() => {
    onChange(color);
  }, [color]);

  useEffect(() => {
    // hide alpha selector is showAlphaSelector prop is false
    if (!showAlphaSelector) {
      const alpha = document.querySelector('.react-colorful__alpha');
      const alphaGradient = document.querySelector('.react-colorful__alpha-gradient');

      if (alpha) {
        alpha.remove();
      }

      if (alphaGradient) {
        alphaGradient.remove();
      }
    }
  }, [showAlphaSelector]);

  return (
    <StyledColorPicker>
      <RgbaColorPicker color={color} onChange={setColor} />
      <div
        style={{
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
          gap: 12,
          marginTop: showAlphaSelector ? 12 : 0,
        }}
      >
        {showPreview && <ColorPreview css={{ backgroundColor: colord(color).toRgbString() }} />}
        {showHexInput && (
          <StyledHexColorInput prefixed={true} color={colord(color).toHex()} onChange={handleHexColorInput} />
        )}
        {showAlphaInput && (
          <TextInput
            inputSize={'xs'}
            containerStyle={{ height: '100%', justifyContent: 'center', width: 60 }}
            inputWrapperStyle={{ padding: '3px 12px', height: 18 }}
            value={color.a * 100}
            type="number"
            min="0"
            max="100"
            onChange={handleAlphaInput}
          />
        )}
      </div>
    </StyledColorPicker>
  );
};
