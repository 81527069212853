import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  IntOrString: any;
  JSON: any;
  Point: any;
  QuerySortOptions: any;
};

export type Account = {
  __typename?: 'Account';
  addressLineOne?: Maybe<Scalars['String']>;
  addressLineTwo?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  /** Whether the account pays for a subscription to any workspace */
  isPaid: Scalars['Boolean'];
  name: Scalars['String'];
  /** Recipient user of the invitation */
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId: Scalars['String'];
  paymentProvider: PaymentProvider;
  paymentProviderCustomerId?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  website?: Maybe<Scalars['String']>;
};

/** Account delete request types for account deletion requests */
export enum AccountDeleteRequestType {
  CancelRequest = 'CANCEL_REQUEST',
  RequestDelete = 'REQUEST_DELETE',
}

export type AccountInput = {
  addressLineOne?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AnimationReportInput = {
  animationId: Scalars['Int'];
  body?: InputMaybe<Scalars['String']>;
  complaintType: ComplaintType;
  url?: InputMaybe<Scalars['String']>;
};

export type Animator = {
  __typename?: 'Animator';
  avatarUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type AnimatorConnection = {
  __typename?: 'AnimatorConnection';
  /** A list edges. */
  edges: Array<AnimatorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type AnimatorEdge = {
  __typename?: 'AnimatorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Animator;
};

export type Attributes = {
  __typename?: 'Attributes';
  /** Size of the uploaded file */
  contentLength?: Maybe<Scalars['String']>;
  /** Type of uploaded file */
  contentType?: Maybe<Scalars['String']>;
  /** S3 Version Id */
  s3VersionId?: Maybe<Scalars['String']>;
};

export type Authentication = {
  __typename?: 'Authentication';
  /** The token to include in future requests to this API. */
  accessToken?: Maybe<Scalars['String']>;
  /** The ISO Date string in the format "2022-12-06T13:33:08.000Z". */
  expiresAt: Scalars['DateTime'];
  /** Returned only from the `socialLogin` mutation. Will be true if a LottieFiles account was created in the `socialLogin`. */
  socialLoginAccountCreated?: Maybe<Scalars['Boolean']>;
  /** The token type, e.g. "Bearer". */
  tokenType: Scalars['String'];
};

export type BillingPackage = {
  __typename?: 'BillingPackage';
  /** Get the default active pricing for a billing package. */
  billingPackagePrice?: Maybe<BillingPackagePrice>;
  createdAt: Scalars['DateTime'];
  defaultEntitlements?: Maybe<Array<PlanEntitlement>>;
  id: Scalars['ID'];
  isBillable: Scalars['Boolean'];
  name: Scalars['String'];
  planPosition: Scalars['Float'];
  status: Scalars['String'];
  stripeProductId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BillingPackageConnection = {
  __typename?: 'BillingPackageConnection';
  /** A list edges. */
  edges: Array<BillingPackageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type BillingPackageEdge = {
  __typename?: 'BillingPackageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BillingPackage;
};

export type BillingPackagePrice = {
  __typename?: 'BillingPackagePrice';
  billingCycle: Scalars['String'];
  billingPackage: BillingPackage;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  endsAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  planId: Scalars['String'];
  price: Scalars['Float'];
  startsAt: Scalars['DateTime'];
  stripePriceId: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Blog = {
  __typename?: 'Blog';
  category?: Maybe<BlogCategory>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  link: Scalars['String'];
  postedAt: Scalars['DateTime'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type BlogCategory = {
  __typename?: 'BlogCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type BlogConnection = {
  __typename?: 'BlogConnection';
  /** A list edges. */
  edges: Array<BlogEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type BlogEdge = {
  __typename?: 'BlogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Blog;
};

export type CollectionAnimationPreview = {
  __typename?: 'CollectionAnimationPreview';
  image: Scalars['String'];
};

/** Collection animation types */
export enum CollectionAnimationType {
  All = 'ALL',
  Animation = 'ANIMATION',
  Sticker = 'STICKER',
}

export type CollectionAnimationTypeInput = {
  animationType?: InputMaybe<CollectionAnimationType>;
};

export type CollectionInput = {
  title: Scalars['String'];
  type?: InputMaybe<CollectionType>;
};

/** Collection types */
export enum CollectionType {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type Color = {
  __typename?: 'Color';
  hex: Scalars['String'];
};

export type ColorPalette = {
  __typename?: 'ColorPalette';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  palette: Array<Color>;
};

export type ColorPaletteConnection = {
  __typename?: 'ColorPaletteConnection';
  /** A list edges. */
  edges: Array<ColorPaletteEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type ColorPaletteEdge = {
  __typename?: 'ColorPaletteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ColorPalette;
};

/** A comment on an entity */
export type Comment = {
  __typename?: 'Comment';
  attachments?: Maybe<Array<CommentAttachment>>;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  extra?: Maybe<CommentExtra>;
  guestName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mentions?: Maybe<Array<CommentUserMention>>;
  nodeId: Scalars['Int'];
  /** The parent comment */
  parent?: Maybe<Comment>;
  parentId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  reactions?: Maybe<Array<CommentReaction>>;
  readReceipts?: Maybe<Array<CommentReadReceipt>>;
  replyCount: Scalars['Float'];
  resolvedAt?: Maybe<Scalars['DateTime']>;
  /** The user who resolved the comment */
  resolvedBy?: Maybe<CommentUser>;
  resolvedById?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** The user who authored the comment */
  user?: Maybe<CommentUser>;
  userId?: Maybe<Scalars['String']>;
};

/** The annotation for a comment */
export type CommentAnnotation = {
  __typename?: 'CommentAnnotation';
  ratio?: Maybe<Array<Scalars['Float']>>;
  type: CommentAnnotationType;
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type CommentAnnotationInput = {
  ratio?: InputMaybe<Array<Scalars['Float']>>;
  type: CommentAnnotationType;
  x: Scalars['Float'];
  y: Scalars['Float'];
};

/** The annotation type */
export enum CommentAnnotationType {
  Point = 'Point',
}

export type CommentAttachment = {
  __typename?: 'CommentAttachment';
  filename: Scalars['String'];
  key: Scalars['String'];
  mimeType: Scalars['String'];
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  /** A list edges. */
  edges: Array<CommentEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type CommentCreateInput = {
  body: Scalars['String'];
  entityId: Scalars['ID'];
  extra?: InputMaybe<CommentExtraInput>;
  /** The entity type */
  type: CommentableEntityType;
};

export type CommentEdge = {
  __typename?: 'CommentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Comment;
};

/** Extra properties in a comment */
export type CommentExtra = {
  __typename?: 'CommentExtra';
  annotation?: Maybe<CommentAnnotation>;
  frame: Scalars['Int'];
  type: CommentExtraType;
};

export type CommentExtraInput = {
  annotation?: InputMaybe<CommentAnnotationInput>;
  frame: Scalars['Int'];
  type: CommentExtraType;
};

/** Comment extra type */
export enum CommentExtraType {
  Annotation = 'Annotation',
  Keyframe = 'Keyframe',
}

export type CommentInput = {
  content: Scalars['String'];
  frame: Scalars['Int'];
  marker?: InputMaybe<Scalars['String']>;
};

/** The type of mention */
export enum CommentMentionType {
  UserMention = 'UserMention',
}

export type CommentReaction = {
  __typename?: 'CommentReaction';
  createdAt: Scalars['DateTime'];
  type: Scalars['String'];
  /** User who made the reaction */
  user?: Maybe<CommentUser>;
  userId: Scalars['String'];
};

export type CommentReadReceipt = {
  __typename?: 'CommentReadReceipt';
  createdAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type CommentUser = {
  __typename?: 'CommentUser';
  avatarUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A mentioned user */
export type CommentUserMention = {
  __typename?: 'CommentUserMention';
  /** The mention type: Always UserMention */
  type: CommentMentionType;
  /** The user that was mentioned */
  user?: Maybe<CommentUser>;
  /** The user id */
  userId: Scalars['String'];
};

export enum CommentableEntityType {
  FileVersion = 'FileVersion',
}

/** Complaint types for report animation */
export enum ComplaintType {
  GuidelinesViolation = 'GUIDELINES_VIOLATION',
  Other = 'OTHER',
  Plagiarism = 'PLAGIARISM',
}

/** Contract types for hire requests */
export enum ContractType {
  Freelance = 'FREELANCE',
  Fulltime = 'FULLTIME',
}

export type CreateFolderInput = {
  folderId?: InputMaybe<Scalars['String']>;
  isFolder?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  slug: Scalars['String'];
};

export type CreateUploadRequestInput = {
  bgColor?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['Boolean']>;
  previews?: InputMaybe<Scalars['Boolean']>;
  thumbnails?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  variationType?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};

export type CreateWorkspaceColorPaletteInput = {
  colors: Array<Scalars['String']>;
  workspaceId: Scalars['ID'];
};

/** Duration filter types for popular animations */
export enum DurationFilterType {
  AllTime = 'ALL_TIME',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type FeatureObject = {
  __typename?: 'FeatureObject';
  currentCount?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  /** Get the permission scopes for animation for the current user */
  animationPermissionScopes: Array<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId: Scalars['String'];
  /** Get the access of the current logged in user */
  currentUserAccess?: Maybe<Scalars['String']>;
  currentVersion: FileVersion;
  currentVersionId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionModifiedBy?: Maybe<User>;
  descriptionModifiedByUserId?: Maybe<Scalars['String']>;
  descriptionUpdatedAt?: Maybe<Scalars['DateTime']>;
  editHash?: Maybe<Scalars['String']>;
  /** Key of featured file */
  featuredFileKey?: Maybe<Scalars['String']>;
  features: Array<FeatureObject>;
  fileObject?: Maybe<FileObject>;
  folder?: Maybe<Folder>;
  folderId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isHidden: Scalars['Boolean'];
  modifiedBy?: Maybe<User>;
  modifiedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  project: Project;
  projectId: Scalars['ID'];
  /** Get the public asset of the file. */
  publicAsset?: Maybe<PublicAsset>;
  showDescOnCollection?: Maybe<Scalars['Boolean']>;
  sourceFileKey?: Maybe<Scalars['String']>;
  sourceFileType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** Users with permissions the file has access */
  userResourcePermissions?: Maybe<UserResourcePermission>;
};

export type FileConnection = {
  __typename?: 'FileConnection';
  /** A list edges. */
  edges: Array<FileEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type FileCreateFallbackInput = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  fileKey: Scalars['String'];
  folderId?: InputMaybe<Scalars['String']>;
  handbackId?: InputMaybe<Scalars['String']>;
  isFolder?: InputMaybe<Scalars['Boolean']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isMyPrivateAnimation?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
};

export type FileCreateInput = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  fileKey: Scalars['String'];
  fileVersionId: Scalars['String'];
  folderId?: InputMaybe<Scalars['String']>;
  handbackId?: InputMaybe<Scalars['String']>;
  isFolder?: InputMaybe<Scalars['Boolean']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isMyPrivateAnimation?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  projectId: Scalars['ID'];
};

export type FileDescriptionUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  showDescOnCollection: Scalars['Boolean'];
};

export type FileEdge = {
  __typename?: 'FileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: File;
};

export type FileHandback = {
  __typename?: 'FileHandback';
  assetUrl: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  fileId: Scalars['String'];
  fileVersionId: Scalars['String'];
  id: Scalars['ID'];
  metadata: Scalars['JSON'];
  newFileId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

/** File */
export type FileObject = {
  __typename?: 'FileObject';
  /** Attributes generated */
  attributes?: Maybe<Attributes>;
  /** Updated Date */
  cleanedAt: Scalars['DateTime'];
  /** Thumbanils generated */
  contents: Scalars['JSON'];
  /** Created Date */
  createdAt: Scalars['DateTime'];
  /** Updated Date */
  deletedAt: Scalars['DateTime'];
  /** File variations */
  fileVariations?: Maybe<Array<FileVariation>>;
  /** Uniquely generated filename */
  filename: Scalars['String'];
  /** Unique file key for a file */
  key: Scalars['String'];
  metadata?: Maybe<Metadata>;
  /** Previews  generated */
  previews?: Maybe<Preview>;
  /** Status of uploaded file */
  status: Scalars['String'];
  thumbnails?: Maybe<Thumbnail>;
  /** Updated Date */
  updatedAt: Scalars['DateTime'];
  /** The url to uploaded  file */
  url: Scalars['String'];
  /** Version ID for a file */
  versionId: Scalars['String'];
};

/** File */
export type FileObjectRegenerate = {
  __typename?: 'FileObjectRegenerate';
  /** Attributes generated */
  attributes?: Maybe<Attributes>;
  /** Updated Date */
  cleanedAt: Scalars['DateTime'];
  /** Thumbanils generated */
  contents: Scalars['JSON'];
  /** Created Date */
  createdAt: Scalars['DateTime'];
  /** Updated Date */
  deletedAt: Scalars['DateTime'];
  /** File variations */
  fileVariations?: Maybe<Array<FileVariation>>;
  /** Uniquely generated filename */
  filename: Scalars['String'];
  /** Unique file key for a file */
  key: Scalars['String'];
  metadata?: Maybe<Metadata>;
  /** Status of uploaded file */
  status: Scalars['String'];
  /** The job id of thumbnails */
  thumbnailJobId?: Maybe<Scalars['String']>;
  /** Updated Date */
  updatedAt: Scalars['DateTime'];
  /** The url to uploaded  file */
  url: Scalars['String'];
  /** Version ID for a file */
  versionId: Scalars['String'];
};

export type FilePreviewGenerate = {
  __typename?: 'FilePreviewGenerate';
  /** Unique ID of file variation */
  id?: Maybe<Scalars['String']>;
  /** Job Id for file preview generation process. */
  jobId?: Maybe<Scalars['String']>;
};

export type FilePreviewGenerateStatus = {
  __typename?: 'FilePreviewGenerateStatus';
  /** File preview generation status */
  status?: Maybe<PreviewGenerationStatus>;
};

export type FileRenameInput = {
  name: Scalars['String'];
};

/** Allowed status for files */
export enum FileStatus {
  Approved = 'Approved',
  InProgress = 'InProgress',
  NeedsReview = 'NeedsReview',
}

export type FileUploadRequest = {
  __typename?: 'FileUploadRequest';
  fields: Scalars['JSON'];
  key: Scalars['String'];
  url: Scalars['String'];
};

export type FileVariant = {
  __typename?: 'FileVariant';
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fileKey: Scalars['String'];
  fileObject?: Maybe<FileObject>;
  fileSize: Scalars['Float'];
  fileVariationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isOptimized: Scalars['Boolean'];
  jobId?: Maybe<Scalars['String']>;
  metadata?: Maybe<FileVariantMetadata>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  workflowFileId: Scalars['String'];
  workflowFileVersionId: Scalars['String'];
};

export type FileVariantInput = {
  format: Scalars['String'];
  height: Scalars['Int'];
  transparency?: InputMaybe<Scalars['Boolean']>;
  width: Scalars['Int'];
};

export type FileVariantMetadata = {
  __typename?: 'FileVariantMetadata';
  fps?: Maybe<Scalars['Int']>;
  height: Scalars['Int'];
  transparency?: Maybe<Scalars['Boolean']>;
  width: Scalars['Int'];
};

export type FileVariantUpdateInput = {
  fileSize: Scalars['Float'];
  fileVariationId: Scalars['String'];
};

export type FileVariation = {
  __typename?: 'FileVariation';
  /** Attributes generated */
  attributes?: Maybe<Attributes>;
  /** Created defaultValue */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** File Key for the source file  */
  fileKey?: Maybe<Scalars['String']>;
  /** File Version Id of the source file */
  fileVersionId?: Maybe<Scalars['String']>;
  /** Unique filename of the variantion */
  filename?: Maybe<Scalars['String']>;
  /** Unique ID of file variation */
  id?: Maybe<Scalars['String']>;
  /** Metadata for file variation */
  metadata?: Maybe<VariationMetadata>;
  /** Status of file variation */
  status?: Maybe<Scalars['String']>;
  /** Type of variation. */
  type?: Maybe<Scalars['String']>;
  /** Url of the file variation */
  url?: Maybe<Scalars['String']>;
};

export type FileVersion = {
  __typename?: 'FileVersion';
  backgroundColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId: Scalars['String'];
  fileId: Scalars['ID'];
  fileKey: Scalars['String'];
  fileObject?: Maybe<FileObject>;
  fileVersionId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String'];
  playSegment: Array<PlaySegment>;
  sourceFileId?: Maybe<Scalars['ID']>;
  tags: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  versionNumber: Scalars['Float'];
};

export type FileVersionConnection = {
  __typename?: 'FileVersionConnection';
  /** A list edges. */
  edges: Array<FileVersionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type FileVersionCreateInput = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  fileId: Scalars['ID'];
  fileKey: Scalars['String'];
  fileVersionId: Scalars['String'];
  handbackId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type FileVersionEdge = {
  __typename?: 'FileVersionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FileVersion;
};

export type Folder = {
  __typename?: 'Folder';
  /** Get the permission scopes for animation for the current user */
  animationPermissionScopes: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId: Scalars['String'];
  /** Get the access of the current logged in user */
  currentUserAccess?: Maybe<Scalars['String']>;
  /** Keys of featured files list */
  featuredFileKeys?: Maybe<Array<Scalars['String']>>;
  features: Array<FeatureObject>;
  filesCount: Scalars['Float'];
  id: Scalars['ID'];
  isHidden: Scalars['Boolean'];
  modifiedBy?: Maybe<User>;
  modifiedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  project: Project;
  projectId: Scalars['ID'];
  thumbnails?: Maybe<Array<Maybe<FileObject>>>;
  updatedAt: Scalars['DateTime'];
};

export type FolderConnection = {
  __typename?: 'FolderConnection';
  /** A list edges. */
  edges: Array<FolderEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type FolderEdge = {
  __typename?: 'FolderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Folder;
};

export type FolderRenameInput = {
  name: Scalars['String'];
};

export type HireRequestInput = {
  brief: Scalars['String'];
  contractType: ContractType;
  deadlineAt: Scalars['DateTime'];
  shouldCopyEmail: Scalars['Boolean'];
  subject: Scalars['String'];
  userId: Scalars['ID'];
};

export type HitCountEvent = {
  __typename?: 'HitCountEvent';
  errors: Scalars['String'];
  message: Scalars['String'];
  status: Scalars['String'];
};

export type HitCountEventInput = {
  ip?: InputMaybe<Scalars['String']>;
  method: Scalars['Int'];
  source?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
  visitorId?: InputMaybe<Scalars['ID']>;
};

export type InvitationRecipient = {
  access?: InputMaybe<Scalars['String']>;
  recipientEmail: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  /** Retrieves the total amount for the invoice. */
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  customer: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  items: Array<InvoiceItem>;
  link?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  subscription: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount: Scalars['String'];
  currency: Scalars['String'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  proration: Scalars['Boolean'];
  quantity: Scalars['Float'];
  subscription: Scalars['String'];
};

export type LoginToken = {
  __typename?: 'LoginToken';
  /** The URL to direct the user to in order to conclude the token login. */
  loginUrl: Scalars['String'];
  /** The token to use with the tokenLogin mutation. */
  token: Scalars['String'];
};

export type Metadata = {
  __typename?: 'Metadata';
  /** Colors */
  colors?: Maybe<Scalars['JSON']>;
  /** Frame Rate */
  frameRate?: Maybe<Scalars['String']>;
  /** No. of frames */
  frames?: Maybe<Scalars['String']>;
  /** Lottie Generator */
  generator?: Maybe<Scalars['String']>;
  /** Lottie Height */
  height?: Maybe<Scalars['String']>;
  /** Lottie Start Frame */
  inPoint?: Maybe<Scalars['String']>;
  /** No of Layers */
  layers?: Maybe<Scalars['String']>;
  /** Lottie Colors */
  lottieColors?: Maybe<Scalars['JSON']>;
  /** Lottie Outpoint */
  outPoint?: Maybe<Scalars['String']>;
  /** Lottie Version */
  version?: Maybe<Scalars['String']>;
  /** Lottie Width */
  width?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  accountDeleteRequestCreate: Scalars['Boolean'];
  /** Create a stripe session and get the ID of the session */
  accountPaymentProviderCustomerPortalSessionCreate: Scalars['String'];
  /** Update organization by its id. */
  accountUpdate: Account;
  /** Cancels the ongoing email change process for the user. */
  cancelEmailChange: User;
  /** Add a comment to an animation */
  commentCreate: Comment;
  /** Delete a comment */
  commentDelete: Scalars['Boolean'];
  /** Edit a comment */
  commentEdit: Comment;
  /** Publish an unpublished comment */
  commentPublish: Comment;
  /** React to a comment */
  commentReact: Comment;
  /** Reply to a comment */
  commentReplyCreate: Comment;
  /** Mark a comment as resolved */
  commentResolve: Comment;
  /** Unpublish a comment */
  commentUnpublish: Comment;
  /** Unreact to a comment */
  commentUnreact: Comment;
  /** Mark a resolved comment as unresolved */
  commentUnresolve: Comment;
  /** Completes an email change process. */
  confirmEmailChange: Scalars['Boolean'];
  /** Completes a reset password process. */
  confirmResetPassword: Scalars['Boolean'];
  /** Initiates the long poll token login process. */
  createLoginToken: LoginToken;
  exportJsonFile: FileVariant;
  /** Create a new file. */
  fileCreate: File;
  /** Fallback mutation to create a new file */
  fileCreateFallback: File;
  /** Delete an existing file by its id. */
  fileDelete: Scalars['Boolean'];
  fileDescriptionUpdate: File;
  /** Duplicate a file. */
  fileDuplicate: File;
  fileNotificationsSubscribe: Scalars['Boolean'];
  fileNotificationsUnsubscribe: Scalars['Boolean'];
  filePreviewCreate: FileVariant;
  /** Update the name of the animation */
  fileRename: File;
  /** Modify an existing file by its id. */
  fileUpdate: File;
  /** Update background color of the file */
  fileUpdateBackgroundColor: File;
  fileUpdateStatus: File;
  fileUploadRequestCreate: FileUploadRequest;
  fileVariantDelete: FileVariant;
  fileVariantFallback: FileVariant;
  fileVariantUpdate: FileVariant;
  /** Create a new version of the file */
  fileVersionCreate: FileVersion;
  /** Delete a version from the file */
  fileVersionDelete: Scalars['Boolean'];
  /** Restore the current file to the specific version ( also increment the version ) */
  fileVersionRestore: File;
  /** Generate tags for file version */
  fileVersionTagsUpdate: Scalars['Boolean'];
  /** Delete existing files by its id. */
  filesDelete: Scalars['Boolean'];
  /** Move file or folder to a new folder. */
  filesMoveToFolder: Array<File>;
  /** Move file or folder to a new folder. */
  filesMoveToProject: Array<File>;
  /** Create a new folder. */
  folderCreate: Folder;
  /** Delete an existing folder by its id. */
  folderDelete: Scalars['Boolean'];
  /** Rename folder */
  folderRename: Folder;
  /** Get S3 presigned icon upload URL */
  getIconUploadPresignedData: WorkspaceIconUploadObject;
  hireRequestCreate: Scalars['Boolean'];
  hitCountEventCreate: HitCountEvent;
  /** @deprecated Use the query `isUsernameAvailable` instead of this mutation. */
  isUsernameAvailable: Scalars['Boolean'];
  /** Logouts the current user, "true" if the session is successfully destroyed. */
  logout: Scalars['Boolean'];
  /** To be called by an authenticated user. It marks the token for the login token process as valid, and enables the `tokenLogin` mutation to receive a new session based on the token. */
  markLoginTokenValid: Scalars['Boolean'];
  optimizeWorkflowFile: FileVariant;
  /** Updates a user's password. */
  passwordChange: Scalars['Boolean'];
  /** Email/Password user login. */
  passwordLogin: Authentication;
  /** remove play segment from file version */
  playSegmentRemove: FileVersion;
  /** add or update play segment to a file version */
  playSegmentUpsert: FileVersion;
  /** clear all play segments from version */
  playSegmentsClear: FileVersion;
  /** copy play segment from one file version to another */
  playSegmentsCopy: FileVersion;
  privateShareAccept: PrivateShare;
  /** Add user to resource */
  privateShareInviteUser: Array<PrivateShare>;
  /** Re-invite user to private share */
  privateShareReInviteUser: PrivateShare;
  /** Update user access to the shared resource */
  privateShareSetPermission?: Maybe<PrivateShare>;
  /** Finishes the process of uploading a new profile image. Must be called right after finishing PUTing the file to the signed URL provided by the `uploadProfilePhoto` mutation. Will process the uploaded image into different sizes and store them. */
  processUserProfilePhotoUpload: Scalars['Boolean'];
  /** Create a new project. */
  projectCreate: Project;
  /** Delete an existing project by its id. */
  projectDelete: Scalars['Boolean'];
  /** Modify an existing project by its id. */
  projectUpdate: Project;
  /** Update the project access to workspace. */
  projectUpdatePermission: Project;
  publicAnimationCollectionAddAnimation: PublicCollection;
  publicAnimationCollectionCreate: PublicCollection;
  publicAnimationCollectionDelete: Scalars['Boolean'];
  publicAnimationCollectionDeleteAnimations: Scalars['Boolean'];
  publicAnimationCollectionUpdate: PublicCollection;
  publicAnimationCreateComment: PublicComment;
  publicAnimationCreateCommentReply: PublicComment;
  publicAnimationDelete: PublicAnimationDeleteResponse;
  publicAnimationLike: PublicAnimation;
  publicAnimationReport: Scalars['Boolean'];
  publicAnimationResolveComment: PublicComment;
  publicAnimationUnlike: PublicAnimation;
  /** Extend public asset link expiry */
  publicAssetExtendExpiry: PublicAsset;
  /** Re-generate public asset link */
  publicAssetRegenerate: PublicAsset;
  /** Restore public asset link to a different workflow file version */
  publicAssetRestore: Array<PublicAsset>;
  /** Publish public asset link for a workflow file version */
  publicAssetUpdate: Array<PublicAsset>;
  publicShareCreate: PublicShare;
  /** Generate public link for a given file key */
  publicShareCreateForFileKey?: Maybe<PublicShare>;
  /** Email/Password user registration. */
  register: Authentication;
  /** Initiates the email change process, will fire an email for the user to conclude the email change process. */
  requestEmailChange: User;
  /** Resends the change email confirmation email message. */
  resendEmailChangeEmail: Scalars['Boolean'];
  /** Resends the verification email for the current logged in user. */
  resendVerificationEmail: Scalars['Boolean'];
  /** Will send a reset password email, if the email belongs to a registered user. */
  resetPassword: Scalars['Boolean'];
  /** Sets the segments to apply to the current logged in user. */
  setUserSegments: Scalars['Boolean'];
  /** Determine if it's the user's first attempt at login, and if so, set-up the workspace. */
  setupInitialWorkspace: Workspace;
  /** Login with a social provider. Accepted providers: "dribbble", "google", "facebook", "twitter", "apple", "github". */
  socialLogin: Authentication;
  /** Checks if the user completed the token login authentication. Will return the accessToken if completed, otherwise it will return an error. */
  tokenLogin: Authentication;
  /** Update the current workspace of the logged in user. If no workspace exists, a new workspace setting will be created. */
  updateCurrentWorkspace: Workspace;
  /** Updates the current user's profile data. */
  updateUser: User;
  /** Update the current onboarding of the logged in user */
  updateUserOnboardingStatus: OnboardingObject;
  uploadDotLottieWorkflowFile: FileVariant;
  /** Starts the upload profile picture process. Returns a signed URL that should be used in a PUT request with the file content. The `processUserProfilePhotoUpload` mutation must be called right after the file upload has finished. */
  uploadProfilePhoto: UserProfilePhotoUpload;
  /** Add animations to a workspace collection. */
  workspaceCollectionAddFiles: WorkspaceCollection;
  /** Create a new workspace collection. */
  workspaceCollectionCreate: WorkspaceCollection;
  /** Delete an existing workspace collection by its id. */
  workspaceCollectionDelete: Scalars['Boolean'];
  /** Add animations to a workspace collection. */
  workspaceCollectionRemoveFiles: WorkspaceCollection;
  /** Modify an existing collection by its id. */
  workspaceCollectionUpdate: WorkspaceCollection;
  workspaceColorPaletteCreate: WorkspaceColorPalette;
  workspaceColorPaletteDelete: Scalars['Boolean'];
  workspaceColorPaletteUpdate: WorkspaceColorPalette;
  /** Create a new workspace. */
  workspaceCreate: Workspace;
  /** Delete a workspace. This will delete all of the workspace's projects, invitations, and subscriptions. */
  workspaceDelete: Workspace;
  workspaceIndexRecords: Scalars['Boolean'];
  /** Invite user to workspace if the invitation code is valid */
  workspaceInvitationLinkAccept: WorkspaceMember;
  /** Regenerate workspace invitation link */
  workspaceInvitationLinkRegenerate: WorkspaceInvitationLink;
  /** Update workspace invitation link. If not workspace invitation link exists, a new link will be created */
  workspaceInvitationLinkUpdate: WorkspaceInvitationLink;
  /** Leave a workspace. This will remove all your permissions from the workspace and remove any associated settings. */
  workspaceLeave: Workspace;
  /** Accept invitation to a workspace using the invitation code. */
  workspaceMemberCompleteOnboarding: WorkspaceMember;
  /** Remove workspace member */
  workspaceMemberDelete: Scalars['Boolean'];
  /** Accept invitation to a workspace using the invitation code. */
  workspaceMemberInvitationAccept: WorkspaceMember;
  /** Reinvite user to a workspace. */
  workspaceMemberResendInvite: WorkspaceMember;
  /** Change access of the user to a workspace. */
  workspaceMemberSetPermission: WorkspaceMember;
  /** Invite a multiple users to a workspace. */
  workspaceMembersSendInvites: Array<WorkspaceMember>;
  /** Cancels current active subscription of the given workspace. */
  workspaceSubscriptionCancel: Scalars['Boolean'];
  /** Cancel trial plan of the given workspace. */
  workspaceSubscriptionCancelTrial: Scalars['Boolean'];
  /** Marks the subscription active if it is marked for cancellation. */
  workspaceSubscriptionContinue: Scalars['Boolean'];
  /** Create a stripe checkout session to a workspace and returns the session. Evaluates the next upgradable plan itself. */
  workspaceSubscriptionCreateCheckoutSession: Scalars['String'];
  /** Upgrades current active subscription of the given workspace to the next one. */
  workspaceSubscriptionUpgrade: Scalars['Boolean'];
  /** Transfer ownership to new user in the workspace */
  workspaceTransferOwnership: Scalars['Boolean'];
  /** Update workspace settings by ID */
  workspaceUpdate: Workspace;
};

export type MutationAccountDeleteRequestCreateArgs = {
  requestType: AccountDeleteRequestType;
};

export type MutationAccountPaymentProviderCustomerPortalSessionCreateArgs = {
  workspaceId?: InputMaybe<Scalars['ID']>;
};

export type MutationAccountUpdateArgs = {
  id: Scalars['ID'];
  input: AccountInput;
};

export type MutationCommentCreateArgs = {
  input: CommentCreateInput;
};

export type MutationCommentDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationCommentEditArgs = {
  body: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationCommentPublishArgs = {
  id: Scalars['ID'];
};

export type MutationCommentReactArgs = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type MutationCommentReplyCreateArgs = {
  body: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationCommentResolveArgs = {
  id: Scalars['ID'];
};

export type MutationCommentUnpublishArgs = {
  id: Scalars['ID'];
};

export type MutationCommentUnreactArgs = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type MutationCommentUnresolveArgs = {
  id: Scalars['ID'];
};

export type MutationConfirmEmailChangeArgs = {
  emailChangeToken: Scalars['String'];
};

export type MutationConfirmResetPasswordArgs = {
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type MutationCreateLoginTokenArgs = {
  appKey?: InputMaybe<Scalars['String']>;
};

export type MutationExportJsonFileArgs = {
  fileVersionId: Scalars['ID'];
};

export type MutationFileCreateArgs = {
  input: FileCreateInput;
};

export type MutationFileCreateFallbackArgs = {
  input: FileCreateFallbackInput;
};

export type MutationFileDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationFileDescriptionUpdateArgs = {
  id: Scalars['ID'];
  input: FileDescriptionUpdateInput;
};

export type MutationFileDuplicateArgs = {
  id: Scalars['ID'];
};

export type MutationFileNotificationsSubscribeArgs = {
  fileId: Scalars['ID'];
};

export type MutationFileNotificationsUnsubscribeArgs = {
  fileId: Scalars['ID'];
};

export type MutationFilePreviewCreateArgs = {
  fileVersionId: Scalars['ID'];
  input: FileVariantInput;
};

export type MutationFileRenameArgs = {
  id: Scalars['ID'];
  input: FileRenameInput;
};

export type MutationFileUpdateArgs = {
  id: Scalars['String'];
  input: UpdateFileInput;
};

export type MutationFileUpdateBackgroundColorArgs = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MutationFileUpdateStatusArgs = {
  id: Scalars['ID'];
  status?: InputMaybe<FileStatus>;
};

export type MutationFileUploadRequestCreateArgs = {
  input?: InputMaybe<CreateUploadRequestInput>;
};

export type MutationFileVariantDeleteArgs = {
  fileVariantId: Scalars['ID'];
  fileVersionId: Scalars['ID'];
};

export type MutationFileVariantFallbackArgs = {
  fileVariationId: Scalars['ID'];
};

export type MutationFileVariantUpdateArgs = {
  input: FileVariantUpdateInput;
};

export type MutationFileVersionCreateArgs = {
  input: FileVersionCreateInput;
};

export type MutationFileVersionDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationFileVersionRestoreArgs = {
  fileId: Scalars['ID'];
  fileVersionId: Scalars['ID'];
};

export type MutationFileVersionTagsUpdateArgs = {
  fileKey: Scalars['ID'];
};

export type MutationFilesDeleteArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationFilesMoveToFolderArgs = {
  fileIds: Array<Scalars['ID']>;
  folderId: Scalars['ID'];
};

export type MutationFilesMoveToProjectArgs = {
  fileIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
};

export type MutationFolderCreateArgs = {
  input: CreateFolderInput;
};

export type MutationFolderDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationFolderRenameArgs = {
  id: Scalars['ID'];
  input: FolderRenameInput;
};

export type MutationGetIconUploadPresignedDataArgs = {
  filename: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type MutationHireRequestCreateArgs = {
  input: HireRequestInput;
};

export type MutationHitCountEventCreateArgs = {
  input: HitCountEventInput;
  resourceId: Scalars['ID'];
};

export type MutationIsUsernameAvailableArgs = {
  username: Scalars['String'];
};

export type MutationLogoutArgs = {
  web?: InputMaybe<Scalars['Boolean']>;
};

export type MutationMarkLoginTokenValidArgs = {
  token: Scalars['String'];
};

export type MutationOptimizeWorkflowFileArgs = {
  fileVersionId: Scalars['ID'];
};

export type MutationPasswordChangeArgs = {
  newPassword: Scalars['String'];
};

export type MutationPasswordLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  web?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPlaySegmentRemoveArgs = {
  fileVersionId: Scalars['ID'];
  playSegmentId: Scalars['ID'];
};

export type MutationPlaySegmentUpsertArgs = {
  fileVersionId: Scalars['ID'];
  input: PlaySegmentInput;
};

export type MutationPlaySegmentsClearArgs = {
  fileVersionId: Scalars['ID'];
};

export type MutationPlaySegmentsCopyArgs = {
  fromFileVersionId: Scalars['ID'];
  playSegmentId?: InputMaybe<Scalars['ID']>;
  toFileVersionId: Scalars['ID'];
};

export type MutationPrivateShareAcceptArgs = {
  invitationCode: Scalars['String'];
};

export type MutationPrivateShareInviteUserArgs = {
  input: SharedResourceInput;
};

export type MutationPrivateShareReInviteUserArgs = {
  id: Scalars['ID'];
};

export type MutationPrivateShareSetPermissionArgs = {
  access: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationProcessUserProfilePhotoUploadArgs = {
  filename: Scalars['String'];
  signedUrl: Scalars['String'];
};

export type MutationProjectCreateArgs = {
  input: ProjectCreateInput;
};

export type MutationProjectDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationProjectUpdateArgs = {
  id: Scalars['ID'];
  input: ProjectUpdateInput;
};

export type MutationProjectUpdatePermissionArgs = {
  id: Scalars['ID'];
  isPrivate: Scalars['Boolean'];
};

export type MutationPublicAnimationCollectionAddAnimationArgs = {
  animationId: Scalars['Int'];
  collectionId: Scalars['Int'];
};

export type MutationPublicAnimationCollectionCreateArgs = {
  input: CollectionInput;
};

export type MutationPublicAnimationCollectionDeleteArgs = {
  collectionId: Scalars['Int'];
};

export type MutationPublicAnimationCollectionDeleteAnimationsArgs = {
  animationIds: Array<Scalars['Int']>;
  collectionId: Scalars['Int'];
};

export type MutationPublicAnimationCollectionUpdateArgs = {
  collectionId: Scalars['Int'];
  input: CollectionInput;
};

export type MutationPublicAnimationCreateCommentArgs = {
  animationId: Scalars['Int'];
  input: CommentInput;
};

export type MutationPublicAnimationCreateCommentReplyArgs = {
  commentId: Scalars['Int'];
  content: Scalars['String'];
};

export type MutationPublicAnimationDeleteArgs = {
  id: Scalars['Int'];
};

export type MutationPublicAnimationLikeArgs = {
  id: Scalars['Int'];
};

export type MutationPublicAnimationReportArgs = {
  input: AnimationReportInput;
};

export type MutationPublicAnimationResolveCommentArgs = {
  id: Scalars['Int'];
};

export type MutationPublicAnimationUnlikeArgs = {
  id: Scalars['Int'];
};

export type MutationPublicAssetExtendExpiryArgs = {
  publicAssetId: Scalars['ID'];
};

export type MutationPublicAssetRegenerateArgs = {
  publicAssetId: Scalars['ID'];
};

export type MutationPublicAssetRestoreArgs = {
  input: PublicAssetRestoreInput;
};

export type MutationPublicAssetUpdateArgs = {
  input: PublicAssetUpdateInput;
};

export type MutationPublicShareCreateArgs = {
  access: Scalars['String'];
  resourceId: Scalars['ID'];
  resourceType: PublicShareType;
};

export type MutationPublicShareCreateForFileKeyArgs = {
  fileKey: Scalars['String'];
};

export type MutationRegisterArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  web?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRequestEmailChangeArgs = {
  email: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationSetUserSegmentsArgs = {
  segmentIds: Scalars['String'];
  segmentOtherDescription?: InputMaybe<Scalars['String']>;
};

export type MutationSocialLoginArgs = {
  accessSecret?: InputMaybe<Scalars['String']>;
  accessToken?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  idToken?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  web?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTokenLoginArgs = {
  token: Scalars['String'];
};

export type MutationUpdateCurrentWorkspaceArgs = {
  workspaceId: Scalars['ID'];
};

export type MutationUpdateUserArgs = {
  behanceUsername?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dribbbleUsername?: InputMaybe<Scalars['String']>;
  enableMarketingEmails?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  githubUsername?: InputMaybe<Scalars['String']>;
  instagramUsername?: InputMaybe<Scalars['String']>;
  isHireable?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedinUsername?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  twitterUsername?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateUserOnboardingStatusArgs = {
  input: UpdateOnboardingInput;
};

export type MutationUploadDotLottieWorkflowFileArgs = {
  fileVersionId: Scalars['ID'];
};

export type MutationUploadProfilePhotoArgs = {
  extension: Scalars['String'];
};

export type MutationWorkspaceCollectionAddFilesArgs = {
  fileIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
};

export type MutationWorkspaceCollectionCreateArgs = {
  input: WorkspaceCollectionCreateInput;
};

export type MutationWorkspaceCollectionDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationWorkspaceCollectionRemoveFilesArgs = {
  fileIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
};

export type MutationWorkspaceCollectionUpdateArgs = {
  id: Scalars['ID'];
  input: WorkspaceCollectionUpdateInput;
};

export type MutationWorkspaceColorPaletteCreateArgs = {
  input: CreateWorkspaceColorPaletteInput;
};

export type MutationWorkspaceColorPaletteDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationWorkspaceColorPaletteUpdateArgs = {
  id: Scalars['ID'];
  input: UpdateWorkspaceColorPaletteInput;
};

export type MutationWorkspaceCreateArgs = {
  name: Scalars['String'];
};

export type MutationWorkspaceDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationWorkspaceIndexRecordsArgs = {
  id: Scalars['ID'];
};

export type MutationWorkspaceInvitationLinkAcceptArgs = {
  invitationCode: Scalars['String'];
};

export type MutationWorkspaceInvitationLinkRegenerateArgs = {
  workspaceId: Scalars['ID'];
};

export type MutationWorkspaceInvitationLinkUpdateArgs = {
  canInviteMembers: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  workspaceId: Scalars['ID'];
};

export type MutationWorkspaceLeaveArgs = {
  id: Scalars['ID'];
};

export type MutationWorkspaceMemberCompleteOnboardingArgs = {
  workspaceId: Scalars['ID'];
};

export type MutationWorkspaceMemberDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationWorkspaceMemberInvitationAcceptArgs = {
  invitationCode: Scalars['String'];
};

export type MutationWorkspaceMemberResendInviteArgs = {
  id: Scalars['String'];
};

export type MutationWorkspaceMemberSetPermissionArgs = {
  access: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationWorkspaceMembersSendInvitesArgs = {
  input: WorkspaceMemberSendInviteInput;
  workspaceId: Scalars['ID'];
};

export type MutationWorkspaceSubscriptionCancelArgs = {
  workspaceId: Scalars['String'];
};

export type MutationWorkspaceSubscriptionCancelTrialArgs = {
  workspaceId: Scalars['String'];
};

export type MutationWorkspaceSubscriptionContinueArgs = {
  id: Scalars['String'];
};

export type MutationWorkspaceSubscriptionCreateCheckoutSessionArgs = {
  input: WorkspaceSubscriptionCheckoutSessionInput;
};

export type MutationWorkspaceSubscriptionUpgradeArgs = {
  input: WorkspaceSubscriptionUpgradeInput;
};

export type MutationWorkspaceTransferOwnershipArgs = {
  id: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationWorkspaceUpdateArgs = {
  id: Scalars['ID'];
  input: WorkspaceInput;
};

export type NextBillingObject = {
  __typename?: 'NextBillingObject';
  amount: Scalars['Float'];
  currency: Scalars['String'];
  date: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  link?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  theme?: Maybe<Theme>;
};

export type OnboardingObject = {
  __typename?: 'OnboardingObject';
  animColorPaletteBtnHotspot?: Maybe<Scalars['Boolean']>;
  animCommentBtnHotspot?: Maybe<Scalars['Boolean']>;
  animDescriptionSequence?: Maybe<Scalars['Boolean']>;
  animPanelBtnHotspot?: Maybe<Scalars['Boolean']>;
  animSegmentBtnHotspot?: Maybe<Scalars['Boolean']>;
  animTitleHotspot?: Maybe<Scalars['Boolean']>;
  animTopbarHotspot?: Maybe<Scalars['Boolean']>;
  animVersionBtnHotspot?: Maybe<Scalars['Boolean']>;
  dashboardAnimUploadHotspot?: Maybe<Scalars['Boolean']>;
  dashboardCollectionHotspot?: Maybe<Scalars['Boolean']>;
  dashboardCollectionViewSequence?: Maybe<Scalars['Boolean']>;
  dashboardPageHotspot?: Maybe<Scalars['Boolean']>;
  dashboardWelcomeLfModal?: Maybe<Scalars['Boolean']>;
  dashboardWelcomeTeamModal?: Maybe<Scalars['Boolean']>;
  dashboardWelcomeUpgradedModal?: Maybe<Scalars['Boolean']>;
  dashboardWorkspaceCollectionHotspot?: Maybe<Scalars['Boolean']>;
  dashboardWorkspaceHotspot?: Maybe<Scalars['Boolean']>;
  introAnimSequence?: Maybe<Scalars['Boolean']>;
  introDashboardSequence?: Maybe<Scalars['Boolean']>;
};

export type OrganizationObject = {
  __typename?: 'OrganizationObject';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items?. */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items?. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  brand: Scalars['String'];
  expMonth: Scalars['Float'];
  expYear: Scalars['Float'];
  last4: Scalars['String'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  card?: Maybe<PaymentCard>;
  customerId: Scalars['String'];
  id: Scalars['String'];
  type: Scalars['String'];
};

/** Payment service provider */
export enum PaymentProvider {
  Stripe = 'Stripe',
}

export type PlanEntitlement = {
  __typename?: 'PlanEntitlement';
  id: Scalars['ID'];
  max?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type PlanObject = {
  __typename?: 'PlanObject';
  defaultEntitlements?: Maybe<Array<PlanEntitlement>>;
  id: Scalars['ID'];
  isBillable: Scalars['Boolean'];
  name: Scalars['String'];
  planPosition: Scalars['Float'];
  status: Scalars['String'];
  stripeProductId: Scalars['String'];
};

export type PlaySegment = {
  __typename?: 'PlaySegment';
  action: PlaySegmentAction;
  endFrame: Scalars['Float'];
  id: Scalars['String'];
  name: Scalars['String'];
  startFrame: Scalars['Float'];
};

export enum PlaySegmentAction {
  Loop = 'Loop',
  PlayOnce = 'PlayOnce',
}

export type PlaySegmentInput = {
  action: PlaySegmentAction;
  endFrame: Scalars['Float'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startFrame: Scalars['Float'];
};

export type PresignedPost = {
  __typename?: 'PresignedPost';
  /** Form fields used for a presigned s3 post */
  fields: Scalars['JSON'];
  /** Unique key of the file */
  key: Scalars['String'];
  /** Url used for a presigned s3 post */
  url: Scalars['String'];
};

export type Preview = {
  __typename?: 'Preview';
  /** Webp */
  gif?: Maybe<PreviewSize>;
  /** Mp4 */
  mp4?: Maybe<PreviewSize>;
  /** Png */
  webp?: Maybe<PreviewSize>;
};

export type PreviewContent = {
  __typename?: 'PreviewContent';
  /** Content Type */
  contentType?: Maybe<Scalars['String']>;
  /** Url of thumbnail */
  url?: Maybe<Scalars['String']>;
  /** Version Id */
  versionId?: Maybe<Scalars['String']>;
};

export enum PreviewGenerationStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
}

export type PreviewSize = {
  __typename?: 'PreviewSize';
  /** Size large */
  large?: Maybe<PreviewContent>;
  /** Size medium  */
  medium?: Maybe<PreviewContent>;
  /** Size small */
  small?: Maybe<PreviewContent>;
};

export type PrivateShare = {
  __typename?: 'PrivateShare';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  access: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  invitationCode: Scalars['String'];
  invitedBy: Scalars['ID'];
  isCreator: Scalars['Boolean'];
  /** Checks if the user is not a workspace member */
  isGuest?: Maybe<Scalars['Boolean']>;
  lastSentAt?: Maybe<Scalars['DateTime']>;
  recipientEmail?: Maybe<Scalars['String']>;
  resource: PrivateShareResource;
  resourceId: Scalars['ID'];
  resourceType: PrivateShareType;
  updatedAt: Scalars['DateTime'];
  /** Recipient user of the invitation */
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type PrivateShareConnection = {
  __typename?: 'PrivateShareConnection';
  /** A list edges. */
  edges: Array<PrivateShareEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type PrivateShareEdge = {
  __typename?: 'PrivateShareEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PrivateShare;
};

export type PrivateShareResource = File | Project | WorkspaceCollection;

export enum PrivateShareType {
  Collection = 'COLLECTION',
  File = 'FILE',
  Project = 'PROJECT',
}

export type Project = {
  __typename?: 'Project';
  /**
   * The animation thumbnail Urls of the last 3 files in the project
   * @deprecated Use `featuredFileKeys` instead
   */
  animationThumbnailUrls?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  /** The file keys for last 3 files in the project */
  featuredFileKeys: Array<Scalars['String']>;
  features: Array<FeatureObject>;
  filesCount: Scalars['Float'];
  id: Scalars['ID'];
  isPrivate: Scalars['Boolean'];
  isSystem: Scalars['Boolean'];
  /** Get the permission scopes for project for the current user */
  projectPermissionScopes: Array<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** Workspace the project belongs to */
  workspace: Workspace;
  workspaceId: Scalars['String'];
  /** Total number of workspace members */
  workspaceTeamMembersCount: Scalars['Float'];
};

export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  /** A list edges. */
  edges: Array<ProjectEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type ProjectCreateInput = {
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  shareToken?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Project;
};

export type ProjectFile = File | Folder;

export type ProjectFileConnection = {
  __typename?: 'ProjectFileConnection';
  /** A list edges. */
  edges: Array<ProjectFileEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type ProjectFileEdge = {
  __typename?: 'ProjectFileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProjectFile;
};

export type ProjectUpdateInput = {
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type PublicAnimation = {
  __typename?: 'PublicAnimation';
  bgColor?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<PublicComment>>;
  commentsCount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  downloads?: Maybe<Scalars['Float']>;
  gifFileSize?: Maybe<Scalars['String']>;
  gifUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageFileSize?: Maybe<Scalars['Int']>;
  imageFrame?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isLiked: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  lottieFileSize?: Maybe<Scalars['Int']>;
  lottieFileType?: Maybe<Scalars['String']>;
  lottieUrl?: Maybe<Scalars['String']>;
  lottieVersion?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  sourceFileName?: Maybe<Scalars['String']>;
  sourceFileSize?: Maybe<Scalars['Int']>;
  sourceFileType?: Maybe<Scalars['String']>;
  sourceFileUrl?: Maybe<Scalars['String']>;
  sourceName?: Maybe<Scalars['String']>;
  sourceVersion?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  videoFileSize?: Maybe<Scalars['Int']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type PublicAnimationConnection = {
  __typename?: 'PublicAnimationConnection';
  /** A list edges. */
  edges: Array<PublicAnimationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type PublicAnimationDeleteResponse = {
  __typename?: 'PublicAnimationDeleteResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type PublicAnimationEdge = {
  __typename?: 'PublicAnimationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PublicAnimation;
};

/** Public animation status filter types */
export enum PublicAnimationStatusFilterType {
  All = 'ALL',
  Pending = 'PENDING',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED',
}

export type PublicAnimationStatusFilterTypeInput = {
  statusFilterType?: InputMaybe<PublicAnimationStatusFilterType>;
};

export type PublicAsset = {
  __typename?: 'PublicAsset';
  backgroundColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  expireAt?: Maybe<Scalars['DateTime']>;
  fileKey: Scalars['String'];
  fileName: Scalars['String'];
  fileSize: Scalars['Float'];
  fileVersionId: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isOptimized: Scalars['Boolean'];
  metadataVersionId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workflowFileId: Scalars['String'];
  workflowFileVersionId: Scalars['String'];
};

export type PublicAssetRestoreInput = {
  fileVersionId: Scalars['ID'];
};

export type PublicAssetUpdateInput = {
  fileId: Scalars['ID'];
  isActive: Scalars['Boolean'];
};

export type PublicCollection = {
  __typename?: 'PublicCollection';
  animationType?: Maybe<CollectionAnimationType>;
  animationsCount?: Maybe<Scalars['Int']>;
  collectionAnimationPreviews?: Maybe<Array<CollectionAnimationPreview>>;
  createdBy?: Maybe<User>;
  createdByUserId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  type: CollectionType;
  url: Scalars['String'];
};

export type PublicCollectionConnection = {
  __typename?: 'PublicCollectionConnection';
  /** A list edges. */
  edges: Array<PublicCollectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type PublicCollectionEdge = {
  __typename?: 'PublicCollectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PublicCollection;
};

export type PublicComment = {
  __typename?: 'PublicComment';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  frame?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  isResolved: Scalars['Boolean'];
  marker?: Maybe<Scalars['Point']>;
  parentId?: Maybe<Scalars['Int']>;
  replies?: Maybe<Array<PublicComment>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type PublicShare = {
  __typename?: 'PublicShare';
  /** Get the access type of the resource */
  accessLevels?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  expireAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  resource: PublicShareResource;
  resourceId: Scalars['ID'];
  resourceType: PublicShareType;
  shareCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PublicShareResource = File | WorkspaceCollection;

export enum PublicShareType {
  Collection = 'COLLECTION',
  File = 'FILE',
}

export type Query = {
  __typename?: 'Query';
  authVersion: Scalars['String'];
  /** Look up a billing package by its id. */
  billingPackage: BillingPackage;
  /** Get the default user price for team billing packages. */
  billingPackageDefaultPerUserPrice: BillingPackagePrice;
  /** Look up a billing package price by its id. */
  billingPackagePrice: BillingPackagePrice;
  /** Billing package connection. */
  billingPackages: BillingPackageConnection;
  blogs: BlogConnection;
  colorPalettes: ColorPaletteConnection;
  /** Get users with view access to an Animation */
  commentMentionableUsers: Array<CommentUser>;
  /** Get the replies for a comment */
  commentReplies: CommentConnection;
  /** Get the comments for an entity */
  comments: CommentConnection;
  /** Check if there is a current workspace and return it */
  currentWorkspace?: Maybe<Workspace>;
  featuredAnimators: AnimatorConnection;
  featuredPublicAnimations: PublicAnimationConnection;
  /** Look up an animation by its id. */
  file: File;
  /** Count the number of animations in a workspace */
  fileCountByWorkspaceId: Scalars['Int'];
  /** Fetch file handback by id */
  fileHandback: FileHandback;
  /** Get all the users who are subscribed to this animation. */
  fileNotificationSubscribers: UserNotificationSubscriptionConnection;
  fileVariants: Array<FileVariant>;
  /** Fetch file version by id */
  fileVersion: FileVersion;
  /** Get all file versions of a file using file ID */
  fileVersions: FileVersionConnection;
  /** Get the last modified files for the user. */
  filesRecentlyModified: Array<File>;
  /** Look up a folder by its id. */
  folder: Folder;
  /** Look up folder subfolders and animations by folder-id. */
  folderFiles: FileConnection;
  /** Fetch folders under a specific project. */
  foldersByProjectId: Array<Folder>;
  /** Get the current workspace of the logged in user */
  getCurrentWorkspace: WorkflowSetting;
  /**
   * Get account details of the loggedin user
   * @deprecated Use `viewer` query instead
   */
  getMyAccountInformation: UserObject;
  /** Fetch Notification Feed token from GetStream API */
  getToken: Scalars['String'];
  /** Get the current onboarding of the logged in user */
  getUserOnboardingStatus: OnboardingObject;
  /** Determine if it's the user's first attempt at login, and if so, set-up the workspace. */
  hasAccessToAWorkspace: Scalars['Boolean'];
  /** Get the list of invoices for the workspace. */
  invoices: Array<Invoice>;
  /** Checks if an email address is available for use. */
  isEmailAvailable: Scalars['Boolean'];
  isPaidUser: Scalars['Boolean'];
  /** Checks if a username is available for use. */
  isUsernameAvailable: Scalars['Boolean'];
  jwt?: Maybe<Scalars['String']>;
  legacyVersion: Scalars['String'];
  notifications?: Maybe<Array<Notification>>;
  popularPublicAnimations: PublicAnimationConnection;
  privateShareMembers: Array<PrivateShare>;
  /** Get all shared resources for the logged in user */
  privateShares?: Maybe<PrivateShareConnection>;
  /** Look up a project by its id. */
  project: Project;
  /** Look up project folders and animations by project-id. */
  projectFiles: ProjectFileConnection;
  publicAnimation?: Maybe<PublicAnimation>;
  publicAnimationCollection: PublicCollection;
  publicAnimationCollections: PublicCollectionConnection;
  publicAnimationCollectionsByUser?: Maybe<PublicCollectionConnection>;
  publicAnimationsByUser?: Maybe<PublicAnimationConnection>;
  /** Get all public assets for a given workflow file version */
  publicAssets: Array<PublicAsset>;
  /** Animations of a collection */
  publicCollectionAnimations: PublicAnimationConnection;
  /** Get a public shared resource */
  publicShare?: Maybe<PublicShare>;
  publicShareByCode: PublicShare;
  recentPublicAnimations: PublicAnimationConnection;
  /**
   * Search folders inside a specific workspace by it's title.
   * @deprecated Use `searchFolders` instead.
   */
  searchFoldersInWorkspace: Array<Folder>;
  /**
   * Search projects inside a specific workspace by it's title.
   * @deprecated Use `searchProjects` instead
   */
  searchProjectsInWorkspace: Array<Project>;
  searchPublicAnimations: PublicAnimationConnection;
  searchWorkspace: SearchWorkspaceResponse;
  services: Array<Maybe<Service>>;
  softwareUpdates: SoftwareUpdate;
  /** Fetch suggested invitees for the authenticated user. */
  suggestedInvitees: SuggestedInviteeConnection;
  trendingSearches: Array<TrendingItem>;
  /** Get a user's public information based on ID. */
  user?: Maybe<User>;
  userAchievements?: Maybe<UserAchievementConnection>;
  /** Returns a list of user segments available for choosing. */
  userSegments?: Maybe<UserSegmentListing>;
  userStats?: Maybe<UserStats>;
  /** Batch get users' public information based on ID. */
  users: Array<Maybe<User>>;
  /** Information about who owns the current session. */
  viewer: User;
  viewerPublicAnimationDownloads: PublicAnimationConnection;
  viewerPublicAnimationLikes: PublicAnimationConnection;
  workflowVersion: Scalars['String'];
  /** Look up a workspace by its id. */
  workspace?: Maybe<Workspace>;
  /** Look up a workflow collection by its id. */
  workspaceCollection: WorkspaceCollection;
  /** Fetch files for a workspace collection. */
  workspaceCollectionFiles: FileConnection;
  /** Fetch workspace collections connection. */
  workspaceCollections: WorkspaceCollectionConnection;
  workspaceColorPalette: WorkspaceColorPalette;
  workspaceColorPalettes: WorkspaceColorPaletteConnection;
  /** Fetch draft project for the given workspace. */
  workspaceDraftProject: Project;
  /** Get workspace invitation link */
  workspaceInvitationLink?: Maybe<WorkspaceInvitationLink>;
  /** Look up invitations by workspace id. */
  workspaceMembers: WorkspaceMemberConnection;
  /** Fetch projects under a specific workspace. */
  workspaceProjects: ProjectConnection;
  /** Checks if the given checkout session was completed */
  workspaceSubscriptionCheckoutCompleted: WorkspaceSubscriptionCheckoutSessionMetadata;
  /** Get the price of per user for the workspace */
  workspaceUserPrice: WorkspaceMemberPrice;
  /** Fetch all the workspaces that has access to the logged in user */
  workspaces?: Maybe<Array<Workspace>>;
};

export type QueryBillingPackageArgs = {
  id: Scalars['ID'];
};

export type QueryBillingPackagePriceArgs = {
  id: Scalars['ID'];
};

export type QueryBillingPackagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryBlogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryColorPalettesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryCommentMentionableUsersArgs = {
  id: Scalars['ID'];
};

export type QueryCommentRepliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  type: CommentableEntityType;
};

export type QueryFeaturedAnimatorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryFeaturedPublicAnimationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryFileArgs = {
  id: Scalars['ID'];
};

export type QueryFileCountByWorkspaceIdArgs = {
  workspaceId: Scalars['ID'];
};

export type QueryFileHandbackArgs = {
  id: Scalars['ID'];
};

export type QueryFileNotificationSubscribersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryFileVariantsArgs = {
  fileId: Scalars['ID'];
  fileVersionId?: InputMaybe<Scalars['ID']>;
};

export type QueryFileVersionArgs = {
  id: Scalars['ID'];
};

export type QueryFileVersionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  fileId: Scalars['ID'];
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryFolderArgs = {
  id: Scalars['ID'];
};

export type QueryFolderFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  status?: InputMaybe<Scalars['String']>;
};

export type QueryFoldersByProjectIdArgs = {
  projectId: Scalars['String'];
};

export type QueryInvoicesArgs = {
  workspaceId: Scalars['ID'];
};

export type QueryIsEmailAvailableArgs = {
  email: Scalars['String'];
};

export type QueryIsUsernameAvailableArgs = {
  username: Scalars['String'];
};

export type QueryPopularPublicAnimationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  sort?: InputMaybe<DurationFilterType>;
};

export type QueryPrivateShareMembersArgs = {
  resourceId: Scalars['ID'];
  resourceType: PrivateShareType;
};

export type QueryPrivateSharesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterByType?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryProjectArgs = {
  id: Scalars['ID'];
};

export type QueryProjectFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryPublicAnimationArgs = {
  id: Scalars['Int'];
};

export type QueryPublicAnimationCollectionArgs = {
  id: Scalars['String'];
};

export type QueryPublicAnimationCollectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<CollectionAnimationTypeInput>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryPublicAnimationCollectionsByUserArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryPublicAnimationsByUserArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<PublicAnimationStatusFilterTypeInput>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  userId: Scalars['ID'];
};

export type QueryPublicAssetsArgs = {
  fileId: Scalars['ID'];
};

export type QueryPublicCollectionAnimationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  collectionId: Scalars['Float'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryPublicShareArgs = {
  resourceId: Scalars['ID'];
  resourceType: PublicShareType;
};

export type QueryPublicShareByCodeArgs = {
  shareCode: Scalars['String'];
};

export type QueryRecentPublicAnimationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QuerySearchFoldersInWorkspaceArgs = {
  query: Scalars['String'];
  workspaceIds: Array<Scalars['ID']>;
};

export type QuerySearchProjectsInWorkspaceArgs = {
  query: Scalars['String'];
  workspaceIds: Array<Scalars['ID']>;
};

export type QuerySearchPublicAnimationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  query: Scalars['String'];
  withAep?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySearchWorkspaceArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  fileStatus?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  query?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type QuerySoftwareUpdatesArgs = {
  app: Scalars['String'];
  version?: InputMaybe<Scalars['String']>;
};

export type QuerySuggestedInviteesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryUserArgs = {
  id: Scalars['String'];
};

export type QueryUserAchievementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryUsersArgs = {
  ids: Array<InputMaybe<Scalars['String']>>;
};

export type QueryViewerPublicAnimationDownloadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryViewerPublicAnimationLikesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryWorkspaceArgs = {
  id: Scalars['ID'];
};

export type QueryWorkspaceCollectionArgs = {
  id: Scalars['ID'];
};

export type QueryWorkspaceCollectionFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
};

export type QueryWorkspaceCollectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  query?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['ID'];
};

export type QueryWorkspaceColorPaletteArgs = {
  id: Scalars['ID'];
};

export type QueryWorkspaceColorPalettesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  workspaceId: Scalars['ID'];
};

export type QueryWorkspaceDraftProjectArgs = {
  workspaceId: Scalars['ID'];
};

export type QueryWorkspaceInvitationLinkArgs = {
  invitationCode?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['ID']>;
};

export type QueryWorkspaceMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  query?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['ID'];
};

export type QueryWorkspaceProjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['QuerySortOptions']>;
  workspaceId: Scalars['ID'];
};

export type QueryWorkspaceSubscriptionCheckoutCompletedArgs = {
  id: Scalars['ID'];
};

export type QueryWorkspaceUserPriceArgs = {
  workspaceId: Scalars['String'];
};

export type RatePlanObject = {
  __typename?: 'RatePlanObject';
  billingCycle: Scalars['String'];
  currency: Scalars['String'];
  endsAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  startsAt: Scalars['DateTime'];
  type: Scalars['String'];
};

export type SearchWorkspaceResponse = {
  __typename?: 'SearchWorkspaceResponse';
  collections: WorkspaceCollectionConnection;
  files: FileConnection;
  folders: FolderConnection;
  projects: ProjectConnection;
};

export type Service = {
  __typename?: 'Service';
  name: Scalars['String'];
  /**  service version in deployment  */
  version?: Maybe<Scalars['String']>;
  /**  service version available in gateway  */
  versionInGateway?: Maybe<Scalars['String']>;
};

export type SharedResourceInput = {
  recipients: Array<SharedResourceInvitationRecipient>;
  resourceId: Scalars['ID'];
  resourceType: PrivateShareType;
};

export type SharedResourceInvitationRecipient = {
  access: Scalars['String'];
  recipientEmail?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SignedUrl = {
  __typename?: 'SignedUrl';
  /** Signed url expiry time */
  expires: Scalars['Float'];
  /** Cloudfront signed url */
  signedUrl: Scalars['String'];
};

export type SoftwareUpdate = {
  __typename?: 'SoftwareUpdate';
  autoUpdate: Scalars['Boolean'];
  changelog?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  forceUpdate: Scalars['Boolean'];
  infoUrl?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Subscribe to File Update events */
  fileUpdate: FileObject;
  /** Subscribe to File Variation Update events */
  fileVariationUpdate: FileVariation;
};

export type SubscriptionFileUpdateArgs = {
  key: Scalars['String'];
};

export type SubscriptionFileVariationUpdateArgs = {
  key: Scalars['String'];
};

export type SuggestedInvitee = {
  __typename?: 'SuggestedInvitee';
  /** Avatar url fetched from auth service. */
  avatarUrl: Scalars['String'];
  id: Scalars['ID'];
  userEmail: Scalars['String'];
  userName: Scalars['String'];
  viewCount: Scalars['Float'];
  viewerEmail: Scalars['String'];
  viewerName: Scalars['String'];
};

export type SuggestedInviteeConnection = {
  __typename?: 'SuggestedInviteeConnection';
  /** A list edges. */
  edges: Array<SuggestedInviteeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type SuggestedInviteeEdge = {
  __typename?: 'SuggestedInviteeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SuggestedInvitee;
};

export type Theme = {
  __typename?: 'Theme';
  dark?: Maybe<ThemeColor>;
  light?: Maybe<ThemeColor>;
};

export type ThemeColor = {
  __typename?: 'ThemeColor';
  bgColor?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type Thumbnail = {
  __typename?: 'Thumbnail';
  /** Png */
  png?: Maybe<ThumbnailSize>;
  /** Webp */
  webp?: Maybe<ThumbnailSize>;
};

export type ThumbnailContent = {
  __typename?: 'ThumbnailContent';
  /** Content Type */
  contentType?: Maybe<Scalars['String']>;
  /** Version Id */
  s3VersionId?: Maybe<Scalars['String']>;
  /** Url of thumbnail */
  url?: Maybe<Scalars['String']>;
};

export type ThumbnailSize = {
  __typename?: 'ThumbnailSize';
  /** Size large */
  large?: Maybe<ThumbnailContent>;
  /** Size medium  */
  medium?: Maybe<ThumbnailContent>;
  /** Size small */
  small?: Maybe<ThumbnailContent>;
};

export type TrendingItem = {
  __typename?: 'TrendingItem';
  link: Scalars['String'];
  rank: Scalars['Float'];
  title: Scalars['String'];
};

export type UpdateFileInput = {
  description?: InputMaybe<Scalars['String']>;
  folderId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  sourceFileKey?: InputMaybe<Scalars['String']>;
  sourceFileType?: InputMaybe<Scalars['String']>;
  sourceFilename?: InputMaybe<Scalars['String']>;
};

export type UpdateOnboardingInput = {
  animColorPaletteBtnHotspot?: InputMaybe<Scalars['Boolean']>;
  animCommentBtnHotspot?: InputMaybe<Scalars['Boolean']>;
  animDescriptionSequence?: InputMaybe<Scalars['Boolean']>;
  animPanelBtnHotspot?: InputMaybe<Scalars['Boolean']>;
  animSegmentBtnHotspot?: InputMaybe<Scalars['Boolean']>;
  animTitleHotspot?: InputMaybe<Scalars['Boolean']>;
  animTopbarHotspot?: InputMaybe<Scalars['Boolean']>;
  animVersionBtnHotspot?: InputMaybe<Scalars['Boolean']>;
  dashboardAnimUploadHotspot?: InputMaybe<Scalars['Boolean']>;
  dashboardCollectionHotspot?: InputMaybe<Scalars['Boolean']>;
  dashboardCollectionViewSequence?: InputMaybe<Scalars['Boolean']>;
  dashboardPageHotspot?: InputMaybe<Scalars['Boolean']>;
  dashboardWelcomeLfModal?: InputMaybe<Scalars['Boolean']>;
  dashboardWelcomeTeamModal?: InputMaybe<Scalars['Boolean']>;
  dashboardWelcomeUpgradedModal?: InputMaybe<Scalars['Boolean']>;
  dashboardWorkspaceCollectionHotspot?: InputMaybe<Scalars['Boolean']>;
  dashboardWorkspaceHotspot?: InputMaybe<Scalars['Boolean']>;
  introAnimSequence?: InputMaybe<Scalars['Boolean']>;
  introDashboardSequence?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateWorkspaceColorPaletteInput = {
  colors: Array<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  achievements?: Maybe<UserAchievementConnection>;
  /** The date/time when the user authenticated the current session. */
  authenticatedAt: Scalars['DateTime'];
  /** The user's profile picture. */
  avatarUrl: Scalars['String'];
  /** The user's Behance username. */
  behanceUsername?: Maybe<Scalars['String']>;
  /** The user's short Bio. */
  bio?: Maybe<Scalars['String']>;
  /** The user's City of residence. */
  city?: Maybe<Scalars['String']>;
  /** The user's Country of residence. */
  country?: Maybe<Scalars['String']>;
  /** The date/time of account creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The user's Dribbble username. */
  dribbbleUsername?: Maybe<Scalars['String']>;
  /** The user's email address. */
  email?: Maybe<Scalars['String']>;
  /** True if the user's current email address has been verified. */
  emailVerified?: Maybe<Scalars['Boolean']>;
  /** True if the user has agreed to receive marketing emails. */
  enableMarketingEmails?: Maybe<Scalars['Boolean']>;
  /** The user's first name. */
  firstName: Scalars['String'];
  /** The user's Github username. */
  githubUsername?: Maybe<Scalars['String']>;
  /** The user's LottieFiles account's unique ID. */
  id: Scalars['String'];
  /** The user's Instagram username. */
  instagramUsername?: Maybe<Scalars['String']>;
  /** True if the user is available for work. */
  isHireable: Scalars['Boolean'];
  /** @deprecated Legacy field, will always return false. */
  isPro: Scalars['Boolean'];
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** For use in the internal migration process. It will contain the ID of the user in the legacy WEB DB if the user is migrated. Otherwise, it will return an INT hashed from the user's Kratos ID. */
  legacyWebUserId: Scalars['Int'];
  /** The user's Linkedin username. */
  linkedinUsername?: Maybe<Scalars['String']>;
  /** @deprecated Use `city` instead. */
  location?: Maybe<Scalars['String']>;
  /** @deprecated Use `firstName` instead. */
  name: Scalars['String'];
  publicAnimations?: Maybe<PublicAnimationConnection>;
  stats?: Maybe<UserStats>;
  /** The user's Twitter username. */
  twitterUsername?: Maybe<Scalars['String']>;
  /** If the user is ongoing an email change process, this is the new email that the user is changing to. Otherwise it will be `null`. */
  unconfirmedNewEmail?: Maybe<Scalars['String']>;
  /** The date/time of last account update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `username` instead. */
  url: Scalars['String'];
  /** The segments this user belongs to. */
  userSegments?: Maybe<Array<UserSegment>>;
  /** The user's unique username. */
  username: Scalars['String'];
  /** The user's personal website. */
  website?: Maybe<Scalars['String']>;
};

export type UserAchievementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserPublicAnimationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<PublicAnimationStatusFilterTypeInput>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserAchievement = {
  __typename?: 'UserAchievement';
  completed: Scalars['Boolean'];
  count: Scalars['Int'];
  goal?: Maybe<Scalars['Int']>;
  progressMessage: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type UserAchievementConnection = {
  __typename?: 'UserAchievementConnection';
  /** A list edges. */
  edges: Array<UserAchievementEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type UserAchievementEdge = {
  __typename?: 'UserAchievementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: UserAchievement;
};

export type UserNotificationSubscription = {
  __typename?: 'UserNotificationSubscription';
  animationFeedGroup: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fileId: Scalars['String'];
  id: Scalars['ID'];
  isSubscribed: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  user: UserObject;
  userId: Scalars['String'];
};

export type UserNotificationSubscriptionConnection = {
  __typename?: 'UserNotificationSubscriptionConnection';
  /** A list edges. */
  edges: Array<UserNotificationSubscriptionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type UserNotificationSubscriptionEdge = {
  __typename?: 'UserNotificationSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: UserNotificationSubscription;
};

export type UserObject = {
  __typename?: 'UserObject';
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UserProfilePhotoUpload = {
  __typename?: 'UserProfilePhotoUpload';
  filename: Scalars['String'];
  signedUrl: Scalars['String'];
  status: Scalars['String'];
};

export type UserResourcePermission = {
  __typename?: 'UserResourcePermission';
  access: Scalars['String'];
  id: Scalars['ID'];
  resourceId: Scalars['ID'];
  resourceType: Scalars['String'];
  userId: Scalars['String'];
};

export type UserSegment = {
  __typename?: 'UserSegment';
  description: Scalars['String'];
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type UserSegmentListing = {
  __typename?: 'UserSegmentListing';
  segments?: Maybe<Array<UserSegment>>;
};

export type UserStatGraphData = {
  __typename?: 'UserStatGraphData';
  value: Scalars['Int'];
};

export type UserStats = {
  __typename?: 'UserStats';
  downloadGraph: Array<UserStatGraphData>;
  downloads: Scalars['Int'];
  downloadsLastMonth: Scalars['Int'];
  likeGraph: Array<UserStatGraphData>;
  likes: Scalars['Int'];
  likesLastMonth: Scalars['Int'];
  profileGraph: Array<UserStatGraphData>;
  profileViews: Scalars['Int'];
  profileViewsLastMonth: Scalars['Int'];
};

export type VariationMetadata = {
  __typename?: 'VariationMetadata';
  /** Preview Background Color */
  bgColor?: Maybe<Scalars['String']>;
  /** Previvew Height */
  height?: Maybe<Scalars['Float']>;
  /** Preview Width */
  width?: Maybe<Scalars['Float']>;
};

export type WorkflowSetting = {
  __typename?: 'WorkflowSetting';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['ID'];
  entityType: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type Workspace = {
  __typename?: 'Workspace';
  account: Account;
  billingAddressLineOne?: Maybe<Scalars['String']>;
  billingAddressLineTwo?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  features: Array<FeatureObject>;
  /** The owner this workspace belongs to. */
  hasOwnership?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  indexed: Scalars['Boolean'];
  invitationLink?: Maybe<WorkspaceInvitationLink>;
  name: Scalars['String'];
  /** The owner this workspace belongs to. */
  owner?: Maybe<UserObject>;
  /** Permission scopes the current user has on this workspace */
  permissionScopes: Array<Scalars['String']>;
  /** Check if the current user is already on boarded or not. If the user is on boarded return null, otherwise return user access level */
  requiresOnboardingAs?: Maybe<Scalars['String']>;
  /** Fetch the active subscription for this workspace */
  subscription?: Maybe<WorkspaceSubscription>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type WorkspaceCollection = {
  __typename?: 'WorkspaceCollection';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']>;
  /** Featured file key for the workspace collection */
  featuredFileKey?: Maybe<Scalars['String']>;
  featuredFileObject?: Maybe<FileObject>;
  files?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isPrivate: Scalars['Boolean'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workspaceId: Scalars['String'];
};

export type WorkspaceCollectionConnection = {
  __typename?: 'WorkspaceCollectionConnection';
  /** A list edges. */
  edges: Array<WorkspaceCollectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type WorkspaceCollectionCreateInput = {
  files: Scalars['JSON'];
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type WorkspaceCollectionEdge = {
  __typename?: 'WorkspaceCollectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: WorkspaceCollection;
};

export type WorkspaceCollectionUpdateInput = {
  files?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type WorkspaceColorPalette = {
  __typename?: 'WorkspaceColorPalette';
  colors: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  workspaceId: Scalars['ID'];
};

export type WorkspaceColorPaletteConnection = {
  __typename?: 'WorkspaceColorPaletteConnection';
  /** A list edges. */
  edges: Array<WorkspaceColorPaletteEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type WorkspaceColorPaletteEdge = {
  __typename?: 'WorkspaceColorPaletteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: WorkspaceColorPalette;
};

export type WorkspaceIconUploadObject = {
  __typename?: 'WorkspaceIconUploadObject';
  fileKey: Scalars['String'];
  preSignedUploadURL: Scalars['String'];
  publicReadURL: Scalars['String'];
};

export type WorkspaceInput = {
  billingAddressLineOne?: InputMaybe<Scalars['String']>;
  billingAddressLineTwo?: InputMaybe<Scalars['String']>;
  billingEmail?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type WorkspaceInvitationLink = {
  __typename?: 'WorkspaceInvitationLink';
  canMembersInvite: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  invitationCode: Scalars['String'];
  isActive: Scalars['Boolean'];
  lastUpdatedById: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** Workspace associated with the invitation link */
  workspace: Workspace;
  workspaceId: Scalars['String'];
};

export type WorkspaceMember = {
  __typename?: 'WorkspaceMember';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  access: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  invitedBy: Scalars['ID'];
  lastSentAt?: Maybe<Scalars['DateTime']>;
  method: Scalars['String'];
  onboardedAt?: Maybe<Scalars['DateTime']>;
  recipientEmail: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** Recipient user of the invitation */
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
  /** The workspace this invitation belongs to. */
  workspace: Workspace;
  workspaceId: Scalars['ID'];
};

export type WorkspaceMemberConnection = {
  __typename?: 'WorkspaceMemberConnection';
  /** A list edges. */
  edges: Array<WorkspaceMemberEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Float'];
};

export type WorkspaceMemberEdge = {
  __typename?: 'WorkspaceMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: WorkspaceMember;
};

export type WorkspaceMemberPrice = {
  __typename?: 'WorkspaceMemberPrice';
  billingCycle: Scalars['String'];
  id: Scalars['ID'];
  price: Scalars['Float'];
};

export type WorkspaceMemberSendInviteInput = {
  recipients: Array<InvitationRecipient>;
};

export type WorkspaceObject = {
  __typename?: 'WorkspaceObject';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type WorkspaceSubscription = {
  __typename?: 'WorkspaceSubscription';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  gracePeriod?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastRevisedAt?: Maybe<Scalars['DateTime']>;
  markedForCancellation: Scalars['Boolean'];
  /** Shows the amount of seats already used by the workspace. */
  nextBilling?: Maybe<NextBillingObject>;
  numberOfSeats: Scalars['Float'];
  /** Shows the amount of seats already used by the workspace. */
  numberOfSeatsUsed?: Maybe<Scalars['Float']>;
  organization: OrganizationObject;
  organizationId: Scalars['ID'];
  parentId?: Maybe<Scalars['String']>;
  plan: PlanObject;
  planId: Scalars['String'];
  ratePlan: RatePlanObject;
  ratePlanId: Scalars['ID'];
  revisionNumber: Scalars['Float'];
  startsAt: Scalars['DateTime'];
  status: Scalars['String'];
  stripeCustomerId: Scalars['String'];
  stripePriceId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workspace: WorkspaceObject;
  workspaceId: Scalars['ID'];
};

export type WorkspaceSubscriptionCheckoutSessionInput = {
  ctaButtonText?: InputMaybe<Scalars['String']>;
  isTrial?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['String']>;
  pricingId?: InputMaybe<Scalars['String']>;
  returnTo?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type WorkspaceSubscriptionCheckoutSessionMetadata = {
  __typename?: 'WorkspaceSubscriptionCheckoutSessionMetadata';
  amountTotal?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  paymentIntent?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  status: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type WorkspaceSubscriptionUpgradeInput = {
  pricingId?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type ViewerQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    firstName: string;
    username: string;
    avatarUrl: string;
  };
};

export const ViewerDocument = gql`
  query Viewer {
    viewer {
      id
      email
      firstName
      username
      avatarUrl
    }
  }
`;

export function useViewerQuery(options?: Omit<Urql.UseQueryArgs<ViewerQueryVariables>, 'query'>) {
  return Urql.useQuery<ViewerQuery, ViewerQueryVariables>({ query: ViewerDocument, ...options });
}
