/**
 * Copyright 2022 Design Barn Inc.
 */

import { Text } from '@lottiefiles/ds-core';
import React from 'react';

import { styled } from '../../config/stitches';

interface LottiePlayerSpeedButtonProps {
  currSpeed: number;
  toggleSpeed: () => void;
}

const SpeedButton = styled('div', {
  height: '20px',
  minWidth: '25px',
  maxWidth: '25px',
  cursor: 'pointer',
  marginLeft: '8px',
  marginRight: '4px',
  backgroundColor: 'black',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '2px',
  userSelect: 'none',
});

export const LottiePlayerSpeedButton: React.FC<LottiePlayerSpeedButtonProps> = ({
  currSpeed,
  toggleSpeed,
}: LottiePlayerSpeedButtonProps) => (
  <>
    <SpeedButton onClick={toggleSpeed}>
      <Text plugins variant="caption1" textStyle={{ margin: '5px', cursor: 'pointer' }}>
        {currSpeed}x
      </Text>
    </SpeedButton>
  </>
);

export const MemoizedLottiePlayerSpeedButton = React.memo(LottiePlayerSpeedButton);
