/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';

export const getLayerDOMElement = (layer: Layer): HTMLElement | null => {
  return document.getElementById(layer.nodeId);
};

export const getLayersDOMElements = (layers: Layer[]): HTMLElement[] => {
  return layers.map(getLayerDOMElement).filter(Boolean) as HTMLElement[];
};
