/**
 * Copyright 2022 Design Barn Inc.
 */

/*
  INFO: ToolkitSandbox component is for testing the toolkit-js.
*/

import { Controls, Player } from '@lottiefiles/react-lottie-player';
import type { DagNode, Scene as SceneType, Layer as LayerType, Event } from '@lottiefiles/toolkit-js';
import { StateHistory, Toolkit, Color } from '@lottiefiles/toolkit-js';
import { LottiePlugin } from '@lottiefiles/toolkit-plugin-lottie';
import React from 'react';
import { useUpdate } from 'react-use';

import { useAnimationURL } from '../../hooks';
import { getColorKey, getColors, updateColor } from '../../toolkit';
import { LagRadar } from '../lag-radar';

function useToolkitNode(toolkit: Toolkit, id: string, events: string[] = ['*']): DagNode | null {
  const rerender = useUpdate();

  const node = toolkit.getNodeById(id);

  const eventsRef = React.useRef(events);

  eventsRef.current = events;

  React.useEffect(() => {
    eventsRef.current.forEach((event) => {
      node?.addEventListener(event, rerender);
    });

    return (): void => {
      eventsRef.current.forEach((event) => {
        node?.removeEventListener(event, rerender);
      });
    };
  }, [node, rerender]);

  return node;
}

export function useScene(toolkit: Toolkit): SceneType | null {
  const rerender = useUpdate();

  const scene = useToolkitNode(toolkit, toolkit.scenes[0]?.nodeId || '') as SceneType | null;

  React.useEffect(() => {
    toolkit.events.addEventListener('addScene', rerender);
    toolkit.events.addEventListener('removeScene', rerender);

    return (): void => {
      toolkit.events.removeEventListener('addScene', rerender);
      toolkit.events.removeEventListener('removeScene', rerender);
    };
  }, [toolkit, rerender]);

  return scene;
}

const Layer: React.FC<{ id: string; toolkit: Toolkit }> = React.memo(({ id, toolkit }) => {
  const layer = useToolkitNode(toolkit, id) as LayerType;

  const deleteLayer = (): void => {
    layer.removeFromGraph();
  };

  const changeVisibility = (): void => {
    layer.setIsHidden(!layer.isHidden);
  };

  return (
    <div style={{ display: 'flex' }}>
      {layer.name}
      <button onClick={deleteLayer}>Delete</button>
      <button onClick={changeVisibility}>{layer.isHidden ? 'Show' : 'Hide'}</button>
    </div>
  );
});

const Scene: React.FC<{ toolkit: Toolkit }> = React.memo(({ toolkit }) => {
  const scene = useScene(toolkit);

  return (
    <div>
      {scene?.state.layers.map((layer) => {
        return (
          <div key={layer.id}>
            <Layer id={layer.id} toolkit={toolkit} />
          </div>
        );
      })}
    </div>
  );
});

const getRandomColor = (): Color => {
  const colors = ['red', 'green', 'blue', 'yellow', 'purple', 'orange', 'pink', 'black', 'white'];

  const randomIndex = Math.floor(Math.random() * colors.length) % colors.length;

  const color = Color.from(colors[randomIndex] || 'black');

  color.setAlpha(Math.random());

  return color;
};

const ColorItem: React.FC<{ color: Color; onClick: React.MouseEventHandler<HTMLDivElement> }> = ({
  color,
  onClick,
}) => (
  <div
    style={{
      display: 'inline-block',
      width: 20,
      height: 20,
      backgroundColor: `rgba(${color.red},${color.green},${color.blue},${color.alpha})`,
      borderRadius: `50%`,
      marginLeft: '5px',
      cursor: 'pointer',
    }}
    onClick={onClick}
  />
);

const Colors: React.FC<{ toolkit: Toolkit }> = ({ toolkit }) => {
  const scene = useScene(toolkit);

  if (!scene) return null;

  const { allColors, uniqueColors } = getColors(scene);

  const changeUniqueColor = (colorKey: string, newColor: Color) => {
    return () => {
      const colorEntries = uniqueColors[colorKey];

      if (colorEntries) {
        toolkit.batch(() => {
          colorEntries.forEach(({ color, keyframe, nodeId }) => {
            updateColor({
              target: toolkit.getNodeById(nodeId),
              keyframe,
              targetColor: color,
              newColor,
            });
          });
        });
      }
    };
  };

  const changeColor = (colorIndex: number, newColor: Color) => () => {
    const colorEntry = allColors[colorIndex];

    if (colorEntry) {
      const { color, keyframe, nodeId } = colorEntry;

      updateColor({
        target: toolkit.getNodeById(nodeId),
        keyframe,
        targetColor: color,
        newColor,
      });
    }
  };

  return (
    <div>
      <div>
        <h1>Unique Colors</h1>
        <div>
          {Object.values(uniqueColors)
            .map((entries) => entries.map(({ color }) => color)[0] as Color)
            .map((color) => (
              <ColorItem
                key={getColorKey(color)}
                color={color}
                onClick={changeUniqueColor(getColorKey(color), getRandomColor())}
              />
            ))}
        </div>
      </div>
      <div>
        <h1>All Color</h1>
        <div>
          {allColors.map(({ color, keyframe, nodeId }, index) => (
            <ColorItem
              key={`${getColorKey(color)}-${nodeId}-${keyframe}-${index}`}
              color={color}
              onClick={changeColor(index, getRandomColor())}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const ToolkitSandbox: React.FC = () => {
  const [lottiePlugin] = React.useState(() => new LottiePlugin());

  const [toolkit] = React.useState(() => {
    const toolkitInstance = new Toolkit({
      enableNodeIds: true,
      immutableState: true,
    });

    toolkitInstance.initializeState();

    toolkitInstance.addPlugin(lottiePlugin);

    return toolkitInstance;
  });

  const [stateHistory] = React.useState<StateHistory>(() => {
    const stateHistoryInstance = new StateHistory(toolkit.events, 100);

    stateHistoryInstance.removeTrackedEvent('addScene', 'removeScene');

    return stateHistoryInstance;
  });

  const [lottie, setLottie] = React.useState<unknown>(null);

  const animationURL = useAnimationURL();

  React.useEffect(() => {
    function importLottie(): void {
      if (!animationURL) return;

      console.log(`importing lottie from ${animationURL}`);

      toolkit.import(lottiePlugin.id, { animation: animationURL, enableNodeIds: true });
    }

    importLottie();
  }, [toolkit, lottiePlugin, animationURL]);

  React.useEffect(() => {
    function exportLottie(event: Event): void {
      if (toolkit.scenes.length === 0) return;

      console.log(`exporting lottie on ${event.type}`);

      toolkit
        .export(lottiePlugin.id, {
          scene: toolkit.scenes[0],
          enableNodeIds: true,
          exportNodeIds: true,
        })
        .then((lottieData) => {
          setLottie(lottieData);
        })
        .catch(console.error);
    }

    toolkit.events.addGlobalListener(exportLottie);

    return () => {
      toolkit.events.removeGlobalListener(exportLottie);
    };
  }, [toolkit, lottiePlugin, setLottie]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            flex: 1,
            flexBasis: '20%',
            flexDirection: 'column',
            border: '1px solid black',
          }}
        >
          <div>
            {stateHistory.canUndo && <button onClick={(): void => stateHistory.undo()}>Undo</button>}
            {stateHistory.canRedo && <button onClick={(): void => stateHistory.redo()}>Redo</button>}
          </div>
          <Scene toolkit={toolkit} />
        </div>
        <div
          style={{
            flex: 1,
            flexBasis: '20%',
            flexDirection: 'column',
            border: '1px solid black',
          }}
        >
          <Colors toolkit={toolkit} />
        </div>
        <div>
          <Player autoplay loop src={lottie as object}>
            <Controls visible buttons={['play', 'repeat', 'frame', 'debug']} />
          </Player>
        </div>
      </div>
      <LagRadar />
    </>
  );
};
