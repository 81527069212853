/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';
import { useRecoilValue } from 'recoil';

import { selectedLayerIdAtom } from '../state';
import { setIsHidden, useToolkitNode, useToolkit } from '../toolkit';
import { removeLayer } from '../utils';

import { useKeypress } from './use-keypress';

export function useLayerShortcuts(): void {
  const selectedLayerId = useRecoilValue(selectedLayerIdAtom);
  const toolkit = useToolkit();

  const layer = useToolkitNode(selectedLayerId) as Layer | null;

  const deleteLayer = (): void => {
    if (layer) {
      removeLayer(layer);
    }
  };

  const changeVisibility = (): void => {
    if (layer) {
      setIsHidden({ layer, isHidden: !layer.isHidden, toolkit });
    }
  };

  useKeypress('mod+shift+h', changeVisibility);
  useKeypress('delete', deleteLayer);

  // Check if macOS
  if (window.navigator.userAgent.includes('Mac')) {
    useKeypress('backspace', deleteLayer);
  }
}
