/**
 * Copyright 2022 Design Barn Inc.
 */

import { Icon, Popover, PopoverContent, PopoverBody, PopoverTrigger } from '@lottiefiles/ds-core';
import type { IconNameType } from '@lottiefiles/ds-core';
import { useRecoilState } from 'recoil';

import { envConfig } from '../../../config/env';
import { keyboardModalOpenAtom } from '../../../state';

interface DropdownMenuProps {
  onOpenChange: (open: boolean) => void;
}

const starIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.70898 11.9283C13.5 9.5 14 8.5 15.3545 3.5C16.5 8.5 17 10 21 11.9283C17 14.5 16.5 15.5 15.3545 20.3566C14 15.5 13.5 14 9.70898 11.9283Z"
      stroke="#20272C"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M5.5 17.5C6.50727 16.7797 6.64012 16.4831 7.00001 15C7.30436 16.4831 7.43721 16.928 8.5 17.5C7.43721 18.2628 7.30436 18.5594 7 20C6.64012 18.5594 6.50727 18.1145 5.5 17.5Z"
      fill="#20272C"
    />
    <path
      d="M3 7.00002C4.34303 6.13567 4.52016 5.77972 5.00001 4C5.40582 5.77972 5.58295 6.31364 7 7.00002C5.58295 7.91539 5.40582 8.27133 5 10C4.52016 8.27133 4.34303 7.73742 3 7.00002Z"
      fill="#20272C"
    />
  </svg>
);

export const DropdownMenu = ({ children, onOpenChange }: React.PropsWithChildren<DropdownMenuProps>): JSX.Element => {
  const [, setKeyboardModalOpen] = useRecoilState(keyboardModalOpenAtom);

  const openFeedbackModal = (): void => {
    if (typeof window.OlvyUtils !== 'undefined' && envConfig.isProd) {
      window.OlvyUtils.showWidget('lottiefiles', 'sleepy_bassi_BqmQx');
    } else if (typeof window.usersnapCXApi !== 'undefined' && !envConfig.isProd) {
      window.usersnapCXApi.open();
    }
  };

  const dropdownItems = [
    {
      icon: 'help-center',
      text: 'Help Center',
      href: 'https://help.lottiefiles.com/hc/en-us/categories/7780917412633-LottieFiles-platform',
    },
    {
      icon: 'comments',
      text: 'Feedback',
      href: '',
      onclick: openFeedbackModal,
    },
    {
      icon: 'mail',
      text: 'Contact us',
      href: 'https://help.lottiefiles.com/hc/en-us/requests/new',
    },
    {
      icon: 'community',
      text: 'Community',
      href: 'https://lottiefiles.com/community',
    },
    {
      icon: 'social-black-twitter',
      text: 'Twitter',
      href: 'https://twitter.com/lottiefiles',
    },
    {
      icon: 'social-black-discord',
      text: 'Discord',
      href: 'https://discord.com/invite/6K3fm7yE9v',
    },
  ];

  // Dropdown List Item Style

  const listItemStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 8px',
    borderRadius: '4px',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
  };

  const openKeyboardModal = (): void => {
    setKeyboardModalOpen(true);
  };

  const handleOpenOlvyModal = (): void => {
    if (typeof window.OlvyUtils !== 'undefined') {
      window.OlvyUtils.showWidget('lottiefiles', 'focused_cannon_vkVDK');
    }
  };

  return (
    <Popover onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent align="end" side="bottom" contentStyle={{ width: 190 }}>
        <PopoverBody containerStyle={{ padding: 0, fontFamily: '$karla' }}>
          <ul className="top-bar-dropdown-list" style={{ listStyle: 'none', padding: 8, margin: 0, fontSize: 14 }}>
            <li style={listItemStyle} onClick={handleOpenOlvyModal}>
              <div style={{ marginRight: '8px' }}>{starIcon}</div>
              What's new
            </li>
            <li style={listItemStyle} onClick={openKeyboardModal}>
              <Icon name="keyboard" style={{ marginRight: '8px' }} size="md" />
              Keyboard shortcuts
            </li>

            {dropdownItems.map((item) => {
              let target;
              let href;

              if (item.href) {
                href = item.href;
                target = '_blank';
              }

              return (
                <li key={item.text} onClick={item.onclick}>
                  <a style={listItemStyle} href={href} target={target}>
                    <Icon name={item.icon as IconNameType} style={{ marginRight: '8px' }} size="md" />
                    {item.text}
                  </a>
                </li>
              );
            })}
          </ul>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
