/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { changeLayerDrawOrderAtom } from '../state';
import { useToolkit } from '../toolkit';

export function useDrawOrderWithChildren(): void {
  const layerIdToChange = useRecoilValue(changeLayerDrawOrderAtom);

  const toolkit = useToolkit();

  React.useEffect(() => {
    const node = toolkit.getNodeById(layerIdToChange.layerId) as Layer | null;

    if (node) node.setDrawOrderWithChildren(layerIdToChange.newDrawOrder);
  }, [layerIdToChange, toolkit]);
}
