/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';
import { useAsyncFn } from 'react-use';
import type { AsyncState } from 'react-use/lib/useAsyncFn';

import { lottiePlugin } from '../plugins';
import { useToolkit } from '../provider';

export function useLottieExporter(): AsyncState<any> {
  const toolkit = useToolkit();

  const [state, runAsync] = useAsyncFn(
    async ({ scene }) => {
      if (!scene) return Promise.resolve();

      return toolkit.export(lottiePlugin.id, {
        scene,
        enableNodeIds: true,
        exportNodeIds: true,
      });
    },
    [toolkit],
  );

  // export on mount
  React.useEffect(() => {
    const scene = toolkit.scenes[0];

    if (!scene) return;

    runAsync({ scene });
  }, [runAsync, toolkit]);

  // export on toolkit state udpates
  React.useEffect(() => {
    function exportLottie(): void {
      const scene = toolkit.scenes[0];

      if (!scene) return;

      runAsync({ scene });
    }

    toolkit.events.addGlobalListener(exportLottie);

    return () => {
      toolkit.events.removeGlobalListener(exportLottie);
    };
  }, [toolkit, runAsync]);

  return state;
}
