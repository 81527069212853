/**
 * Copyright 2022 Design Barn Inc.
 */

import type { RGBAColor } from '@lottiefiles/toolkit-js';
import { Color } from '@lottiefiles/toolkit-js';
import { Root, Trigger, Content } from '@radix-ui/react-popover';
import React from 'react';

import { styled } from '../../../config/stitches';
import { COLOR_ITEM_HEIGHT, COLOR_ITEM_WIDTH } from '../../../utils';
import { ColorPicker } from '../../color-picker';

const Popover = Root;
const PopoverTrigger = Trigger;
const PopoverContent = styled(Content, {
  zIndex: 99,
});

const StyleColorItem = styled(PopoverTrigger, {
  boxSizing: 'border-box',
  width: COLOR_ITEM_WIDTH,
  height: COLOR_ITEM_HEIGHT,
  border: '1px solid #D9E0E6;',
  borderRadius: '100px',
  flex: 'none',
  order: 0,
  flexGrow: 0,
  cursor: 'pointer',

  '&:hover': { borderWidth: '1px', borderStyle: 'solid', borderColor: '$textIconPrimary' },
  "&[data-state='open']": {
    borderColor: '$actionActionFocus',
    borderWidth: '2px',
    boxShadow: 'inset 0 0 0 1px #fff',
  },
});

interface ColorItemProps {
  color: Color;
  onChange?: (color: Color) => void;
  onChangeEnd: (color: Color) => void;
}

export const ColorItem: React.FC<ColorItemProps> = ({ color, onChange, onChangeEnd }) => {
  const [currentColor, setCurrentColor] = React.useState(color);

  React.useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  const onColorPickerOpenChange = React.useCallback(
    (open: boolean): void => {
      if (!open) {
        onChangeEnd(currentColor);
      }
    },
    [onChangeEnd, currentColor],
  );

  const onColorChange = React.useCallback(
    (selectedColor: RGBAColor): void => {
      const newColor = new Color(selectedColor.r, selectedColor.g, selectedColor.b, selectedColor.a);

      setCurrentColor(newColor);
      if (onChange) onChange(newColor);
    },
    [onChange, setCurrentColor],
  );

  return (
    <Popover onOpenChange={onColorPickerOpenChange}>
      <StyleColorItem
        style={{
          backgroundColor: `rgba(${currentColor.red},${currentColor.green},${currentColor.blue},${currentColor.alpha})`,
        }}
      />
      <PopoverContent sideOffset={5} avoidCollisions>
        <ColorPicker colorValue={currentColor.rgba} onChange={onColorChange} showHexInput showPreview />
      </PopoverContent>
    </Popover>
  );
};
