/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { useAnimationURL } from '../../hooks';
import { useLottieImporter } from '../../toolkit';
import { PageLoader } from '../ui';

interface LottieImporterProps {
  children: React.ReactNode;
}

export const LottieImporter: React.FC<LottieImporterProps> = ({ children }) => {
  const animationUrl = useAnimationURL();
  const { error, loading } = useLottieImporter(animationUrl);

  if (error) {
    throw error;
  }

  if (loading) {
    return <PageLoader />;
  }

  return <>{children}</>;
};
