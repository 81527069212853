/**
 * Copyright 2022 Design Barn Inc.
 */

import { GridItem, GridLayout } from '@lottiefiles/ds-core';
import type { Color } from '@lottiefiles/toolkit-js';
import React from 'react';

import { getColorKey } from '../../../toolkit';
import type { getColors } from '../../../toolkit';

import { ColorItem } from './color-item';

interface AllColorGridProps {
  colors: ReturnType<typeof getColors>['allColors'];
  onChange?: (colorIndex: number, newColor: Color) => void;
  onChangeEnd: (colorIndex: number, newColor: Color) => void;
}

export const AllColorGrid: React.FC<AllColorGridProps> = (props): JSX.Element => {
  const { colors, onChange, onChangeEnd } = props;

  const onChangeColor = React.useCallback(
    (index: number) => {
      return (newColor: Color) => {
        if (onChange) onChange(index, newColor);
      };
    },
    [onChange],
  );

  const onChangeColorEnd = React.useCallback(
    (index: number) => {
      return (newColor: Color) => {
        onChangeEnd(index, newColor);
      };
    },
    [onChangeEnd],
  );

  return (
    <GridLayout
      customStyles={{
        gridTemplateColumns: 'repeat(auto-fill, minmax(24px, 1fr))',
        rowGap: '$space4',
        columnGap: '$space3',
      }}
      padding="0px"
      margin=""
      backgroundColor=""
      gap=""
      columns={{ lg: 8 }}
    >
      {colors.map(({ color, keyframe, nodeId }, index) => (
        <GridItem
          customStyles={{}}
          padding=""
          margin=""
          backgroundColor=""
          key={`${getColorKey(color)}_${nodeId}_${keyframe}_${index}`}
        >
          <ColorItem color={color} onChange={onChangeColor(index)} onChangeEnd={onChangeColorEnd(index)} />
        </GridItem>
      ))}
    </GridLayout>
  );
};

interface UniqueColorGridProps {
  colors: ReturnType<typeof getColors>['uniqueColors'];
  onChange?: (colorHex: string, newColor: Color) => void;
  onChangeEnd: (colorHex: string, newColor: Color) => void;
}

export const UniqueColorGrid: React.FC<UniqueColorGridProps> = (props) => {
  const { colors, onChange, onChangeEnd } = props;

  const onChangeColor = React.useCallback(
    (colorHex: string) => {
      return (newColor: Color) => {
        if (onChange) onChange(colorHex, newColor);
      };
    },
    [onChange],
  );

  const onChangeColorEnd = React.useCallback(
    (colorKey: string) => {
      return (newColor: Color) => {
        onChangeEnd(colorKey, newColor);
      };
    },
    [onChangeEnd],
  );

  return (
    <GridLayout
      customStyles={{
        gridTemplateColumns: 'repeat(auto-fill, minmax(24px, 1fr))',
        rowGap: '$space4',
        columnGap: '$space3',
      }}
      padding="0px"
      margin=""
      backgroundColor=""
      gap="$space3"
      columns={{ lg: 8 }}
    >
      {Object.values(colors)
        .map((entries) => entries.map(({ color }) => color)[0] as Color)
        .map((color) => {
          const colorKey = getColorKey(color);

          return (
            <GridItem customStyles={{}} padding="" margin="" backgroundColor="" key={colorKey}>
              <ColorItem color={color} onChange={onChangeColor(colorKey)} onChangeEnd={onChangeColorEnd(colorKey)} />
            </GridItem>
          );
        })}
    </GridLayout>
  );
};
