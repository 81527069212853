/**
 * Copyright 2022 Design Barn Inc.
 */

import isHotkey from 'is-hotkey';
import { useEffect, useRef } from 'react';

export function useKeypress(key: string, fn: CallableFunction): void {
  const callbackRef = useRef(fn);

  callbackRef.current = fn;

  useEffect(() => {
    const onKeydown = (event: KeyboardEvent): void => {
      if (isHotkey(key, event)) {
        const activeElement = document.activeElement as HTMLElement;
        const inputs = ['input', 'select', 'textarea'];

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (activeElement && inputs.includes(activeElement.tagName.toLowerCase())) {
          return;
        }

        event.preventDefault();

        callbackRef.current(event);
      }
    };

    document.addEventListener('keydown', onKeydown);

    return (): void => document.removeEventListener('keydown', onKeydown);
  }, [key]);
}
