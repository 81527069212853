/**
 * Copyright 2022 Design Barn Inc.
 */

import { FlexLayout } from '@lottiefiles/ds-core';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';

import { TopBar, FloatingMenu, LeftPanel, CanvasArea, LottiePlayer, RightPanel } from '../components';
import { EmptyFrontpage } from '../components/ui/empty-frontpage';
import { envConfig } from '../config/env';
import { useBackgroundColor, useHighlighedLayerEffects, useSelectedLayerEffects, useLayerShortcuts } from '../hooks';
import { useDrawOrderWithChildren } from '../hooks/use-draw-order-with-children';
import { useLottieExporter, useScene } from '../toolkit';

const App: React.FC = () => {
  const { error, value: animationData } = useLottieExporter();
  const scene = useScene();
  const playerRef = useRef(null);
  const noLayersLeft = scene?.state.layers.length === 0;

  useLayerShortcuts();
  useHighlighedLayerEffects();
  useDrawOrderWithChildren();
  useSelectedLayerEffects();
  useBackgroundColor();

  if (error) {
    throw error;
  }

  return (
    <>
      <Helmet>
        <script type="text/javascript">{"var OlvyConfig = { workspaceAlias: 'lottiefiles' };"}</script>
        <script type="text/javascript" src="https://app.olvy.co/scriptV2.js" defer></script>
        <script>{'window.onUsersnapCXLoad = function(api) { api.init(); window.usersnapCXApi = api; }'}</script>
        {!envConfig.isProd && (
          <script
            type="text/javascript"
            async
            src={`https://widget.usersnap.com/load/${envConfig.usersnapApiKey}?onload=onUsersnapCXLoad`}
          ></script>
        )}
      </Helmet>
      <TopBar />
      <div
        style={{
          display: noLayersLeft ? 'block' : 'none',
        }}
      >
        <EmptyFrontpage />
      </div>
      <FlexLayout
        customStyles={{ display: noLayersLeft ? 'none' : 'flex' }}
        direction={''}
        alignItems={''}
        justifyContent={''}
      >
        <LeftPanel playerRef={playerRef} />
        <CanvasArea>
          <LottiePlayer animationData={animationData} playerRef={playerRef} />
          <FloatingMenu />
        </CanvasArea>
        <RightPanel />
      </FlexLayout>
    </>
  );
};

export default App;
