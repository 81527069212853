/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { highlightedLayerIdAtom } from '../state';
import { useToolkit, addClassName, removeClassName } from '../toolkit';

export function useHighlighedLayerEffects(): void {
  const highlightedLayerId = useRecoilValue(highlightedLayerIdAtom);

  const toolkit = useToolkit();

  React.useEffect(() => {
    const highlightedLayer = toolkit.getNodeById(highlightedLayerId) as Layer | null;

    if (highlightedLayer) {
      try {
        addClassName(highlightedLayer, 'layer-highlighted');
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }

    return (): void => {
      if (highlightedLayer) {
        try {
          removeClassName(highlightedLayer, 'layer-highlighted');
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    };
  }, [highlightedLayerId, toolkit]);
}
