/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Color, DagNode } from '@lottiefiles/toolkit-js';
import {
  FillShape,
  TextLayer,
  SolidLayer,
  StrokeShape,
  GradientColor,
  GradientFillShape,
  GradientStrokeShape,
} from '@lottiefiles/toolkit-js';
import { SVG } from '@svgdotjs/svg.js';

import { getColorKey } from '..';

interface UpdateColorArgs {
  keyframe: number;
  newColor: Color;
  target: DagNode | null;
  targetColor: Color;
}
type UpdateColor = (args: UpdateColorArgs) => void;

export const updateColor: UpdateColor = ({ keyframe, newColor, target, targetColor }) => {
  if (getColorKey(targetColor) === getColorKey(newColor)) return;

  if (target instanceof FillShape || target instanceof StrokeShape) {
    target.color.setValueAtKeyFrame(newColor, keyframe);
  } else if (target instanceof GradientFillShape || target instanceof GradientStrokeShape) {
    const gradientValue = target.gradient.valueAtKeyFrame(keyframe);

    const colorStops = gradientValue.colorStops.map((colorStop) => ({
      ...colorStop,
      color: colorStop.color === targetColor ? newColor : colorStop.color,
    }));

    const newGradientValue = new GradientColor(colorStops);

    target.gradient.setValueAtKeyFrame(newGradientValue, keyframe);
  } else if (target instanceof TextLayer) {
    const textDocument = target.textData.valueAtKeyFrame(keyframe);

    const newTextDocument = textDocument.clone();

    if (targetColor === textDocument.fontColor) {
      newTextDocument.setFontColor(newColor);
    } else if (targetColor === textDocument.strokeColor) {
      newTextDocument.setStrokeColor(newColor);
    }

    target.textData.setValueAtKeyFrame(newTextDocument, keyframe);
  } else if (target instanceof SolidLayer) {
    target.setSolidColor(newColor);
  }
};

export function updateSVGColor({
  keyframe,
  newColor,
  target,
  targetColor,
}: {
  keyframe: number;
  newColor: Color;
  target: DagNode | null;
  targetColor: Color;
}): void {
  if (!target) return;

  const element = document.getElementById(target.nodeId);

  if (!element) return;

  const svg = SVG(`[id="${target.nodeId}"]`);

  if (target instanceof FillShape) {
    svg.fill({
      color: `#${newColor.hex6}`,
      opacity: newColor.alpha,
    });
  }

  if (target instanceof StrokeShape) {
    svg.stroke({
      color: `#${newColor.hex6}`,
      opacity: newColor.alpha,
    });
  }

  if (target instanceof SolidLayer) {
    const rect = svg.findOne('rect')?.node;

    rect?.setAttribute('fill', `#${newColor.hex6}`);
    rect?.setAttribute('fill-opacity', `${newColor.alpha}`);
  }

  if (target instanceof TextLayer) {
    svg.fill({
      color: `#${newColor.hex6}`,
      opacity: newColor.alpha,
    });
  }

  if (target instanceof GradientFillShape) {
    const gradientValue = target.gradient.valueAtKeyFrame(keyframe);

    const targetColorStop = gradientValue.colorStops.find(({ color }) => color === targetColor);

    if (!targetColorStop) return;

    const targetOffset = `${Math.round(targetColorStop.stop.value * 100)}%`;

    const gradientFillSvg = svg.reference('fill');

    if (!gradientFillSvg) return;

    gradientFillSvg.children().forEach((child) => {
      if (child.attr('offset') === targetOffset) {
        child.attr('stop-color', `#${newColor.hex6}`);
        child.attr('stop-opacity', `${newColor.alpha}`);
      }
    });
  }

  if (target instanceof GradientStrokeShape) {
    const gradientValue = target.gradient.valueAtKeyFrame(keyframe);

    const targetColorStop = gradientValue.colorStops.find(({ color }) => color === targetColor);

    if (!targetColorStop) return;

    const targetOffset = `${Math.round(targetColorStop.stop.value * 100)}%`;

    const gradientStrokeSvg = svg.reference('stroke');

    gradientStrokeSvg.children().forEach((child) => {
      if (child.attr('offset') === targetOffset) {
        child.attr('stop-color', `#${newColor.hex6}`);
        child.attr('stop-opacity', `${newColor.alpha}`);
      }
    });
  }
}
