/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { selectedLayerIdAtom } from '../state';
import { useToolkit, addClassName, removeClassName } from '../toolkit';

export function useSelectedLayerEffects(): void {
  const selectedLayerId = useRecoilValue(selectedLayerIdAtom);
  const toolkit = useToolkit();

  React.useEffect(() => {
    const selectedLayer = toolkit.getNodeById(selectedLayerId) as Layer | null;

    if (selectedLayer) {
      addClassName(selectedLayer, 'layer-active');
      toolkit.scenes[0]?.allLayers
        .filter((layer) => layer.nodeId !== selectedLayerId)
        .forEach((layer) => {
          addClassName(layer, 'layer-inactive');
        });
    }

    return () => {
      toolkit.scenes[0]?.allLayers.forEach((layer) => {
        ['layer-inactive', 'layer-active'].forEach((className) => {
          removeClassName(layer, className);
        });
      });
    };
  }, [selectedLayerId, toolkit]);
}
