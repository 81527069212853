/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

interface AppFallbackProps {
  error: Error;
}

export const AppFallback: React.FC<AppFallbackProps> = ({ error }) => {
  return (
    <div>
      <h1>Something went wrong</h1>
      <pre>{error.message}</pre>
    </div>
  );
};
