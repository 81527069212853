/**
 * Copyright 2022 Design Barn Inc.
 */

import { Icon, Text } from '@lottiefiles/ds-core';
import type { Scene, Layer } from '@lottiefiles/toolkit-js';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@radix-ui/react-accordion';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { styled } from '../../config/stitches';
import { selectedLayerIdAtom } from '../../state';
import { getColors, useScene, useToolkitNode } from '../../toolkit';

import { ColorSettings } from './color-settings';
import { ImageLayerSettings } from './image-layer-settings';
import { LayerAttributes } from './layer-attributes';
import { TextLayerSettings } from './text-layer-settings';

export const LayerSettings = React.memo((): JSX.Element => {
  const scene = useScene();

  const selectedLayerId = useRecoilValue(selectedLayerIdAtom);
  const selectedLayer = useToolkitNode(selectedLayerId) as Layer | null;

  if (!scene) {
    return <></>;
  }

  const activeNode: Layer | Scene = selectedLayer || scene;

  const colors = React.useMemo(() => getColors(activeNode), [activeNode.state]);

  const hasColors = colors.allColors.length > 0;

  const StyledLayerSettingsContainer = styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '$space5',
  });

  const StyledAccordionTrigger = styled(AccordionTrigger, {
    backgroundColor: 'transparent',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    width: '100%',
    paddingTop: '$space5',
    paddingBottom: '$space4',
    paddingLeft: '0',
    paddingRight: '0',
    cursor: 'pointer',
    '> svg': {
      transition: 'all 300ms',
    },
    '&[data-state=open] > svg': {
      transform: 'rotate(180deg)',
    },
  });

  return (
    <div>
      {hasColors && <ColorSettings colors={colors} />}

      <Accordion type="multiple" defaultValue={['textLayer', 'imageLayer', 'attributes']}>
        {selectedLayer?.type === 'TEXT' && (
          <AccordionItem value="textLayer" style={{ borderBottom: '1px solid #F3F6F8' }}>
            <StyledAccordionTrigger>
              <Text plugins={false} variant="body4" customTag="div" textStyle={{ fontWeight: 700, color: '$gray800' }}>
                Text layer
              </Text>
              <Icon name="chevron-outline-down" size="sm" color="$gray300" />
            </StyledAccordionTrigger>

            <AccordionContent style={{ paddingBottom: '16px' }}>
              <StyledLayerSettingsContainer>
                <TextLayerSettings />
              </StyledLayerSettingsContainer>
            </AccordionContent>
          </AccordionItem>
        )}

        {selectedLayer?.type === 'IMAGE' && (
          <AccordionItem value="imageLayer" style={{ borderBottom: '1px solid #F3F6F8' }}>
            <StyledAccordionTrigger>
              <Text plugins={false} variant="body4" customTag="div" textStyle={{ fontWeight: 700, color: '$gray800' }}>
                Image Preview
              </Text>
              <Icon name="chevron-outline-down" size="sm" color="$gray300" />
            </StyledAccordionTrigger>

            <AccordionContent style={{ paddingBottom: '16px' }}>
              <StyledLayerSettingsContainer>
                <ImageLayerSettings />
              </StyledLayerSettingsContainer>
            </AccordionContent>
          </AccordionItem>
        )}

        {selectedLayer && (
          <AccordionItem value="attributes" style={{ borderBottom: '1px solid #F3F6F8' }}>
            <StyledAccordionTrigger>
              <Text plugins={false} variant="body4" customTag="div" textStyle={{ fontWeight: 700, color: '$gray800' }}>
                Attributes
              </Text>
              <Icon name="chevron-outline-down" size="sm" color="$gray300" />
            </StyledAccordionTrigger>

            <AccordionContent style={{ paddingBottom: '16px' }}>
              <LayerAttributes />
            </AccordionContent>
          </AccordionItem>
        )}
      </Accordion>
    </div>
  );
});
