/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer, Toolkit } from '@lottiefiles/toolkit-js';

import { visitAllLayers } from './visit-all-layers';

interface SetIsHiddenArgs {
  isHidden: boolean;
  layer: Layer;
  toolkit: Toolkit;
}

const maxDepth = 3;

export const setIsHidden = ({ isHidden, layer, toolkit }: SetIsHiddenArgs): void => {
  if (!layer.isEnabled) return;

  toolkit.batch(() => {
    visitAllLayers(
      layer,
      ({ layer: nestedLayer }) => {
        nestedLayer.setIsHidden(isHidden);
      },
      maxDepth,
    );
  });
};
